import { apiExport, apiRequest } from '../index';
import {
  MEDICATIONS_CONF_SEARCH_URL,
  MEDICATIONS_URL,
  MEDIA_SERVICE_URL,
  BULK_LISTING_URL,
} from '../urls';

export const search = async (searchKeyword: { keyword: string }) => {
  return apiRequest('POST', MEDICATIONS_CONF_SEARCH_URL, searchKeyword, null);
};

export const types = async () => {
  return apiRequest('GET', `${MEDICATIONS_URL}/med-types`, null, null);
};

export const create = async (data: any) => {
  return apiRequest('POST', MEDICATIONS_URL, data, null);
};

export const exportMedications = async () => {
  return apiExport(`${MEDIA_SERVICE_URL}/export/MEDICATION`, {});
};

export const exportProcessFile = async (filter: any) => {
  return apiExport(`${MEDIA_SERVICE_URL}/export/UPLOAD`, { ...filter });
};

export const uploadBulk = async (data: any) => {
  return apiRequest('POST', `${MEDIA_SERVICE_URL}/upload`, data, null);
};

export const update = async ({ data, id }: any) => {
  return apiRequest('PATCH', `${MEDICATIONS_URL}/${id}`, data, null);
};

export const get = async (id: any) => {
  return apiRequest('GET', `${MEDICATIONS_URL}/${id}`, null, null);
};

export const listBulk = async () => {
  return apiRequest('GET', BULK_LISTING_URL, null, {
    sort: 'uploadedAt,DESC',
  });
};

export default {
  search,
  create,
  exportMedications,
  update,
  get,
  uploadBulk,
  listBulk,
  exportProcessFile,
};
