import { all, takeLatest, put, call } from 'redux-saga/effects';
import { Types } from '../../redux/cpt/action';
import cptApi from '../../../services/api/cpt';
import { Creators } from '../../redux/cpt/action';

export function* fetchCpt(action: any) {
  const { Type, filters } = action;

  try {
    let response = yield call(cptApi.list, Type, filters);
    yield put(Creators.cptListRequestSuccess(response.data, filters));
  } catch (e) {
    let error = e.response;
    yield put(Creators.cptListRequestError(error));
  }
}

export function* watchList() {
  yield takeLatest(Types.CPT_LIST_REQUEST, fetchCpt);
}
export default watchList;
