import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* exportReport(action: any) {
  try {
    if (action.filterType === 'data') {
      const response = yield call(claimsApi.exportReport, action.filters);
      yield put(Creators.claimsExportRequestSuccess(response?.data));
    } else {
      const response = yield call(claimsApi.exportImages, action.filters, action.filterType);
      yield put(Creators.claimsExportRequestSuccess(response?.data));
    }
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'export', entity: 'claims' });
    let error = e.response;
    yield put(Creators.claimsExportRequestError(error));
  }
}

export function* watchExport() {
  yield debounce(6e2, Types.CLAIMS_EXPORT_REQUEST, exportReport);
}

export default watchExport;
