import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import ContentLoader, { BulletList } from 'react-content-loader';
import { LoaderProps } from 'src/props/loader.props';
import styles from './Loader.module.scss';

const Loader = (props: LoaderProps): JSX.Element => {
  const looper = [];
  const rows = props?.rows || 1;
  for (let i = 0; i < rows; i++) {
    looper.push(i);
  }

  if (props.type === 'table') {
    return (
      <>
        {looper.map((ind) => (
          <Fragment key={ind}>
            <ContentLoader className={styles.hBar} viewBox="0 0 500 90">
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="30px" />
            </ContentLoader>
            <ContentLoader className={styles.hBar} viewBox="0 0 500 90">
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="30px" />
            </ContentLoader>
            <ContentLoader className={styles.hBar} viewBox="0 0 500 90">
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="30px" />
            </ContentLoader>
            <ContentLoader className={styles.hBar} viewBox="0 0 500 90">
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="30px" />
            </ContentLoader>
            <ContentLoader className={styles.hBar} viewBox="0 0 500 90">
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="30px" />
            </ContentLoader>
          </Fragment>
        ))}
      </>
    );
  } else if (props.type === 'section') {
    return (
      <>
        {looper.map((ind) => (
          <Fragment key={ind}>
            <ContentLoader viewBox="0 0 500 20">
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="10px" />
            </ContentLoader>
          </Fragment>
        ))}
      </>
    );
  } else if (props.type === 'form') {
    return (
      <Row className={styles.rowSpacing}>
        {looper.map((ind) => (
          <Col key={ind} className={styles.formfield} md={props.col || 6}>
            <ContentLoader
              className={styles.hBar}
              key={ind}
              viewBox="0 0 500 90">
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="30px" />
            </ContentLoader>
            <ContentLoader viewBox="0 0 500 20">
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="10px" />
            </ContentLoader>
          </Col>
        ))}
      </Row>
    );
  } else if (props.type === 'list') {
    return (
      <>
        {looper.map((repeat: any) => (
          <BulletList key={repeat}></BulletList>
        ))}
      </>
    );
  } else {
    const width = props.width || '50';
    const height = props.type === 'square' ? width : '10';
    return (
      <ContentLoader viewBox={'0 0 ' + width + ' ' + height}>
        <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
      </ContentLoader>
    );
  }
};

export default Loader;
