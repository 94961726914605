import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/doctors/actions';
import doctorsApi from 'src/services/api/doctors';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* create({ doctor }: any) {
  try {
    const response = yield call(doctorsApi.create, doctor);
    yield put(Creators.doctorsCreateRequestSuccess(response.data));
    toaster.success('doctor were added successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'create', entity: 'doctors' });
    handleErrorResponse(error);
    yield put(Creators.doctorsCreateRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLeading(Types.DOCTORS_CREATE_REQUEST, create);
}

export default watchCreate;
