import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    medicationsListRequest: ['filters'],
    medicationsListRequestSuccess: ['data'],
    medicationsListRequestError: ['error'],

    medicationsCacheRequest: ['filters'],
    medicationsCacheRequestSuccess: [],
    medicationsCacheRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
