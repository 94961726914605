import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/field-representatives/actions';
import fieldRepresentativesApi from 'src/services/api/field-representatives';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* create(action: any) {
  try {
    const response = yield call(
      fieldRepresentativesApi.create,
      action.fieldRepresentative
    );
    yield put(Creators.fieldRepresentativesCreateRequestSuccess(response.data));
    toaster.success('field representative were created successfully');
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'create', entity: 'fieldRep' });
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.fieldRepresentativesCreateRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLeading(Types.FIELD_REPRESENTATIVES_CREATE_REQUEST, create);
}

export default watchCreate;
