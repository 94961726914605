import { apiRequest } from '../index';
import { TAGS_SMS_URL, QUERY_TAGS_URL, TAGS_URL, TAGS_UPDATE } from '../urls';
import { Filters } from 'src/props/filters.props';
import { Tag } from 'src/types-dictionary/tags';

export const list = async (filters: Filters) => {
  return apiRequest('GET', QUERY_TAGS_URL, null, filters);
};

export const sms = async (id: number) => {
  const data = apiRequest('GET', `${TAGS_SMS_URL}/claim-tag/${id}`, null, null);
  return data;
};

const create = async (tag: Tag) => {
  return apiRequest('POST', TAGS_URL, tag, null);
};

const get = async (id: number) => {
  return apiRequest('GET', `${TAGS_URL}/${id}`, null, null);
};

const update = async (id: number, tag: Tag) => {
  return apiRequest('PATCH', `${TAGS_UPDATE}/${id}`, tag, null);
};

const deleteTag = async (id: number) => {
  return apiRequest('DELETE', `${TAGS_URL}/${id}`, null, null);
};

export default {
  create,
  list,
  sms,
  get,
  update,
  deleteTag,
};
