import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    DOCTORS_CREATE_REQUEST
    DOCTORS_CREATE_REQUEST_SUCCESS
    DOCTORS_CREATE_REQUEST_ERROR

    DOCTORS_DELETE_REQUEST
    DOCTORS_DELETE_REQUEST_SUCCESS
    DOCTORS_DELETE_REQUEST_ERROR

    DOCTORS_UPDATE_REQUEST
    DOCTORS_UPDATE_REQUEST_SUCCESS
    DOCTORS_UPDATE_REQUEST_ERROR

    DOCTORS_LIST_REQUEST
    DOCTORS_LIST_REQUEST_SUCCESS
    DOCTORS_LIST_REQUEST_ERROR

    DOCTORS_UPLOAD_REQUEST
    DOCTORS_UPLOAD_REQUEST_SUCCESS
    DOCTORS_UPLOAD_REQUEST_ERROR
    
    DOCTORS_GET_REQUEST
    DOCTORS_GET_REQUEST_SUCCESS
    DOCTORS_GET_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
