import React from 'react';
import { Container } from 'react-bootstrap';
import classnames from 'classnames';

import styles from './index.module.scss';

export type SegmentProps = {
  // write custom types here
};

const Segment: React.FC<SegmentProps & React.HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  return (
    <Container
      
      fluid
      {...props}
      className={classnames(styles.segment, props.className)}>
      {props.children}
    </Container>
  );
};

export default Segment;
