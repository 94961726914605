import { debounce, put, call, all, select } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import DBSocket from 'src/services/websockets';
import { DBErrorMonitor } from 'src/services/error-monitor';
import ReduxStoreTypes from 'src/store/redux/types';
import { Claim, ClaimStatus, DVStatus } from 'src/types-dictionary';

export function* list(action: any) {
  try {
    //get list of claims
    const response = yield call(claimsApi.list, action.filters);
    yield put(Creators.claimsListRequestSuccess(response.data));

    while (DBSocket.isConnected('claims')) {
      const updatedClaim: Claim = yield call(
        DBSocket.listenToSocket,
        'claims',
        'updateClaim'
      );

      let claims = yield select((store: ReduxStoreTypes) => store.claims.list);
      const user = yield select((store: ReduxStoreTypes) => store.auth.user);

      let newClaims = yield select(
        (store: ReduxStoreTypes) => store.claims.newClaim
      );

      claims = claims
        ?.filter((claim: Claim) => {
          return !(
            (updatedClaim.id === claim.id &&
              updatedClaim.dvStatus === DVStatus.RESUBMITTED) ||
            (updatedClaim.id === claim.id &&
              updatedClaim.status === ClaimStatus.ASSIGNED &&
              updatedClaim.assignedAgent.id == user.id)
          );
        })
        .map((claim: Claim) => {
          if (updatedClaim.id === claim.id) {
            return updatedClaim;
          }
          return claim;
        });

      newClaims =
        newClaims?.map((claim: Claim) => {
          if (updatedClaim.id === claim.id) {
            return updatedClaim;
          }
          return claim;
        }) ?? [];

      if (
        updatedClaim.status === ClaimStatus.ASSIGNED &&
        updatedClaim.assignedAgent.id == user.id
      ) {
        claims.unshift(updatedClaim);
      }

      if (updatedClaim.dvStatus === DVStatus.RESUBMITTED) {
        claims.unshift(updatedClaim);
      }

      yield put(Creators.claimsListRequestSuccess({ data: claims, newClaims }));
    }
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'list', entity: 'claims' });
    let error = e.response;
    if (error) {
      handleErrorResponse(error);
    } else {
      console.log(e);
    }
    yield put(Creators.claimsListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.CLAIMS_LIST_REQUEST, list);
}

export default watchList;
