import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/roles/actions';
import rolesApi from 'src/services/api/roles';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* update(action: any) {
  try {
    const response = yield call(rolesApi.update, action.data);
    toaster.success('role were updated successfully');
    yield put(Creators.rolesUpdateRequestSuccess(response.data));
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'update', entity: 'roles' });
    toaster.error(e.message);
    let error = e.response;
    yield put(Creators.rolesUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.ROLES_UPDATE_REQUEST, update);
}

export default watchUpdate;
