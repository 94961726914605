import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    CLAIMS_REPORT_REQUEST
    CLAIMS_REPORT_REQUEST_SUCCESS
    CLAIMS_REPORT_REQUEST_ERROR

    CLAIMS_EXPORT_REQUEST
    CLAIMS_EXPORT_REQUEST_SUCCESS
    CLAIMS_EXPORT_REQUEST_ERROR
    
    CLAIMS_CREATE_REQUEST
    CLAIMS_CREATE_REQUEST_SUCCESS
    CLAIMS_CREATE_REQUEST_ERROR

    CLAIMS_LIST_REQUEST
    CLAIMS_LIST_REQUEST_SUCCESS
    CLAIMS_LIST_REQUEST_ERROR

    CLAIMS_TRACK_REQUEST
    CLAIMS_TRACK_REQUEST_SUCCESS
    CLAIMS_TRACK_REQUEST_ERROR

    CLAIMS_ADJUST_REQUEST

    CLAIMS_LISTENER_REQUEST
    CLAIMS_LISTENER_REQUEST_SUCCESS
    CLAIMS_LISTENER_REQUEST_ERROR

    CLAIMS_UPDATE_REQUEST
    CLAIMS_UPDATE_REQUEST_SUCCESS
    CLAIMS_UPDATE_REQUEST_ERROR

    CLAIMS_DELETE_REQUEST
    CLAIMS_DELETE_REQUEST_SUCCESS
    CLAIMS_DELETE_REQUEST_ERROR

    CLAIMS_GET_REQUEST
    CLAIMS_GET_REQUEST_SUCCESS
    CLAIMS_GET_REQUEST_ERROR

    CLAIMS_COUNT_REQUEST
    CLAIMS_COUNT_REQUEST_SUCCESS
    CLAIMS_COUNT_REQUEST_ERROR

    CLAIMS_PARSE_IMAGE_REQUEST
    CLAIMS_PARSE_IMAGE_REQUEST_SUCCESS
    CLAIMS_PARSE_IMAGE_REQUEST_ERROR

    CLAIMS_GET_IMAGE_REQUEST
    CLAIMS_GET_IMAGE_REQUEST_SUCCESS
    CLAIMS_GET_IMAGE_REQUEST_ERROR

    CLAIMS_GET_ATTACHMENT_REQUEST
    CLAIMS_GET_ATTACHMENT_REQUEST_SUCCESS
    CLAIMS_GET_ATTACHMENT_REQUEST_ERROR

    CLAIMS_ASSIGN_SELF_REQUEST
    CLAIMS_ASSIGN_SELF_REQUEST_SUCCESS 
    CLAIMS_ASSIGN_SELF_REQUEST_ERROR

    CLAIMS_CHECK_ELIGIBILITY_REQUEST
    CLAIMS_CHECK_ELIGIBILITY_REQUEST_SUCCESS 
    CLAIMS_CHECK_ELIGIBILITY_REQUEST_ERROR


    CLAIMS_CANCEL_REQUEST
    CLAIMS_CANCEL_REQUEST_SUCCESS
    CLAIMS_CANCEL_REQUEST_ERROR

    CLAIMS_ASSIGN_REQUEST
    CLAIMS_ASSIGN_REQUEST_SUCCESS
    CLAIMS_ASSIGN_REQUEST_ERROR

    CLAIMS_HISTORY_REQUEST
    CLAIMS_HISTORY_REQUEST_SUCCESS
    CLAIMS_HISTORY_REQUEST_ERROR


    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
