import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    insuranceCompaniesListRequest: ['filters'],
    insuranceCompaniesListRequestSuccess: ['data'],
    insuranceCompaniesListRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
