import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medication-config/actions';
import medsConfigApi from 'src/services/api/medication-config';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';
// import history from 'src/services/browser-history';

export function* create({ data }: any) {
  try {
    const response = yield call(medsConfigApi.create, data);
    yield put(Creators.medicationConfCreateRequestSuccess(response.data));
    toaster.success('Medication were added successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'create',
      entity: 'medication_config',
    });
    handleErrorResponse(error);
    yield put(Creators.medicationConfCreateRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLeading(Types.MEDICATION_CONF_CREATE_REQUEST, create);
}

export default watchCreate;
