import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medication-config/actions';
import { types as typesMedConf } from 'src/services/api/medication-config';

export function* types() {
  try {
    //filters {keyword:string}
    const response = yield call(typesMedConf);
    yield put(Creators.medicationConfTypesRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.medicationConfTypesRequestError(error));
  }
}

export function* watchTypes() {
  yield takeLeading(Types.MEDICATION_CONF_TYPES_REQUEST, types);
}

export default watchTypes;
