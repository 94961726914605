import { apiRequest } from '../index';
import { PERMISSIONS_URL, AGENTS_URL } from '../urls';

const create = (data: any) => {
  return apiRequest('POST', `${PERMISSIONS_URL}/role-permissions`, data);
};

const list = () => {
  return apiRequest('GET', `${PERMISSIONS_URL}/roles`, null, null);
};

const remove = (roleId: number) => {
  return apiRequest('DELETE', `${PERMISSIONS_URL}/roles/${roleId}`, null, null);
};

const get = (roleId: number) => {
  return apiRequest('GET', `${PERMISSIONS_URL}/roles/${roleId}`, null, null);
};

const getCurr = () => {
  return apiRequest('GET', `${AGENTS_URL}/current-user-permissions`, null);
};

const update = ({ id, ...data }: any) => {
  return apiRequest('PATCH', `${PERMISSIONS_URL}/role-permissions/${id}`, data);
};

const listPerm = () => {
  return apiRequest('GET', PERMISSIONS_URL, null, null);
};

export default {
  create,
  listPerm,
  list,
  remove,
  get,
  update,
  getCurr,
};
