import { Filters } from 'src/props/filters.props';
import { apiRequest } from '../index';
import { AGENTS_URL, CURRENT_USER_URL } from '../urls';

export const create = (agent: any) => {
  return apiRequest('POST', AGENTS_URL, agent);
};

export const update = ({ id, ...data }: any) => {
  return apiRequest('PATCH', `${AGENTS_URL}/${id || 'current'}`, {
    id,
    ...data,
  });
};

export const deleteAgent = (id: string | number) => {
  return apiRequest('DELETE', `${AGENTS_URL}/${id}`, null);
};

export const list = (filters: Filters) => {
  const queries: any = {
    ...filters,
    sort: 'id,DESC',
  };
  return apiRequest('GET', `${AGENTS_URL}/query/members`, null, queries);
};

export const get = (id?: number | string) => {
  const url = id ? `${AGENTS_URL}/${id}` : CURRENT_USER_URL;
  return apiRequest('GET', url, null);
};

export const generateMFA = (id: number | string) => {
  const url = `${AGENTS_URL}/revoke-mfa/${id}`;
  return apiRequest('GET', url, null);
};

export const changeStatus = () => {
  const url = `${AGENTS_URL}/change-isOnline`;
  return apiRequest('GET', url, null);
};

export default {
  create,
  update,
  delete: deleteAgent,
  list,
  get,
  generateMFA,
  changeStatus,
};
