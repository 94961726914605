import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medical-entities/actions';
import medicalEntitiesApi from 'src/services/api/medical-entities';
import toaster from 'src/services/toaster';
import { ROUTES } from 'src/constants';
import history from 'src/services/browser-history';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* create({ medicalEntity }: any) {
  try {
    const response = yield call(medicalEntitiesApi.create, medicalEntity);
    yield put(Creators.medicalEntitiesCreateRequestSuccess(response));
    toaster.success('medical entity were created successfully');
    const { id, type } = response.data;
    history.push(
      ROUTES.MEDICAL_ENTITIES.ADD_DOCTORS.replace(':type/:id', `${type}/${id}`)
    );
  } catch (e) {
    DBErrorMonitor.captureException(e, {
      saga: 'create',
      entity: 'medicalEntities',
    });
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.medicalEntitiesCreateRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLeading(Types.MEDICAL_ENTITIES_CREATE_REQUEST, create);
}

export default watchCreate;
