import {
  createStore,
  combineReducers,
  applyMiddleware,
  Middleware,
  compose,
} from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootSaga from 'src/store/sagas';
import authReducer from './redux/auth/reducer';
import agentsReducer from './redux/agents/reducer';
import medicalEntitiesReducer from './redux/medical-entities/reducer';
import medicalGroupsReducer from './redux/medical-groups/reducer';
import medicalSpecialtiesReducer from './redux/medical-specialties/reducer';
import medicalDegreesReducer from './redux/medical-degrees/reducer';
import doctorsReducer from './redux/doctors/reducer';
import insuranceCompaniesReducer from './redux/insurance-companies/reducer';
import fieldRepresentativesReducer from './redux/field-representatives/reducer';
import medicationsReducer from './redux/medications/reducer';
import claimsReducer from './redux/claims/reducer';
import diseaseCodesReducer from './redux/disease-codes/reducer';
import notificationsReducer from './redux/notifications/reducer';
import rolesReducer from './redux/roles/reducer';
import tagsReducer from './redux/tags/reducer';
import smsConfigReducer from './redux/sms-config/reducer';
import commentsReducer from './redux/comments/reducer';
import labsReducer from './redux/labs/reducer';
import scansReducer from './redux/scans/reducer';
import slaReducer from './redux/SLA/reducer';
import medicationsConfigReducer from './redux/medication-config/reducer';
import statisticsTPAReducer from './redux/statistics-tpa/reducer';
import statisticsMEReducer from './redux/statistics-me/reducer';
import cptReducer from './redux/cpt/reducer';
import itReducer from './redux/Integration/reducer';
import { DBErrorMonitor } from 'src/services/error-monitor';

const logger = createLogger();

const isDev = () => process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [isDev() && logger, sagaMiddleware].filter(
  Boolean
) as Middleware[];

const store = createStore(
  combineReducers({
    auth: authReducer,
    agents: agentsReducer,
    medicalEntities: medicalEntitiesReducer,
    medicalGroups: medicalGroupsReducer,
    medicalSpecialties: medicalSpecialtiesReducer,
    medicalDegrees: medicalDegreesReducer,
    doctors: doctorsReducer,
    insuranceCompanies: insuranceCompaniesReducer,
    fieldRepresentatives: fieldRepresentativesReducer,
    claims: claimsReducer,
    diseaseCodes: diseaseCodesReducer,
    notifications: notificationsReducer,
    roles: rolesReducer,
    medications: medicationsReducer,
    tags: tagsReducer,
    comments: commentsReducer,
    smsConfig: smsConfigReducer,
    labs: labsReducer,
    scans: scansReducer,
    SLA: slaReducer,
    integration: itReducer,
    medicationsConfig: medicationsConfigReducer,
    statsTPA: statisticsTPAReducer,
    statsME: statisticsMEReducer,
    cpt: cptReducer,
  }),
  compose(applyMiddleware(...middlewares), DBErrorMonitor.reduxInjector())
);

sagaMiddleware.run(rootSaga);

export default store;
