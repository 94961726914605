import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    diseaseCodesListRequest: ['filters'],
    diseaseCodesListRequestSuccess: ['data'],
    diseaseCodesListRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
