import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    medicalGroupsCreateRequest: ['group'],
    medicalGroupsCreateRequestSuccess: ['data'],
    medicalGroupsCreateRequestError: ['error'],

    medicalGroupsListRequest: ['filters'],
    medicalGroupsListRequestSuccess: ['data'],
    medicalGroupsListRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
