import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/tags/actions';
import tagsApi from 'src/services/api/tags';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get({ id }: any) {
  try {
    const response = yield call(tagsApi.get, id);
    yield put(Creators.tagsGetRequestSuccess(response));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'tags' });
    switch (error.data.statusCode) {
      case 400:
        toaster.error(error.data.message);
        break;
      case 500:
        toaster.error('Internal Server Error');
        break;
      default:
        toaster.error(error);
    }
    yield put(Creators.tagsGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLeading(Types.TAGS_GET_REQUEST, get);
}

export default watchGet;
