import { apiRequest } from '../index';
import { MEDICAL_ENTITIES_URL } from '../urls';
import { Filters } from 'src/props/filters.props';

export const create = (medicalEntity: any) => {
  return apiRequest('POST', MEDICAL_ENTITIES_URL, medicalEntity);
};

export const update = ({ id, ...data }: any) => {
  return apiRequest('PATCH', `${MEDICAL_ENTITIES_URL}/${id}`, {id, ...data});
};

export const deleteEntity = (id: string | number) => {
  return apiRequest('DELETE', `${MEDICAL_ENTITIES_URL}/${id}`, null);
};

export const list = (filters: Filters) => {
  return apiRequest(
    'GET',
    `${MEDICAL_ENTITIES_URL}/query-medicalEntities`,
    null,
    filters
  );
};

export const get = (id?: number | string) => {
  return apiRequest('GET', `${MEDICAL_ENTITIES_URL}/${id}`, null);
};

export default {
  create,
  update,
  delete: deleteEntity,
  list,
  get,
};
