import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medication-config/actions';
import medsApi from 'src/services/api/medication-config';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* upload({ data }: any) {
  try {
    const fileParts = data.name.split('.');
    const ext = fileParts[fileParts.length - 1];
    if (!['csv', 'xlsx'].includes(ext)) {
      toaster.error('The uploaded file type should be csv or Excel (.xlsx).');
      throw new Error();
    }

    const fileToUpload = new FormData();
    fileToUpload.append('file', data);
    fileToUpload.append('service', 'MEDICATION');

    const response = yield call(medsApi.uploadBulk, fileToUpload);

    yield put(Creators.medicationConfBulkUploadRequestSuccess(response.data));
    toaster.success('medications were added successfully');
  } catch (e) {
    let error = e?.response;
    DBErrorMonitor.captureException(e, {
      saga: 'upload',
      entity: 'medication-config',
    });
    handleErrorResponse(error);
    yield put(Creators.medicationConfBulkUploadRequestError(error));
  }
}

export function* watchUpload() {
  yield debounce(6e2, Types.MEDICATION_CONF_BULK_UPLOAD_REQUEST, upload);
}

export default watchUpload;
