import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/auth/actions';
import toaster from 'src/services/toaster';
import history from 'src/services/browser-history';
import { ROUTES } from 'src/constants';
import { DBErrorMonitor } from 'src/services/error-monitor';
import AuthAPI from 'src/services/api/auth';
export function* reset(action: any) {
  try {
    const response = yield call(
      AuthAPI.reset,
      action.user.token,
      action.user.password
    );
    yield put(Creators.resetRequestSuccess(response));
    history.push(ROUTES.AUTH.LOGIN);
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'reset', entity: 'auth' });
    toaster.error('Failed to reset new password');
    yield put(Creators.resetRequestError(e));
  }
}

export function* watchReset() {
  yield takeLeading(Types.RESET_REQUEST, reset);
}

export default watchReset;
