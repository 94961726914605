import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    MEDICAL_DEGREES_LIST_REQUEST
    MEDICAL_DEGREES_LIST_REQUEST_SUCCESS
    MEDICAL_DEGREES_LIST_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
