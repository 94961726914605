import { createReducer } from 'reduxsauce';
import { CONFIG_URL } from 'src/services/api/urls';
import history from 'src/services/browser-history';
import { ITreducerType } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: ITreducerType = {
  list: [],
  item: {} as any,
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  isLoading: false,
  hasError: false,
};

export const getRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    item: action.data.data,
    isLoading: false,
    hasError: false,
  };
};

export const getRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const updateRequest = (state = INITIAL_STATE) => {
  return {
    ...INITIAL_STATE,
    isLoading: true,
    hasError: false,
  };
};

export const updateRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    item: action.data.data,
    isLoading: false,
    hasError: false,
  };
};

export const updateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => {
  if (
    CONFIG_URL.includes(history.location.pathname.split('/')[1]) &&
    history.location.search !== ''
  ) {
    return {
      ...INITIAL_STATE,
      pagination: state.pagination,
      isLoading: true,
    };
  }
  return state;
};

export const HANDLERS = {
  [Types.IT_CONFIG_GET_REQUEST as string]: getRequest,
  [Types.IT_CONFIG_GET_REQUEST_SUCCESS as string]: getRequestSuccess,
  [Types.IT_CONFIG_GET_REQUEST_ERROR as string]: getRequestError,

  [Types.IT_CONFIG_UPDATE_REQUEST as string]: updateRequest,
  [Types.IT_CONFIG_UPDATE_REQUEST_SUCCESS as string]: updateRequestSuccess,
  [Types.IT_CONFIG_UPDATE_REQUEST_ERROR as string]: updateRequestError,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
