import { apiRequest } from '../index';
import { SMS_CONFIG_URL, SMS_CONFIG_SCENARIOS_URL } from '../urls';
import { Filters } from 'src/props/filters.props';

export const claimSms = (filters: any) => {
  return apiRequest('POST', `${SMS_CONFIG_URL}/claim-tag`, filters);
};

export const create = (doctor: any) => {
  return apiRequest('POST', SMS_CONFIG_SCENARIOS_URL, doctor);
};

export const update = ({ id, ...data }: any) => {
  return apiRequest('PATCH', `${SMS_CONFIG_SCENARIOS_URL}/${id}`, data);
};

export const deleteSMSConfig = (id: string | number) => {
  return apiRequest('DELETE', `${SMS_CONFIG_SCENARIOS_URL}/${id}`, null);
};

export const list = (filters: Filters) => {
  return apiRequest('GET', SMS_CONFIG_SCENARIOS_URL, null, filters);
};

export const get = (id: string | number) => {
  return apiRequest('GET', `${SMS_CONFIG_SCENARIOS_URL}/${id}`, null);
};

export default {
  create,
  update,
  delete: deleteSMSConfig,
  list,
  get,
  claimSms,
};
