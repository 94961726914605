import { put, call, takeLatest, all } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get(action: any) {
  try {
    console.log('claim id to be sent', action.claimId);
    const response = yield call(claimsApi.getClaimHistory, action.claimId);
    yield put(Creators.claimsHistoryRequestSuccess(response.data));
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'history', entity: 'claims' });
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.claimsHistoryRequestError(error));
  }
}

export function* watchHistory() {
  yield takeLatest(Types.CLAIMS_HISTORY_REQUEST, get);
}

export default watchHistory;
