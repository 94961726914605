import { debounce, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import toaster from 'src/services/toaster';
import history from 'src/services/browser-history';
export function* AssignClaimToAgent(action: any) {
  try {
    const { userId, claimId } = action;
    const response = yield call(claimsApi.assignToAgent, userId, claimId);

    yield put(Creators.claimsAssignRequestSuccess(response.data));
    //yield put(Creators.claimsListRequest({filter:''}))
    toaster.success(`The claim has been assinged successfully`);
  } catch (e) {
    let error = e.response;
    yield put(Creators.claimsAssignRequestError());
    toaster.error(`The claim is not assigned correctly `);
  }
}

export function* watchAssignClaimToAgent() {
  yield debounce(6e2, Types.CLAIMS_ASSIGN_REQUEST, AssignClaimToAgent);
}

export default watchAssignClaimToAgent;
