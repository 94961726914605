import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* assignClaimToSelf({ id, internal }: any) {
  try {
    const response = yield call(claimsApi.assignToSelf, id, internal);
    yield put(Creators.claimsAssignSelfRequestSuccess(response.data));
    toaster.success('Prescription was assigned to you successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'assignClaimToSelf',
      entity: 'claims',
    });
    handleErrorResponse(error);
    yield put(Creators.claimsAssignSelfRequestError(error));
  }
}

export function* watchAssignClaimToSelf() {
  yield debounce(6e2, Types.CLAIMS_ASSIGN_SELF_REQUEST, assignClaimToSelf);
}

export default watchAssignClaimToSelf;
