import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    USER_REQUEST
    USER_REQUEST_SUCCESS
    USER_REQUEST_ERROR

    LOGIN_REQUEST
    LOGIN_REQUEST_SUCCESS
    LOGIN_REQUEST_ERROR

    LOGOUT_REQUEST
    LOGOUT_REQUEST_SUCCESS
    LOGOUT_REQUEST_ERROR

    FORGET_REQUEST
    FORGET_REQUEST_SUCCESS
    FORGET_REQUEST_ERROR

    RESET_REQUEST
    RESET_REQUEST_SUCCESS
    RESET_REQUEST_ERROR

    COMPLETE_NEW_PASSWORD_REQUEST
    COMPLETE_NEW_PASSWORD_REQUEST_SUCCESS
    COMPLETE_NEW_PASSWORD_REQUEST_ERROR

  `,
  {
    prefix: '',
  }
);
