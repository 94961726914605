import { apiRequest } from '../index';
import { MEDICAL_GROUPS_URL } from '../urls';
import { Filters } from 'src/props/filters.props';

export const create = (group: any) => {
  return apiRequest('POST', MEDICAL_GROUPS_URL, group);
};

export const list = (filters: Filters) => {
  return apiRequest('GET', MEDICAL_GROUPS_URL, null, filters);
};

export default {
  create,
  list,
};
