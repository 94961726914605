import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    IT_CONFIG_GET_REQUEST
    IT_CONFIG_GET_REQUEST_SUCCESS
    IT_CONFIG_GET_REQUEST_ERROR

    IT_CONFIG_UPDATE_REQUEST
    IT_CONFIG_UPDATE_REQUEST_SUCCESS
    IT_CONFIG_UPDATE_REQUEST_ERROR

    IT_CONFIG_UPDATE_AGENT_CAPC_REQUEST
    IT_CONFIG_UPDATE_AGENT_CAPC_REQUEST_SUCCESS
    IT_CONFIG_UPDATE_AGENT_CAPC_REQUEST_ERROR


    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
