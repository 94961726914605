import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    NOTIFICATIONS_LIST_REQUEST
    NOTIFICATIONS_LIST_REQUEST_SUCCESS
    NOTIFICATIONS_LIST_HEADER_REQUEST_SUCCESS
    NOTIFICATIONS_LIST_REQUEST_ERROR

    NOTIFICATIONS_READ_REQUEST
    NOTIFICATIONS_READ_REQUEST_SUCCESS
    NOTIFICATIONS_READ_REQUEST_ERROR

    COUNTS_ALL_REQUEST
    COUNTS_ALL_REQUEST_SUCCESS
    COUNTS_ALL_REQUEST_ERROR

    NOTIFICATIONS_PULL_REQUEST
    NOTIFICATIONS_PULL_REQUEST_SUCCESS
    NOTIFICATIONS_PULL_REQUEST_ERROR
  `,
  {
    prefix: '',
  }
);
