import { apiRequest } from '../index';
import { LABS_URL } from '../urls';

export const list = (filters: any) => {
  const url = `${LABS_URL}/search-lab`;
  const categories: string[] = [];
  const searchLabs: { keyword?: string; categories?: string[] } = {};

  if (filters.filter) {
    Object.keys(filters.filter.category).forEach((labF: any) => {
      if (filters.filter.category[labF].isFilteredBy) {
        categories.push(filters.filter.category[labF].value);
      }
    });

    searchLabs.keyword = filters.filter.name;
    searchLabs.categories = categories;

    delete filters.filter;
  }
  return apiRequest('POST', url, searchLabs, null);
};

export const categories = () => {
  return apiRequest('GET', `${LABS_URL}/categories`, null);
};

export default {
  list,
  categories,
};
