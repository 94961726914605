import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/doctors/actions';
import doctorsApi from 'src/services/api/doctors';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* update(action: any) {
  try {
    const response = yield call(doctorsApi.update, action.doctor);
    yield put(Creators.doctorsUpdateRequestSuccess(response.data));
    toaster.success('doctor were updated successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'update', entity: 'doctors' });
    handleErrorResponse(error);
    yield put(Creators.doctorsUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.DOCTORS_UPDATE_REQUEST, update);
}

export default watchUpdate;
