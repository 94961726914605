import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medication-config/actions';
import { search as searchMedConf } from 'src/services/api/medication-config';

export function* search(action: any) {
  try {
    //filters {keyword:string}
    const response = yield call(searchMedConf, action.filters);
    yield put(Creators.medicationConfSearchListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.medicationConfSearchListRequestError(error));
  }
}

export function* watchSearch() {
  yield debounce(500, Types.MEDICATION_CONF_SEARCH_LIST_REQUEST, search);
}

export default watchSearch;
