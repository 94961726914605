import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    notificationsListRequest: ['filters'],
    notificationsListRequestSuccess: ['data'],
    notificationsListHeaderRequestSuccess: ['data'],
    notificationsListRequestError: ['error'],

    notificationsReadRequest: ['data'],
    notificationsReadRequestSuccess: ['data'],
    notificationsReadRequestError: ['error'],

    notificationsPullRequest: [],
    notificationsPullRequestSuccess: ['data'],
    notificationsPullRequestError: ['error'],

    countsAllRequest: [],
    countsAllRequestSuccess: ['data'],
    countsAllRequestError: ['error'],
  },
  {
    prefix: '',
  }
);
