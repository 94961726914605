export const normalizePermissions = (permissions: string[]) => {
  const permObj: any = {};
  for (const permission of permissions) {
    const permissionParts = permission.split('_') as string[];

    if (permissionParts.length !== 2) {
      continue;
    }

    if (typeof permObj[permissionParts[1] as string] === 'undefined') {
      permObj[permissionParts[1] as string] = [permissionParts[0]];
    } else {
      permObj[permissionParts[1] as string].push(permissionParts[0]);
    }
  }

  return permObj;
};

export const validateUser = (
  permission: string,
  currentPermissions: string[]
) => {
  return (
    currentPermissions.includes('all_access') ||
    currentPermissions.includes(permission)
  );
};
