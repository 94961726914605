import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medical-specialties/actions';
import medicalSpecialtiesApi from 'src/services/api/medical-specialties';

export function* list(action: any): any {
  try {
    const response = yield call(medicalSpecialtiesApi.list, action.filters);
    yield put(Creators.medicalSpecialtiesListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.medicalSpecialtiesListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.MEDICAL_SPECIALTIES_LIST_REQUEST, list);
}

export default watchList;
