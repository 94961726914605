import { put, call, takeLatest, all } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get(action: any) {
  try {
    const response = yield call(claimsApi.get, action.id);
    yield put(Creators.claimsGetRequestSuccess(response.data));
    yield put(
      Creators.claimsGetImageRequest({
        claim: response.data.photoKey,
        medicalEntity: response.data.medicalEntity?.logoKey,
      })
    );
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'claims' });
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.claimsGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLatest(Types.CLAIMS_GET_REQUEST, get);
}

export default watchGet;
