import React from 'react';
import Layout from './components';

type withLayoutOptions = {
  component: React.FC<any> | React.ComponentType<any>;
};

export const withLayout = (props: withLayoutOptions) => {
  const { component: Component } = props;

  return (props: any) => {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  };
};

export default withLayout;
