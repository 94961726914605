import axios, { AxiosRequestConfig } from 'axios';
import * as URLS from './urls';
import { ROUTES } from 'src/constants';
import history from 'src/services/browser-history';

const isLocal = process.env.REACT_APP_BACKEND?.includes('localhost');
export const axiosInstance = axios.create({
  baseURL: URLS.BASE_URL,
  responseType: 'json',
  withCredentials: !isLocal,
  // timeout: 3000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      if (isLocal) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          'accessToken'
        )}`;

        if (config.url?.includes('/auth/')) {
          config.url.replace(':3001', ':3005');
        }
      }

      if (!config.params) {
        config.params = {};
      }

      if (config.url?.includes('/upload')) {
        config.headers.contentType = 'multipart/form-data';
        config.headers.post['Content-Type'] = 'multipart/form-data';
      } else if (
        config.url?.includes('/resource') &&
        !config.url?.includes('/parse')
      ) {
        config.responseType = 'blob';
      }

      if (config.method === 'get') {
        if (!(parseInt(config.params.page) >= 1)) {
          config.params.page = '1';
        }

        if (config.params.selector) {
          config.params.limit = 99;
        } else {
          config.params.limit = config.url?.includes('/specialties') ? 50 : 30;
        }
        delete config.params.count;

        if (config.url?.includes('ocr.space')) {
          delete config.params.limit;
          delete config.params.page;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      history.push(ROUTES.AUTH.LOGIN);
    }
    return Promise.reject(error);
  }
);

export const apiRequest = (
  method: AxiosRequestConfig['method'],
  url: AxiosRequestConfig['url'],
  data: AxiosRequestConfig['data'],
  params?: AxiosRequestConfig['params'],
  headers?: AxiosRequestConfig['headers']
) => {
  if (params?.filter === '') {
    delete params.filter;
  }

  return axiosInstance({
    method,
    url,
    data,
    params,
    headers,
  });
};

export const apiExport = (
  url: AxiosRequestConfig['url'],
  params?: AxiosRequestConfig['params']
) => {
  if (isLocal) {
    params.token = localStorage.getItem('accessToken');
  }
  let paramsList: string[] = [];
  for (const param in params) {
    if (param === 's') {
      paramsList.push(`${param}=${JSON.stringify(params[param])}`);
    } else if(Array.isArray(params[param])) {
      const opts = params[param].map((opt: string) => `${param}[]=${opt}`);
      paramsList.push(opts.join('&'));
    } else {
      paramsList.push(`${param}=${params[param]}`);
    }
  }

  const download = `${url}?${paramsList.join('&')}`;
  window.open(download, '_blank');
  return;
};
