import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import DBSocket from 'src/services/websockets';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* listener(action: any) {
  try {
    while (DBSocket.isConnected('claims')) {
      const newClaim = yield call(
        DBSocket.listenToSocket,
        'claims',
        'newClaim'
      );

      yield put(Creators.claimsListenerRequestSuccess(newClaim));
    }
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'new', entity: 'claims' });
    let error = e.response;
    if (error) {
      handleErrorResponse(error);
    } else {
      console.log(e);
    }
    yield put(Creators.claimsListenerRequestError(error));
  }
}

export function* watchListener() {
  yield debounce(6e2, Types.CLAIMS_LISTENER_REQUEST, listener);
}

export default watchListener;
