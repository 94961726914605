import React from 'react';
import { Spinner } from 'react-bootstrap';
import Segment from '../../../templates/Segment';
import classnames from 'classnames';

import styles from './index.module.scss';

export const PageSpinner: React.FC<
  {
    fluid?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className, fluid, ...props }) => {
  return (
    <Segment
      {...props}
      className={classnames(styles.container, className, {
        [styles.fluid]: fluid,
      })}>
      <Spinner animation="border" />
    </Segment>
  );
};

export default PageSpinner;
