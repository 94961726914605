export const MEDICAL_ENTITY_TYPES = {
  G_HOSPITAL: 'G_Hospital',
  HOSPITAL: 'Hospital',
  CLINIC: 'Clinic',
  POLY_CLINIC: 'Poly_Clinic',
};

export const ROUTES = {
  AUTH: {
    LOGIN: '/auth/login',
    FORGET: '/auth/forget',
    RESET: '/auth/reset',
    ACCESS: '/access_denied',
    CHALLENGES: {
      NEW_PASSWORD: '/auth/challenges/NEW_PASSWORD_REQUIRED',
    },
  },
  DASHBOARD: { 
    MAIN: '/dashboard', 
    TPA_STATISTICS: '/tpa-statistics',
    ME_TATISTICS:'/me-statistics'
  },
  CLAIMS: {
    ALL: '/claims/all',
    EDIT: '/claims/:id/edit',
  },
  MEDICAL_ENTITIES: {
    ALL: '/medical-entities/all',
    ADD_ENTITY: '/medical-entities/:type/:gid/add',
    ADD_DOCTORS: '/medical-entities/:type/:id/add/doctors',
    ADD_FIELD_REPS: '/medical-entities/:type/:id/add/field-rep',
    ENTITY: '/medical-entities/:id/edit',
  },
  DOCTORS: {
    ALL: '/doctors/all',
    ADD: '/doctors/add',
    UPLOAD: '/doctors/upload',
    EDIT: '/doctor/:id/edit',
  },
  AGENTS: {
    ALL: '/agents/all',
    ADD: '/agents/add',
    PROFILE: '/agents/:id/profile',
  },
  ROLES: {
    ALL: '/roles/all',
    ADD: '/roles/add',
    EDIT: '/roles/:id',
  },
  FieldRep: {
    ALL: '/field-representatives/all',
    ADD: '/field-representatives/add',
    EDIT: '/field-representatives/:id/edit',
  },
  NOTIFICATIONS: {
    ALL: '/notifications/all',
  },
  SMS_CONFIG: {
    ALL: '/sms-config/all',
    ADD: '/sms-config/add',
    EDIT: '/sms-config/:id/edit',
  },
  CONFIG: {
    ADD: '/config/add',
  },
  Medication_CONFIG: {
    ADD: '/medications/add',
    SEARCH: '/medications/search',
    UPDATE: '/medications/:id',
    BULK: '/medications/bulk/add',
  },
  CPT: {
    ALL: '/CPTs',
    ADD: '/CPTs/add',
    UPDATE: '/CPTs/:type/:id',
  },
  TAGS: {
    ADD_RESPONSE: '/Tags/add/response',
    ADD_FR: '/Tags/add/fr',
    ADD_NOTE: '/Tags/add/note',
    UPDATE_RESPONSE: '/Tags/update/response/:id',
    UPDATE_FR: '/Tags/update/fr/:id',
    UPDATE_NOTE: '/Tags/update/note/:id',
    LIST_RESPONSE: '/Tags/list/response',
    LIST_FR: '/Tags/list/fr',
    LIST_NOTE: '/Tags/list/note',
  },
};

export const AMPLIFY_CONFIG = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
};

interface OBJECT_TYPE {
  [key: string]: any;
}

export const MEDICAL_ENTITIES_TYPES: OBJECT_TYPE = {
  G_Hospital: 'Group hospital',
  Hospital: 'Hospital',
  Clinic: 'Clinic',
  Poly_Clinic: 'Poly Clinic',
};
