import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/disease-codes/actions';
import diseaseCodesApi from 'src/services/api/disease-codes';

export function* list(action: any) {
  try {
    const response = yield call(diseaseCodesApi.list, action.filters);
    yield put(Creators.diseaseCodesListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.diseaseCodesListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.DISEASE_CODES_LIST_REQUEST, list);
}

export default watchList;
