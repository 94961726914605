import { createReducer } from 'reduxsauce';
import { AGENTS_URL } from 'src/services/api/urls';
import history from 'src/services/browser-history';
import { AgentReducerTypes } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: AgentReducerTypes = {
  list: [],
  item: {},
  isOnline: null,
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  isImageLoading: false,
  isLoading: false,
  hasError: false,
};

export const agentsCreateRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const agentsCreateRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const agentsCreateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const agentsListRequest = (state = INITIAL_STATE, action: any) => {
  state.list = [];
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const agentsListRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const { data: list, ...pagination } = action.data.data;

  return {
    ...state,
    list,
    pagination: { ...state.pagination, ...pagination },
    isLoading: false,
    hasError: false,
  };
};

export const agentsListRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const agentsUpdateRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const agentsUpdateRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const agentsUpdateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const agentsDeleteRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const agentsDeleteRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const list = state.list.filter(({ id }: any) => id !== action.id);

  return {
    ...state,
    list,
    isLoading: false,
    hasError: false,
  };
};

export const agentsDeleteRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const agentsGetRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const agentsGetRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    list: [action.data.data],
    item: action.data.data,
    isLoading: false,
    hasError: false,
  };
};

export const agentsImageRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isImageLoading: true,
  };
};

export const agentsImageRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    item: {
      ...state.item,
      image:
        action.data.entity === 'agent'
          ? action.data.image
          : (state.item as any)?.image,
    },
    isImageLoading: false,
    hasError: false,
  };
};

export const agentsImageRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isImageLoading: false,
    hasError: true,
  };
};

export const agentsGetRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const agentsMFARequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const agentsMFARequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    list: [action.data.data],
    isLoading: false,
    hasError: false,
  };
};

export const agentsMFARequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const agentsStatusRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const agentsStatusRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
    isOnline: action.data?.isOnline,
  };
};

export const agentsStatusRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => {
  if ('/agents/all'.includes(history.location.pathname.split('/')[1])) {
    return {
      ...INITIAL_STATE,
      pagination: state.pagination,
      isOnline: state.isOnline,
    };
  }
  return { ...INITIAL_STATE, isOnline: state.isOnline };
};

export const HANDLERS = {
  [Types.AGENTS_CREATE_REQUEST as string]: agentsCreateRequest,
  [Types.AGENTS_CREATE_REQUEST_SUCCESS as string]: agentsCreateRequestSuccess,
  [Types.AGENTS_CREATE_REQUEST_ERROR as string]: agentsCreateRequestError,

  [Types.AGENTS_LIST_REQUEST as string]: agentsListRequest,
  [Types.AGENTS_LIST_REQUEST_SUCCESS as string]: agentsListRequestSuccess,
  [Types.AGENTS_LIST_REQUEST_ERROR as string]: agentsListRequestError,

  [Types.AGENTS_UPDATE_REQUEST as string]: agentsUpdateRequest,
  [Types.AGENTS_UPDATE_REQUEST_SUCCESS as string]: agentsUpdateRequestSuccess,
  [Types.AGENTS_UPDATE_REQUEST_ERROR as string]: agentsUpdateRequestError,

  [Types.AGENTS_DELETE_REQUEST as string]: agentsDeleteRequest,
  [Types.AGENTS_DELETE_REQUEST_SUCCESS as string]: agentsDeleteRequestSuccess,
  [Types.AGENTS_DELETE_REQUEST_ERROR as string]: agentsDeleteRequestError,

  [Types.AGENTS_GET_REQUEST as string]: agentsGetRequest,
  [Types.AGENTS_GET_REQUEST_SUCCESS as string]: agentsGetRequestSuccess,
  [Types.AGENTS_GET_REQUEST_ERROR as string]: agentsGetRequestError,

  [Types.AGENTS_MFA_REQUEST as string]: agentsMFARequest,
  [Types.AGENTS_MFA_REQUEST_SUCCESS as string]: agentsMFARequestSuccess,
  [Types.AGENTS_MFA_REQUEST_ERROR as string]: agentsMFARequestError,

  [Types.AGENTS_IMAGE_REQUEST as string]: agentsImageRequest,
  [Types.AGENTS_IMAGE_REQUEST_SUCCESS as string]: agentsImageRequestSuccess,
  [Types.AGENTS_IMAGE_REQUEST_ERROR as string]: agentsImageRequestError,

  [Types.AGENTS_STATUS_REQUEST as string]: agentsStatusRequest,
  [Types.AGENTS_STATUS_REQUEST_SUCCESS as string]: agentsStatusRequestSuccess,
  [Types.AGENTS_STATUS_REQUEST_ERROR as string]: agentsStatusRequestError,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
