import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    claimsReportRequest: [],
    claimsReportRequestSuccess: ['data'],
    claimsReportRequestError: ['error'],

    claimsExportRequest: ['filters', 'filterType'],
    claimsExportRequestSuccess: ['data'],
    claimsExportRequestError: ['error'],

    claimsCreateRequest: ['claim'],
    claimsCreateRequestSuccess: ['data'],
    claimsCreateRequestError: ['error'],

    claimsListRequest: ['filters'],
    claimsListRequestSuccess: ['data'],
    claimsListRequestError: ['error'],

    claimsTrackRequest: ['tracker'],
    claimsTrackRequestSuccess: ['data'],
    claimsTrackRequestError: ['error'],

    claimsAdjustRequest: [],

    claimsListenerRequest: [],
    claimsListenerRequestSuccess: ['data'],
    claimsListenerRequestError: ['error'],

    claimsUpdateRequest: ['claim', 'submissionType'],
    claimsUpdateRequestSuccess: ['data'],
    claimsUpdateRequestError: ['error', 'data'],

    claimsDeleteRequest: ['id'],
    claimsDeleteRequestSuccess: ['data'],
    claimsDeleteRequestError: ['error'],

    claimsGetRequest: ['id'],
    claimsGetRequestSuccess: ['claim'],
    claimsGetRequestError: ['error'],

    claimsGetImageRequest: ['photoKey'],
    claimsGetImageRequestSuccess: ['image'],
    claimsGetImageRequestError: ['error'],

    claimsGetAttachmentRequest: ['photoKey'],
    claimsGetAttachmentRequestSuccess: ['image'],
    claimsGetAttachmentRequestError: ['error'],

    claimsCountRequest: [],
    claimsCountRequestSuccess: ['count'],
    claimsCountRequestError: ['error'],

    claimsParseImageRequest: ['data'],
    claimsParseImageRequestSuccess: ['data'],
    claimsParseImageRequestError: ['error'],

    claimsAssignSelfRequest: ['id', 'internal'],
    claimsAssignSelfRequestSuccess: ['claim'],
    claimsAssignSelfRequestError: ['error'],

    claimsCheckEligibilityRequest: ['formik'],
    claimsCheckEligibilityRequestSuccess: ['data'],
    claimsCheckEligibilityRequestError: ['error'],

    claimsCancelRequest: ['id'],
    claimsCancelRequestSuccess: [],
    claimsCancelRequestError: [],

    claimsAssignRequest: ['userId', 'claimId'],
    claimsAssignRequestSuccess: ['claim'],
    claimsAssignRequestError: [],

    claimsHistoryRequest: ['claimId'],
    claimsHistoryRequestSuccess: ['data'],
    claimsHistoyRequestError: [],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
