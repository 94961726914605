import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    STATISTICS_TICKET_OVERVIEW_REQUEST
    STATISTICS_TICKET_OVERVIEW_REQUEST_SUCCESS
    STATISTICS_TICKET_OVERVIEW_REQUEST_ERROR

    STATISTICS_SLA_REQUEST
    STATISTICS_SLA_REQUEST_SUCCESS
    STATISTICS_SLA_REQUEST_ERROR

    STATISTICS_FRQ_REQUEST
    STATISTICS_FRQ_REQUEST_SUCCESS
    STATISTICS_FRQ_REQUEST_ERROR

    STATISTICS_MEDICAL_ENTITY_REQUEST
    STATISTICS_MEDICAL_ENTITY_REQUEST_SUCCESS
    STATISTICS_MEDICAL_ENTITY_REQUEST_ERROR

    STATISTICS_MEDICAL_ENTITY_TYPED_REQUEST
    STATISTICS_MEDICAL_ENTITY_TYPED_REQUEST_SUCCESS
    STATISTICS_MEDICAL_ENTITY_TYPED_REQUEST_ERROR

    STATISTICS_MEDICAL_GROUP_REQUEST
    STATISTICS_MEDICAL_GROUP_REQUEST_SUCCESS
    STATISTICS_MEDICAL_GROUP_REQUEST_ERROR


    

    STATISTICS_UPDATE_DATE_FILTERS
    STATISTICS_UPDATE_ENTITY_FILTERS
    STATISTICS_UPDATE_MEDICAL_GROUPS_FILTER

    STATISTICS_GET_STATISTICS_DATA

    STATISTICS_CLEAR_DATE_FILTERS
    STATISTICS_CLEAR_ENTITY_FILTERS
    STATISTICS_CLEAR_MEDICAL_GROUPS_FILTER


    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
