import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/auth/actions';
import { Creators as RoleCreators } from 'src/store/redux/roles/actions';
import AuthAPI from 'src/services/api/auth';
import toaster from 'src/services/toaster';
import history from 'src/services/browser-history';
import { ROUTES } from 'src/constants';
import { mfaVerify } from 'src/services/api/auth';
import { DBErrorMonitor } from 'src/services/error-monitor';
import { handleErrorResponse } from 'src/lib/helpers/errors';

export function* login(action: any) {
  try {
    //remove old access token
    localStorage.removeItem('accessToken');

    const response = yield call(
      AuthAPI.login,
      action.user.email,
      action.user.password
    );
    if (response.data.needMFA) {
      const resToken = yield call(mfaVerify, action.user);
    
      let token = resToken.data?.tokenData?.AuthenticationResult?.AccessToken;
      const isLocal = process.env.REACT_APP_BACKEND?.includes('localhost');

      if (isLocal) {
        token= resToken.data?.tokenData;
        localStorage.setItem('accessToken', token);
      }
      
      const resUser = yield call(AuthAPI.getUser);
      yield put(Creators.loginRequestSuccess(resUser.data));
      history.push(ROUTES.DASHBOARD.MAIN);
      window.location.href = `${window.location.origin}${ROUTES.DASHBOARD.MAIN}`;
    }
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'login', entity: 'auth' });
    if (e.response) {
      handleErrorResponse(e.response);
    }
    yield put(Creators.loginRequestError(e));
  }
}

export function* watchLogin() {
  yield takeLeading(Types.LOGIN_REQUEST, login);
}

export default watchLogin;
