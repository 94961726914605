import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medication-config/actions';
import medsApi from 'src/services/api/medication-config';

export function* list(action: any) {
  try {
    const response = yield call(medsApi.listBulk);
    yield put(Creators.medicationConfBulkListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.medicationConfBulkListRequestError(error));
  }
}

export function* watchBulkList() {
  yield debounce(6e2, Types.MEDICATION_CONF_BULK_LIST_REQUEST, list);
}

export default watchBulkList;
