import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medical-entities/actions';
import medicalEntitiesApi from 'src/services/api/medical-entities';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* update({ medicalEntity }: any) {
  try {
    const response = yield call(medicalEntitiesApi.update, medicalEntity);
    yield put(Creators.medicalEntitiesUpdateRequestSuccess(response));
    //yield put(Creators.medicalEntitiesGetRequest(medicalEntity.id));
    toaster.success('entity were updated successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'update',
      entity: 'medicalEntities',
    });
    handleErrorResponse(error);
    yield put(Creators.medicalEntitiesUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.MEDICAL_ENTITIES_UPDATE_REQUEST, update);
}

export default watchUpdate;
