import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medications/actions';
import medicationsApi from 'src/services/api/medications';

export function* list(action: any) {
  try {
    const response = yield call(medicationsApi.list, action.filters);
    yield put(Creators.medicationsListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.medicationsListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(500, Types.MEDICATIONS_LIST_REQUEST, list);
}

export default watchList;
