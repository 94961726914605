import { put, call, takeLatest, all } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get(action: any) {
  try {
    for (const key in action.photoKey) {
      const currentKey = action.photoKey[key];
      if (!currentKey) {
        continue;
      }
      const imgResponse = yield call(claimsApi.fetchImage, currentKey);

      const reader = new FileReader();
      const blob = imgResponse.data; //yield call([imgResponse.data, imgResponse.data?.blob]);
      const imagePromise = (): Promise<unknown> =>
        new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsDataURL(blob);
        });
      const image = yield call(imagePromise);
      const data = { image, entity: key };
      yield put(Creators.claimsGetImageRequestSuccess(data));
    }
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'claims' });
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.claimsGetImageRequestError(error));
  }
}

export function* watchGetImage() {
  yield takeLatest(Types.CLAIMS_GET_IMAGE_REQUEST, get);
}

export default watchGetImage;
