import { takeEvery, put, call, select } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/statistics-me/actions';
import statisticsAPI from 'src/services/api/statistics';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';
import ReduxStoreTypes from 'src/store/redux/types';

export function* getStatsMedicalEntities(filters: any = null) {
  try {
    // @ts-ignore: Unreachable code error
    const response = yield call(statisticsAPI.statsMedicalEntities, filters);
    yield put(Creators.statisticsMeMedicalEntityRequestSuccess(response));
  } catch (e) {
    let error = e;
    DBErrorMonitor.captureException(e, {
      saga: 'getStatsMedicalEntities',
      entity: 'statistics',
    });
    handleErrorResponse(error);
    yield put(Creators.statisticsMeMedicalEntityRequestError(error));
  }
}

export function* watchGetStatsMedicalEntities() {
  yield takeEvery(
    Types.STATISTICS_ME_MEDICAL_ENTITY_REQUEST,
    getStatsMedicalEntities
  );
}

export default watchGetStatsMedicalEntities;
