import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    userRequest: [],
    userRequestSuccess: ['data'],
    userRequestError: ['error'],

    loginRequest: ['user'],
    loginRequestSuccess: ['data'],
    loginRequestError: ['error'],

    logoutRequest: [],
    logoutRequestSuccess: ['data'],
    logoutRequestError: ['error'],

    forgetRequest: ['user'],
    forgetRequestSuccess: ['data'],
    forgetRequestError: ['error'],

    resetRequest: ['user'],
    resetRequestSuccess: ['data'],
    resetRequestError: ['error'],

    completeNewPasswordRequest: ['user'],
    completeNewPasswordRequestSuccess: [],
    completeNewPasswordRequestError: ['error'],
  },
  {
    prefix: '',
  }
);
