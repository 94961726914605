import { apiRequest } from '../index';
import { COMMENTS_URL } from '../urls';

export const create = (comment: any) => {
  return apiRequest('POST', COMMENTS_URL, comment);
};

export default {
  create,
};
