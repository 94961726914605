import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {

    statisticsTicketOverviewRequest: ['filters'],
    statisticsTicketOverviewRequestSuccess: ['data'],
    statisticsTicketOverviewRequestError: ['error'], 

    statisticsSlaRequest: ['filters'],
    statisticsSlaRequestSuccess: ['data'],
    statisticsSlaRequestError: ['error'],

    statisticsFrqRequest: ['filters'],
    statisticsFrqRequestSuccess: ['data'],
    statisticsFrqRequestError: ['error'],


    statisticsMedicalEntityRequest: ['filters'],
    statisticsMedicalEntityRequestSuccess: ['data'],
    statisticsMedicalEntityRequestError: ['error'],

    statisticsMedicalEntityTypedRequest: ['filters'],
    statisticsMedicalEntityTypedRequestSuccess: ['data'],
    statisticsMedicalEntityTypedRequestError: ['error'],



    statisticsUpdateDateFilters: ['filters'],
    statisticsUpdateEntityFilters: ['filters'],
    statisticsUpdateMedicalGroupsFilter: ['filters'],

    statisticsGetStatisticsData: [],

    statisticsClearDateFilters: [],
    statisticsClearEntityFilters: [],
    statisticsClearMedicalGroupsFilter: [],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
