import { apiRequest } from '../index';
import { MEDICAL_DEGREES_URL } from '../urls';
import { Filters } from 'src/props/filters.props';
import { CacheManager } from 'src/services/cache';

export const list = async (filters: Filters) => {
  const cacheMgr = new CacheManager();
  await cacheMgr.init('degrees-cache', 30);

  const url = MEDICAL_DEGREES_URL;
  const response = await cacheMgr.getCache(url, 'json');

  if (response) {
    return { data: response };
  }

  const data = apiRequest('GET', MEDICAL_DEGREES_URL, null, filters);
  data.then((res) => {
    cacheMgr.setCache(url, res, 'json');
  });

  return data;
};

export default {
  list,
};
