import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/agents/actions';
import agentsApi from 'src/services/api/agents';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* list(action: any) {
  try {
    const response = yield call(agentsApi.list, action.filters);
    yield put(Creators.agentsListRequestSuccess(response));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'list', entity: 'agents' });
    handleErrorResponse(error);
    yield put(Creators.agentsListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.AGENTS_LIST_REQUEST, list);
}

export default watchList;
