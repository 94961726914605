import { takeLatest, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medication-config/actions';
import medApi from 'src/services/api/medication-config';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* exportReport({ filter }: any) {
  try {
    const response = yield call(medApi.exportProcessFile, filter);
    yield put(
      Creators.medicationConfExportProcessFileRequestSuccess(response?.data)
    );
  } catch (e) {
    DBErrorMonitor.captureException(e, {
      saga: 'export',
      entity: 'medication-config',
    });
    let error = e.response;
    yield put(Creators.medicationConfExportProcessFileRequestError(error));
  }
}

export function* watchExportProcessFile() {
  yield takeLatest(
    Types.MEDICATION_CONF_EXPORT_PROCESS_FILE_REQUEST,
    exportReport
  );
}

export default watchExportProcessFile;
