import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medication-config/actions';
import medApi from 'src/services/api/medication-config';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* exportReport() {
  try {
    const response = yield call(medApi.exportMedications);
    yield put(Creators.medicationConfExportRequestSuccess(response?.data));
  } catch (e) {
    DBErrorMonitor.captureException(e, {
      saga: 'export',
      entity: 'medication-config',
    });
    let error = e.response;
    yield put(Creators.medicationConfExportRequestError(error));
  }
}

export function* watchExport() {
  yield debounce(6e2, Types.MEDICATION_CONF_EXPORT_REQUEST, exportReport);
}

export default watchExport;
