import { createReducer } from 'reduxsauce';
import { NotificationsReducerTypes } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: NotificationsReducerTypes = {
  list: [],
  headerList: [],
  counts: [],
  countLoading: false,
  pagination: {
    count: 5,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  isLoading: false,
  hasError: false,
};

export const listRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const listRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data, ...pagination } = action.data;
  delete action.place;
  return {
    ...state,
    list: data,
    pagination: { ...state.pagination, ...pagination },
    isLoading: false,
    hasError: false,
  };
};

export const listHeaderRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const { data } = action;
  delete data.place;
  return {
    ...state,
    headerList: data.data,
    isLoading: false,
    hasError: false,
  };
};

export const listRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const readRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const readRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action;
  const list: any[] = [data, ...state.list];
  return {
    ...state,
    list,
    isLoading: false,
    hasError: false,
  };
};

export const readRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const countsRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    countLoading: true,
  };
};

export const countsRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action;
  return {
    ...state,
    counts: data.data,
    countLoading: false,
    hasError: false,
  };
};

export const countsRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    countLoading: false,
    hasError: true,
  };
};

export const pullRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const pullRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action;
  const list: any[] = [data, ...state.list];
  return {
    ...state,
    list,
    isLoading: false,
    hasError: false,
  };
};

export const pullRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};
export const HANDLERS = {
  [Types.NOTIFICATIONS_LIST_REQUEST as string]: listRequest,
  [Types.NOTIFICATIONS_LIST_REQUEST_SUCCESS as string]: listRequestSuccess,
  [Types.NOTIFICATIONS_LIST_HEADER_REQUEST_SUCCESS as string]: listHeaderRequestSuccess,
  [Types.NOTIFICATIONS_LIST_REQUEST_ERROR as string]: listRequestError,

  [Types.NOTIFICATIONS_PULL_REQUEST as string]: pullRequest,
  [Types.NOTIFICATIONS_PULL_REQUEST_SUCCESS as string]: pullRequestSuccess,
  [Types.NOTIFICATIONS_PULL_REQUEST_ERROR as string]: pullRequestError,

  [Types.NOTIFICATIONS_READ_REQUEST as string]: readRequest,
  [Types.NOTIFICATIONS_READ_REQUEST_SUCCESS as string]: readRequestSuccess,
  [Types.NOTIFICATIONS_READ_REQUEST_ERROR as string]: readRequestError,

  [Types.COUNTS_ALL_REQUEST as string]: countsRequest,
  [Types.COUNTS_ALL_REQUEST_SUCCESS as string]: countsRequestSuccess,
  [Types.COUNTS_ALL_REQUEST_ERROR as string]: countsRequestError,
};

export default createReducer(INITIAL_STATE, HANDLERS);
