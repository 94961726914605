import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medical-entities/actions';
import medicalEntitiesApi from 'src/services/api/medical-entities';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* list(action: any) {
  try {
    if (action.filters?.selector) {
      if (!action.filters?.filter) {
        action.filters.filter = 'isDeleted||eq||false';
      } else if (typeof action.filters?.filter === 'string') {
        action.filters.filter = [action.filters.filter, 'isDeleted||eq||false'];
      } else {
        action.filters.filter.push('isDeleted||eq||false');
      }
    }

    const response = yield call(medicalEntitiesApi.list, action.filters);
    yield put(Creators.medicalEntitiesListRequestSuccess(response));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'list',
      entity: 'medicalEntities',
    });
    handleErrorResponse(error);
    yield put(Creators.medicalEntitiesListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.MEDICAL_ENTITIES_LIST_REQUEST, list);
}

export default watchList;
