import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* update(action: any) {
  try {
    const response = yield call(
      claimsApi.update,
      action.claim,
      action.submissionType
    );
    yield put(Creators.claimsUpdateRequestSuccess(response.data));
    toaster.success('Prescription updated successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'update', entity: 'claims' });
    handleErrorResponse(error);
    yield put(Creators.claimsUpdateRequestError(error, action.claim));
  }
}

export function* watchUpdate() {
  yield debounce(6e2, Types.CLAIMS_UPDATE_REQUEST, update);
}

export default watchUpdate;
