import { createReducer } from 'reduxsauce';
import history from 'src/services/browser-history';
import { TPAStatisticsReducerType } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: TPAStatisticsReducerType = {

  ticketOverviewData: null,
  ticketOverviewLoading: false,
  ticketOverviewError: false,

  slaData:null,
  slaLoading:false,
  slaError:false,

  frqData:null,
  frqLoading:false,
  frqError:false,

  medicalEntitiesStats: null,
  medicalEntitiesStatsLoading: false,
  medicalEntitiesStatsError: false,

  medicalGroupStats:[],
  

  medicalEntitiesStatsTyped: null,
  medicalEntitiesStatsTypedLoading: false,
  medicalEntitiesStatsTypedError: false,


  dateFilter: null,
  entityFilter: {entityType:null,entityName:[]},

  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
};

const getTicketOverview = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    ticketOverviewLoading: true,
    ticketOverviewError: false,
  };
};

const getTicketOverviewSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    ticketOverviewData: action.data,
    ticketOverviewLoading: false,
    ticketOverviewError: false,
  };
};

const getTicketOverviewError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    ticketOverviewLoading: false,
    ticketOverviewError: true,
  };
};



const getSla = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    slaLoading: true,
    slaError: false,
  };
};

const getSlaSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    slaData: action.data,
    slaLoading: false,
    slaError: false,
  };
};

const getSlaError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    slaLoading: false,
    slaError: true,
  };
};

const getFrq = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    frqLoading: true,
    frqError: false,
  };
};

const getFrqSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    frqData: action.data,
    frqLoading: false,
    frqError: false,
  };
};

const getFrqError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    frqLoading: false,
    frqError: true,
  };
};




const getMedTypedStats = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    medicalEntitiesStatsTypedLoading: true,
    medicalEntitiesStatsTypedError: false,
  };
};

const getMedTypedStatsSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    totalClaimsCount:
      action.data.data.length > 0
        ? action.data?.data
            ?.map((ent: any) => Number(ent.NumOfClaims))
            .reduce((accumulator: number, currentValue: number) => {
              return Number(accumulator) + Number(currentValue);
            })
        : null,
    medicalEntitiesStatsTyped: action.data.data,
    medicalEntitiesStatsTypedLoading: false,
    medicalEntitiesStatsTypedError: false,
  };
};

const getMedTypedStatsError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,

    medicalEntitiesStatsTypedLoading: false,
    medicalEntitiesStatsTypedError: true,
  };
};




const updateStatsDateFilters = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    dateFilter: action.filters,
  };
};

const updateStatsEntityFilters = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    entityFilter:action.filters
              
  };
};

const updateMedGroupFilter = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    medicalGroupStats:action.filters
              
  };
};

export const fetchStatsData = (state = INITIAL_STATE, action: any) => {
  return state;
};

const clearDateFilter = (state = INITIAL_STATE, action: any) => {
  return { ...state, dateFilter: null };
};

const clearEntityFilter = (state = INITIAL_STATE, action: any) => {
  return { ...state, entityFilter: null };
};
const clearMedGroupFilter = (state = INITIAL_STATE, action: any) => {
  return { ...state, medicalGroupStats: null };
};

export const clearStore = (state = INITIAL_STATE) => {
  if ('/dashboard'.includes(history.location.pathname.split('/')[1])) {
    return { ...INITIAL_STATE, pagination: state.pagination };
  }
  return INITIAL_STATE;
};

export const HANDLERS = {
  
  [Types.STATISTICS_MEDICAL_ENTITY_TYPED_REQUEST as string]: getMedTypedStats,
  [Types.STATISTICS_MEDICAL_ENTITY_TYPED_REQUEST_SUCCESS as string]: getMedTypedStatsSuccess,
  [Types.STATISTICS_MEDICAL_ENTITY_TYPED_REQUEST_ERROR as string]: getMedTypedStatsError,

  
  [Types.STATISTICS_SLA_REQUEST as string]: getSla,
  [Types.STATISTICS_SLA_REQUEST_SUCCESS as string]: getSlaSuccess,
  [Types.STATISTICS_SLA_REQUEST_ERROR as string]: getSlaError,


  [Types.STATISTICS_FRQ_REQUEST as string]: getFrq,
  [Types.STATISTICS_FRQ_REQUEST_SUCCESS as string]: getFrqSuccess,
  [Types.STATISTICS_FRQ_REQUEST_ERROR as string]: getFrqError,

  [Types.STATISTICS_UPDATE_DATE_FILTERS as string]: updateStatsDateFilters,
  [Types.STATISTICS_UPDATE_ENTITY_FILTERS as string]: updateStatsEntityFilters,
  [Types.STATISTICS_UPDATE_MEDICAL_GROUPS_FILTER as string]: updateMedGroupFilter,

  [Types.STATISTICS_GET_STATISTICS_DATA as string]: fetchStatsData,

  [Types.STATISTICS_TICKET_OVERVIEW_REQUEST as string]: getTicketOverview,
  [Types.STATISTICS_TICKET_OVERVIEW_REQUEST_SUCCESS as string]: getTicketOverviewSuccess,
  [Types.STATISTICS_TICKET_OVERVIEW_REQUEST_ERROR as string]: getTicketOverviewError,

  [Types.STATISTICS_CLEAR_DATE_FILTERS]: clearDateFilter,
  [Types.STATISTICS_CLEAR_ENTITY_FILTERS]: clearEntityFilter,
  [Types.STATISTICS_CLEAR_MEDICAL_GROUPS_FILTER]: clearMedGroupFilter,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
