import { takeLatest, put, call } from 'redux-saga/effects';
import { Types } from '../../redux/cpt/action';
import cptApi from '../../../services/api/cpt';
import { Creators } from '../../redux/cpt/action';

export function* searchCPT(action: any) {
  const { Type, filters, search } = action;

  try {
    let response = yield call(cptApi.search, Type, filters, search);
    yield put(Creators.cptSearchRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.cptSearchRequestError(error));
  }
}

export function* watchSearch() {
  yield takeLatest(Types.CPT_SEARCH_REQUEST, searchCPT);
}
export default watchSearch;
