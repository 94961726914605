import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FIELD_REPRESENTATIVES_LIST_REQUEST
    FIELD_REPRESENTATIVES_LIST_REQUEST_SUCCESS
    FIELD_REPRESENTATIVES_LIST_REQUEST_ERROR

    FIELD_REPRESENTATIVES_RESET_REQUEST
    FIELD_REPRESENTATIVES_RESET_REQUEST_SUCCESS
    FIELD_REPRESENTATIVES_RESET_REQUEST_ERROR

    FIELD_REPRESENTATIVES_CREATE_REQUEST
    FIELD_REPRESENTATIVES_CREATE_REQUEST_SUCCESS
    FIELD_REPRESENTATIVES_CREATE_REQUEST_ERROR

    FIELD_REPRESENTATIVES_DELETE_REQUEST
    FIELD_REPRESENTATIVES_DELETE_REQUEST_SUCCESS
    FIELD_REPRESENTATIVES_DELETE_REQUEST_ERROR

    FIELD_REPRESENTATIVES_UPDATE_REQUEST
    FIELD_REPRESENTATIVES_UPDATE_REQUEST_SUCCESS
    FIELD_REPRESENTATIVES_UPDATE_REQUEST_ERROR

    FIELD_REPRESENTATIVES_GET_REQUEST
    FIELD_REPRESENTATIVES_GET_REQUEST_SUCCESS
    FIELD_REPRESENTATIVES_GET_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
