import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    MEDICATIONS_LIST_REQUEST
    MEDICATIONS_LIST_REQUEST_SUCCESS
    MEDICATIONS_LIST_REQUEST_ERROR

    MEDICATIONS_CACHE_REQUEST
    MEDICATIONS_CACHE_REQUEST_SUCCESS
    MEDICATIONS_CACHE_REQUEST_ERROR

    CLEAR_STORE

  `,
  {
    prefix: '',
  }
);
