import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medical-groups/actions';
import medicalGroupsApi from 'src/services/api/medical-groups';
import toaster from 'src/services/toaster';

export function* create({ group }: any) {
  try {
    const response = yield call(medicalGroupsApi.create, group);
    yield put(Creators.medicalGroupsCreateRequestSuccess(response));
    toaster.success('medical group were added successfully');
  } catch (e) {
    toaster.error(e.message);
    let error = e.response;
    yield put(Creators.medicalGroupsCreateRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLeading(Types.MEDICAL_GROUPS_CREATE_REQUEST, create);
}

export default watchCreate;
