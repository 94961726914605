import { apiRequest } from '../index';
import { FIELD_REPRESENTATIVES_URL } from '../urls';

export const list = (filters: any) => {
  let filtersArr: String[] = [];
  for (const f in filters) {
    filtersArr.push(`${f}=${filters[f]}`);
  }
  return apiRequest(
    'GET',
    `${FIELD_REPRESENTATIVES_URL}/query/frs`,
    null,
    filters
  );
};

export const create = (fieldRepresentative: any) => {
  const { entityType, ...submitable } = fieldRepresentative;
  return apiRequest('POST', FIELD_REPRESENTATIVES_URL, submitable);
};

export const reset = ({ id }: any) => {
  return apiRequest(
    'GET',
    `${FIELD_REPRESENTATIVES_URL}/reset-password/${id}`,
    null
  );
};

export const update = ({ id, ...data }: any) => {
  const { entityType, ...submitable } = data;
  return apiRequest('PATCH', `${FIELD_REPRESENTATIVES_URL}/${id}`, {
    ...submitable,
    id,
  });
};

export const deleteFieldRepresentative = (id: string | number) => {
  return apiRequest('DELETE', `${FIELD_REPRESENTATIVES_URL}/${id}`, null);
};

export const get = (id: string | number) => {
  return apiRequest('GET', `${FIELD_REPRESENTATIVES_URL}/${id}`, null);
};

export default {
  list,
  create,
  update,
  delete: deleteFieldRepresentative,
  get,
  reset,
};
