import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/field-representatives/actions';
import fieldRepresentativesApi from 'src/services/api/field-representatives';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* update(action: any) {
  try {
    const response = yield call(
      fieldRepresentativesApi.update,
      action.fieldRepresentative
    );
    yield put(Creators.fieldRepresentativesUpdateRequestSuccess(response.data));
    toaster.success('field representative were updated successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'update', entity: 'fieldRep' });
    handleErrorResponse(error);
    yield put(Creators.fieldRepresentativesUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.FIELD_REPRESENTATIVES_UPDATE_REQUEST, update);
}

export default watchUpdate;
