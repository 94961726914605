import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/field-representatives/actions';
import fieldRepresentativesApi from 'src/services/api/field-representatives';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* list(action: any) {
  try {
    if (
      action.filters?.s &&
      action.filters?.s.hasOwnProperty('$and') &&
      Array.isArray(action.filters?.s['$and'])
    ) {
      action.filters.s['$and'].push({ role: { $eq: 'FR' } });
    } else {
      action.filters.s = { $and: [{ role: { $eq: 'FR' } }] };
    }

    const response = yield call(fieldRepresentativesApi.list, action.filters);
    yield put(Creators.fieldRepresentativesListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'list', entity: 'fieldRep' });
    handleErrorResponse(error);
    yield put(Creators.fieldRepresentativesListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.FIELD_REPRESENTATIVES_LIST_REQUEST, list);
}

export default watchList;
