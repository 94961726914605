import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    slaConfigGetRequest: [],
    slaConfigGetRequestSuccess: ['data'],
    slaConfigGetRequestError: ['error'],

    slaConfigUpdateRequest: ['data'],
    slaConfigUpdateRequestSuccess: ['data'],
    slaConfigUpdateRequestError: ['error'],

    slaConfigUpdateAgentCapcRequest: ['capacity'],
    slaConfigUpdateAgentCapcRequestSuccess: [],
    slaConfigUpdateAgentCapcRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
