import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/agents/actions';
import agentsApi from 'src/services/api/agents';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get({ id }: any) {
  try {
    const response = yield call(agentsApi.get, id);
    yield put(Creators.agentsGetRequestSuccess(response));
    yield put(
      Creators.agentsImageRequest({
        agent: response.data.photoKey,
      })
    );
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'agents' });
    handleErrorResponse(error);
    yield put(Creators.agentsGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLeading(Types.AGENTS_GET_REQUEST, get);
}

export default watchGet;
