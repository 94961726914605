import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/roles/actions';
import rolesApi from 'src/services/api/roles';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* create(action: any) {
  try {
    const response = yield call(rolesApi.create, action.data);
    yield put(Creators.rolesAddRequestSuccess(response));
    toaster.success('role were added successfully');
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'create', entity: 'roles' });
    toaster.error(e.message);
    let error = e.response;
    yield put(Creators.rolesAddRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLeading(Types.ROLES_ADD_REQUEST, create);
}

export default watchCreate;
