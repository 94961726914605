import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    MEDICAL_SPECIALTIES_LIST_REQUEST
    MEDICAL_SPECIALTIES_LIST_REQUEST_SUCCESS
    MEDICAL_SPECIALTIES_LIST_REQUEST_ERROR

    CLEAR_STORE
    
  `,
  {
    prefix: '',
  }
);
