import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/doctors/actions';
import doctorsApi from 'src/services/api/doctors';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* list(action: any) {
  try {
    const response = yield call(doctorsApi.list, action.filters);
    yield put(Creators.doctorsListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'list', entity: 'doctors' });
    handleErrorResponse(error);
    yield put(Creators.doctorsListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.DOCTORS_LIST_REQUEST, list);
}

export default watchList;
