import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medication-config/actions';
import toaster from 'src/services/toaster';
import medsApi from 'src/services/api/medication-config';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* update(data: any) {
  try {
    const response = yield call(medsApi.update, data);
    yield put(Creators.medicationConfUpdateRequestSuccess(response));
    toaster.success('Medication were updated successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'update',
      entity: 'medication-config',
    });
    handleErrorResponse(error);
    yield put(Creators.medicationConfUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.MEDICATION_CONF_UPDATE_REQUEST, update);
}

export default watchUpdate;
