import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    fieldRepresentativesListRequest: ['filters'],
    fieldRepresentativesListRequestSuccess: ['data'],
    fieldRepresentativesListRequestError: ['error'],

    fieldRepresentativesResetRequest: ['id'],
    fieldRepresentativesResetRequestSuccess: ['data'],
    fieldRepresentativesResetRequestError: ['error'],

    fieldRepresentativesCreateRequest: ['fieldRepresentative'],
    fieldRepresentativesCreateRequestSuccess: ['fieldRepresentative'],
    fieldRepresentativesCreateRequestError: ['error'],

    fieldRepresentativesDeleteRequest: ['id'],
    fieldRepresentativesDeleteRequestSuccess: ['data'],
    fieldRepresentativesDeleteRequestError: ['error'],

    fieldRepresentativesUpdateRequest: ['fieldRepresentative'],
    fieldRepresentativesUpdateRequestSuccess: ['fieldRepresentative'],
    fieldRepresentativesUpdateRequestError: ['error'],

    fieldRepresentativesGetRequest: ['id'],
    fieldRepresentativesGetRequestSuccess: ['data'],
    fieldRepresentativesGetRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
