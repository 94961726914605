import dayjs from 'dayjs';

export class CacheManager {
  public cache: Cache | null = null;

  async init(cacheName: string, expirationDays: number) {
    this.cache = await caches.open(cacheName);

    const latestCacheDate = localStorage.getItem(`cacheTimestamp-${cacheName}`);
    const diff = dayjs().subtract(expirationDays, 'days').format('x');

    if (!latestCacheDate || latestCacheDate < diff) {
      caches.delete(cacheName);

      const currentTimestamp = dayjs().format('x');
      localStorage.setItem(`cacheTimestamp-${cacheName}`, currentTimestamp);
    }
  }

  async getCache(url: string, type: 'json' | 'blob' = 'blob') {
    let options: RequestInit | undefined;

    if (type === 'json') {
      options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }

    const req = new Request(url, options);
    const data = await this.cache?.match(req);

    if (data) {
      if (type === 'json' && data.json) {
        return await data.json();
      } else if (type === 'blob' && data.blob) {
        return await data.blob();
      }
    }

    return null;
  }

  setCache(url: string, data: any, type: 'json' | 'blob' = 'blob') {
    let options: ResponseInit | undefined;

    if (type === 'json') {
      options = {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      };

      const res = new Response(JSON.stringify(data.data), options);
      this.cache?.put(url, res);
    } else {
      const res = new Response(data);
      this.cache?.put(url, res);
    }
  }
}
