import React from 'react';
import { Nav } from 'react-bootstrap';
import { FaAngleRight, FaAngleDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

import styles from './index.module.scss';
import { NavTabProps, NavTabsProps } from 'src/props/navbar.props';

export const NavTabs = (props: NavTabsProps) => {
  const [isOpen, setIsOpen] = React.useState(props?.isOpen || false);
  const handleOpen = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <Nav.Link as="div" className={styles.submenu} onClick={handleOpen}>
      <div className={styles.link}>
        <span className={styles.icon}>{props.icon}</span>
        <span className={styles.title}>{props.title}</span>
        <span className={styles.icon}>
          {isOpen ? <FaAngleDown /> : <FaAngleRight />}
        </span>
      </div>

      <div className={classnames(styles.tabs, { [styles.active]: isOpen })}>
        {props.children}
      </div>
    </Nav.Link>
  );
};

NavTabs.Tab = (props: NavTabProps) => {
  if (props.tabular) {
    return (
      <Nav.Link
        as={Link}
        to={props.to}
        className={styles.navlink}
        active={props?.active}
        hidden={props?.hidden}>
        <span className={styles.icon}>{props.icon}</span>
        <span className={props?.isParent ? styles.bold_title : styles.title}>
          {props.title}
        </span>
      </Nav.Link>
    );
  }

  return (
    <Link
      to={props.to}
      className={classnames(styles.tab, { [styles.active]: props?.active })}
      hidden={props?.hidden}>
      {props.title}
    </Link>
  );
};

export default NavTabs;
