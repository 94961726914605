import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    smsConfigClaimRequest: ['filters'],
    smsConfigClaimRequestSuccess: ['data'],
    smsConfigClaimRequestError: ['error'],

    smsConfigCreateRequest: ['smsConfig'],
    smsConfigCreateRequestSuccess: ['smsConfig'],
    smsConfigCreateRequestError: ['error'],

    smsConfigDeleteRequest: ['id'],
    smsConfigDeleteRequestSuccess: ['data'],
    smsConfigDeleteRequestError: ['error'],

    smsConfigUpdateRequest: ['smsConfig'],
    smsConfigUpdateRequestSuccess: ['smsConfig'],
    smsConfigUpdateRequestError: ['error'],

    smsConfigListRequest: ['filters'],
    smsConfigListRequestSuccess: ['data'],
    smsConfigListRequestError: ['error'],

    smsConfigGetRequest: ['id'],
    smsConfigGetRequestSuccess: ['data'],
    smsConfigGetRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
