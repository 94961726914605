import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/sms-config/actions';
import smsConfigAPi from 'src/services/api/sms-config';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* list(action: any) {
  try {
    const response = yield call(smsConfigAPi.list, action.filters);
    yield put(Creators.smsConfigListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'list', entity: 'sms_config' });
    handleErrorResponse(error);
    yield put(Creators.smsConfigListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.SMS_CONFIG_LIST_REQUEST, list);
}

export default watchList;
