import { createReducer } from 'reduxsauce';
import pagination from 'src/lib/helpers/pagination';
import { CPTReducerTypes } from '../types';
import Types from './action-types';

export const INITIAL_STATE: CPTReducerTypes = {
  list: [],
  item: null,
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  isLoading: false,
  hasError: false,
};

export const listRequest = (state = INITIAL_STATE, action: any) => {
  state.list = [];
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const listRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data: list, ...pagination } = action.data;
  return {
    ...state,
    list,
    pagination: { ...state.pagination, ...action.filters, ...pagination },
    isLoading: false,
    hasError: false,
  };
};

export const listRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const searchRequest = (state = INITIAL_STATE, action: any) => {
  state.list = [];
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const searchRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action;

  return {
    ...state,
    list: data,
    pagination: { ...state.pagination },
    isLoading: false,
    hasError: false,
  };
};

export const searchRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const updateRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { id, ...rest } = action.data;
  const item = action.data || state.item;
  const list = state.list.length
    ? state.list.map((item: any) => {
        if (item.id.toString() === id.toString()) {
          return { ...item, ...rest };
        }
        return item;
      })
    : [];

  return {
    ...state,
    list,
    item,
    isLoading: false,
    hasError: false,
  };
};

export const updateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const uploadRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const uploadRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const getOneCPTRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getOneCPTRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const item = action.data;

  return {
    ...state,
    item,
    isLoading: false,
    hasError: false,
  };
};

export const getOneCPTRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const cptCreateRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
  };
};

export const cptCreateRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const cptCreateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.CPT_LIST_REQUEST as string]: listRequest,
  [Types.CPT_LIST_REQUEST_SUCCESS as string]: listRequestSuccess,
  [Types.CPT_LIST_REQUEST_ERROR as string]: listRequestError,

  [Types.CPT_SEARCH_REQUEST as string]: searchRequest,
  [Types.CPT_SEARCH_REQUEST_SUCCESS as string]: searchRequestSuccess,
  [Types.CPT_SEARCH_REQUEST_ERROR as string]: searchRequestError,

  [Types.CPT_UPDATE_REQUEST as string]: updateRequest,
  [Types.CPT_UPDATE_REQUEST_SUCCESS as string]: updateRequestSuccess,
  [Types.CPT_UPDATE_REQUEST_ERROR as string]: updateRequestError,

  [Types.CPT_UPLOAD_REQUEST as string]: uploadRequest,
  [Types.CPT_UPLOAD_REQUEST_ERROR as string]: uploadRequestError,

  [Types.CPT_GET_REQUEST as string]: getOneCPTRequest,
  [Types.CPT_GET_REQUEST_SUCCESS as string]: getOneCPTRequestSuccess,
  [Types.CPT_GET_REQUEST_ERROR as string]: getOneCPTRequestError,

  [Types.CLEAR_STORE as string]: clearStore,

  [Types.CPT_CREATE_REQUEST as string]: cptCreateRequest,
  [Types.CPT_CREATE_REQUEST_SUCCESS as string]: cptCreateRequestSuccess,
  [Types.CPT_CREATE_REQUEST_ERROR as string]: cptCreateRequestError,
};

export default createReducer(INITIAL_STATE, HANDLERS);
