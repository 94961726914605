import { takeLeading, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/auth/actions';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';
import AuthAPI from 'src/services/api/auth';
export function* forget(action: any) {
  try {
    const response = yield call(AuthAPI.forget,action.user.email) 
    yield put(Creators.forgetRequestSuccess(response));
    toaster.success('Check in your inbox for the activation link');
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'forget', entity: 'auth' });
    toaster.error('Failed to request new password');
    yield put(Creators.forgetRequestError(e));
  }
}

export function* watchForget() {
  yield takeLeading(Types.FORGET_REQUEST, forget);
}

export default watchForget;
