import { all } from 'redux-saga/effects';
import { watchLogin } from './login';
import { watchLogout } from './logout';
import { watchForget } from './forget';
import { watchReset } from './reset';
import { watchNewPassword } from './challenges/new_password_required';
import watchUser from './user';

export function* watchAuth() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchForget(),
    watchReset(),
    watchNewPassword(),
    watchUser(),
  ]);
}
