import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Sidebar from './sidebar';
import Navbar from './top-navbar';

import styles from './index.module.scss';

function LayoutComponent({ children }: { children: any }) {
  
  const hasSidebar = window.toolbar.visible;
  const [toggle, setToggle] = useState<boolean>(false);
  const toggleSidebar = React.useCallback(() => {
    setToggle((toggle) => !toggle);
  }, [toggle]);

  const resizeChange = () => {

    if (window.innerWidth <= 1700) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  useEffect(() => {
    let matches = window.matchMedia('(max-width: 1700px)').matches;
    if (matches||window.location.href.includes('statistics')) {
      setToggle(true);
    }
  }, []);

  window.addEventListener('resize', resizeChange);

  return (
    <Container className={styles.container} fluid>
      <Row>
        {hasSidebar && (
          <div
            className={
              toggle
                ? `${styles.sidebar} ${styles.hidden}`
                : ` ${styles.sidebar}`
            }>
            <Sidebar toggle={toggleSidebar} />
          </div>
        )}
        <Col
          md={{ span: hasSidebar ? 10 : 12, offset: hasSidebar ? 2 : 0 }}
          className={
            toggle
              ? `${styles.contentCol} ${styles.fullWidthContent}`
              : styles.contentCol
          }
          id="content_res">
          <Navbar toggle={toggleSidebar} />
          <Container className={styles.content} fluid>
            {children}
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

const Layout = React.memo(LayoutComponent);

export default Layout;
