import toaster from 'src/services/toaster';

export const handleErrorResponse = (res: any) => {
 if(!res?.status){
  toaster.error('Failed to retrieve data from server.');
  return
 }
  switch (res?.status) {
    case 400:
      toaster.error(res?.data?.message);
      break;
    case 401:
      // window.location.href = LOGIN_URL;
      break;
    case 500:
      if (res?.config.method === 'get') {
        toaster.error('Failed to retrieve data from server.');
      } else if (res?.config.method === 'post') {
        toaster.error('An Error has occurred while creating this item.');
      } else if (res?.config.method === 'patch') {
        toaster.error('An Error has occurred while updating this item.');
      } else if (res?.config.method === 'delete') {
        toaster.error('An Error has occurred while deleting this item.');
      }
      break;
    default:
      toaster.error(res?.statusText);
  }
};
