import { all } from 'redux-saga/effects';
import { watchListPrem } from './listPerm';
import { watchCreate } from './create';
import { watchList } from './list';
import { watchDelete } from './delete';
import { watchGet } from './get';
import { watchUpdate } from './update';
import { watchGetCurr } from './getcurr';

export function* watchRoles() {
  yield all([
    watchListPrem(),
    watchCreate(),
    watchList(),
    watchDelete(),
    watchGet(),
    watchGetCurr(),
    watchUpdate(),
  ]);
}
