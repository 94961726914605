import { takeLeading, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/Integration/actions';
import itAPI from 'src/services/api/Integration';
import { handleErrorResponse } from 'src/lib/helpers/errors';

export function* get() {
  try {
    const response = yield call(itAPI.get);
    yield put(Creators.itConfigGetRequestSuccess(response));
  } catch (e) {
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.itConfigGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLeading(Types.IT_CONFIG_GET_REQUEST, get);
}

export default watchGet;
