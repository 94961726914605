import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/scans/actions';
import scansApi from 'src/services/api/scans';

export function* categories(action: any) {
  try {
    const response = yield call(scansApi.categories);
    yield put(Creators.scansCategoriesRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.scansCategoriesRequestError(error));
  }
}

export function* watchCategories() {
  yield debounce(6e2, Types.SCANS_CATEGORIES_REQUEST, categories);
}

export default watchCategories;
