import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';

import history from 'src/services/browser-history';
import toaster from 'src/services/toaster';

export function* CancelClaim(action: any) {
  try {
    const response = yield call(claimsApi.cancel, action.id);
    yield put(Creators.claimsCancelRequestSuccess(response.data));
    history.push('/claims/all');
    toaster.success(`Claim number ${action.id} was Canceled`);
  } catch (e) {
    let error = e.response;
    yield put(Creators.claimsCancelRequestError(error));
    toaster.error(`error Canceling claim number ${action.id}`);
  }
}

export function* watchCancel() {
  yield debounce(6e2, Types.CLAIMS_CANCEL_REQUEST, CancelClaim);
}

export default watchCancel;
