import { createReducer } from 'reduxsauce';

import Types from './actions-types';
import { Auth } from 'aws-amplify';
import { DBErrorMonitor } from 'src/services/error-monitor';
import { AuthReducerTypes } from '../types';

export const INITIAL_STATE: AuthReducerTypes = {
  email: '',
  user: null,
  accessToken: localStorage.getItem('accessToken') || '',
  isLoading: false,
  hasError: false,
};

export const userRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};

export const userRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const currentUser = action.data;
  DBErrorMonitor.configureUser({
    email: currentUser?.email,
    name: `${currentUser?.given_name} ${currentUser?.family_name}`,
  });

  return {
    ...state,
    user: currentUser,
    isLoading: false,
    hasError: false,
  };
};

export const userRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const loginRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};

export const loginRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    user: action.data,
    accessToken: localStorage.getItem('accessToken') || '',
    isLoading: false,
    hasError: false,
  };
};

export const loginRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const logoutRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};

export const logoutRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const logoutRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const forgetRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    email: action.user.email,
    isLoading: true,
    hasError: false,
  };
};

export const forgetRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const forgetRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const resetRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};

export const resetRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const resetRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const newPasswordRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};

export const newPasswordRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const newPasswordRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const HANDLERS = {
  [Types.LOGIN_REQUEST as string]: loginRequest,
  [Types.LOGIN_REQUEST_SUCCESS as string]: loginRequestSuccess,
  [Types.LOGIN_REQUEST_ERROR as string]: loginRequestError,

  [Types.USER_REQUEST as string]: userRequest,
  [Types.USER_REQUEST_SUCCESS as string]: userRequestSuccess,
  [Types.USER_REQUEST_ERROR as string]: userRequestError,

  [Types.FORGET_REQUEST as string]: forgetRequest,
  [Types.FORGET_REQUEST_SUCCESS as string]: forgetRequestSuccess,
  [Types.FORGET_REQUEST_ERROR as string]: forgetRequestError,

  [Types.RESET_REQUEST as string]: resetRequest,
  [Types.RESET_REQUEST_SUCCESS as string]: resetRequestSuccess,
  [Types.RESET_REQUEST_ERROR as string]: resetRequestError,

  [Types.COMPLETE_NEW_PASSWORD_REQUEST as string]: newPasswordRequest,
  [Types.COMPLETE_NEW_PASSWORD_REQUEST_SUCCESS as string]: newPasswordRequestSuccess,
  [Types.COMPLETE_NEW_PASSWORD_REQUEST_ERROR as string]: newPasswordRequestError,
};

export default createReducer(INITIAL_STATE, HANDLERS);
