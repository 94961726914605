import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/auth/actions';
import toaster from 'src/services/toaster';
import history from 'src/services/browser-history';
import { ROUTES } from 'src/constants';
import { DBErrorMonitor } from 'src/services/error-monitor';
import AuthAPI from 'src/services/api/auth';
export function* logout(action: any) {
  try {
    // const response = yield Auth.signOut();
    localStorage.removeItem('accessToken');
    yield call(AuthAPI.logout);
    yield put(Creators.logoutRequestSuccess());
    history.push(ROUTES.AUTH.LOGIN);
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'logout', entity: 'auth' });
    toaster.error(e.message || e);
    yield put(Creators.logoutRequestError(e));
  }
}

export function* watchLogout() {
  yield takeLeading(Types.LOGOUT_REQUEST, logout);
}

export default watchLogout;
