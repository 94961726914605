import * as Sentry from '@sentry/react';

export class DBErrorMonitor {
  static init() {
    const environment = process.env.REACT_APP_ENV
      ? process.env.REACT_APP_ENV
      : process.env.NODE_ENV;
    Sentry.init({
      dsn:
        'https://b3de1e891adb4c52a57856eb1a0db091@o435687.ingest.sentry.io/5395410',
      environment,
    });
  }

  static configureUser(user: { name: string; email: string }) {
    Sentry.configureScope(function (scope) {
      scope.setUser(user);
    });
  }

  static reduxInjector() {
    return Sentry.createReduxEnhancer({
      actionTransformer: (action) => {
        if (action.type.includes('CLAIM')) {
          return {
            ...action,
            claim: null,
          };
        } else if (action.type === 'AGENTS_CREATE_REQUEST') {
          return {
            ...action,
            agent: {
              ...action.agent,
              password: null,
              confirm_password: null,
            },
          };
        } else if (
          [
            'LOGIN_REQUEST',
            'FORGET_REQUEST',
            'RESET_REQUEST',
            'COMPLETE_NEW_PASSWORD_REQUEST',
          ].includes(action.type)
        ) {
          return {
            ...action,
            accessToken: null,
          };
        }

        return action;
      },
      stateTransformer: (state) => {
        const transformedState = {
          ...state,
          claims: null,
          auth: {
            accessToken: null,
            user: null,
          },
        };

        return transformedState;
      },
    });
  }

  static captureException(err: any, tags?: any) {
    if (!(err instanceof Error)) {
      err = err?.error;
    }
    Sentry.captureException(err, (scope: any) => {
      for (const key in tags) {
        scope.setTag(key, tags[key]);
      }
      return scope;
    });
  }

  static captureEvent(event: Sentry.Event) {
    Sentry.captureEvent(event);
  }
}
