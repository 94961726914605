import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/sms-config/actions';
import smsConfigApi from 'src/services/api/sms-config';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';
import history from 'src/services/browser-history';

export function* update(action: any) {
  try {
    const response = yield call(smsConfigApi.update, action.smsConfig);
    yield put(Creators.smsConfigUpdateRequestSuccess(response.data));
    toaster.success('SMS config were updated successfully');
    yield put(Creators.smsConfigGetRequestSuccess(response));
    history.push(`/sms-config/all`);
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'update',
      entity: 'sms_config',
    });
    handleErrorResponse(error);
    yield put(Creators.smsConfigUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.SMS_CONFIG_UPDATE_REQUEST, update);
}

export default watchUpdate;
