import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    SCANS_LIST_REQUEST
    SCANS_LIST_REQUEST_SUCCESS
    SCANS_LIST_REQUEST_ERROR

    SCANS_CATEGORIES_REQUEST
    SCANS_CATEGORIES_REQUEST_SUCCESS
    SCANS_CATEGORIES_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
