import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/roles/actions';
import rolesApi from 'src/services/api/roles';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* remove(action: any) {
  try {
    const response = yield call(rolesApi.remove, action.roleId);
    yield put(Creators.rolesDeleteRequestSuccess(response));
    toaster.success('role were deleted successfully');
    yield put(Creators.rolesListRequest());
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'delete', entity: 'roles' });
    toaster.error(e.message);
    let error = e.response;
    yield put(Creators.rolesDeleteRequestError(error));
  }
}

export function* watchDelete() {
  yield takeLeading(Types.ROLES_DELETE_REQUEST, remove);
}

export default watchDelete;
