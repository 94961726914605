import { createReducer } from 'reduxsauce';
import { MedicationConfigReducerTypes } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: MedicationConfigReducerTypes = {
  list: null,
  bulkList: null,
  types: [],
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  currentItem: null,
  isLoading: false,
  isUploading: false,
  hasError: false,
};

export const searchListRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const searchListRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const { data, ...pagination } = action;

  return {
    ...state,
    list: data,
    pagination: { ...state.pagination, ...pagination },
    isLoading: false,
    hasError: false,
  };
};

export const searchListRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const typesRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const typesRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action;

  return {
    ...state,
    types: data,
    isLoading: false,
    hasError: false,
  };
};

export const typesRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const createMedication = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const createMedicationSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const createMedicationError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const updateMedication = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateMedicationSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const updateMedicationError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const exportMeds = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
  };
};

export const exportMedsSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const exportMedsError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const getMedication = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getMedicationSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    currentItem: action.data.data,
    isLoading: false,
    hasError: false,
  };
};
export const getMedicationError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const uploadRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isUploading: true,
  };
};

export const uploadRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action;

  return {
    ...state,
    list: data,
    isUploading: false,
    hasError: false,
  };
};

export const uploadRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isUploading: false,
    hasError: true,
  };
};

const listBulkRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};
const listBulkRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    bulkList: action.data.data,
    isLoading: false,
    hasError: false,
  };
};
const listBulkRequestError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

const exportProcessFile = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};
const exportProcessFileSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};
const exportProcessFileError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const clearStore = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.MEDICATION_CONF_SEARCH_LIST_REQUEST as string]: searchListRequest,
  [Types.MEDICATION_CONF_SEARCH_LIST_REQUEST_SUCCESS as string]: searchListRequestSuccess,
  [Types.MEDICATION_CONF_SEARCH_LIST_REQUEST_ERROR as string]: searchListRequestError,

  [Types.MEDICATION_CONF_CREATE_REQUEST as string]: createMedication,
  [Types.MEDICATION_CONF_CREATE_REQUEST_SUCCESS as string]: createMedicationSuccess,
  [Types.MEDICATION_CONF_CREATE_REQUEST_ERROR as string]: createMedicationError,

  [Types.MEDICATION_CONF_UPDATE_REQUEST as string]: updateMedication,
  [Types.MEDICATION_CONF_UPDATE_REQUEST_SUCCESS as string]: updateMedicationSuccess,
  [Types.MEDICATION_CONF_UPDATE_REQUEST_ERROR as string]: updateMedicationError,

  [Types.MEDICATION_CONF_EXPORT_REQUEST as string]: exportMeds,
  [Types.MEDICATION_CONF_EXPORT_REQUEST_SUCCESS as string]: exportMedsSuccess,
  [Types.MEDICATION_CONF_EXPORT_REQUEST_ERROR as string]: exportMedsError,
  [Types.MEDICATION_CONF_GET_REQUEST as string]: getMedication,
  [Types.MEDICATION_CONF_GET_REQUEST_SUCCESS as string]: getMedicationSuccess,
  [Types.MEDICATION_CONF_GET_REQUEST_ERROR as string]: getMedicationError,

  [Types.MEDICATION_CONF_BULK_LIST_REQUEST as string]: listBulkRequest,
  [Types.MEDICATION_CONF_BULK_LIST_REQUEST_SUCCESS as string]: listBulkRequestSuccess,
  [Types.MEDICATION_CONF_BULK_LIST_REQUEST_ERROR as string]: listBulkRequestError,

  [Types.MEDICATION_CONF_BULK_UPLOAD_REQUEST as string]: uploadRequest,
  [Types.MEDICATION_CONF_BULK_UPLOAD_REQUEST_SUCCESS as string]: uploadRequestSuccess,
  [Types.MEDICATION_CONF_BULK_UPLOAD_REQUEST_ERROR as string]: uploadRequestError,

  [Types.MEDICATION_CONF_EXPORT_PROCESS_FILE_REQUEST as string]: exportProcessFile,
  [Types.MEDICATION_CONF_EXPORT_PROCESS_FILE_REQUEST_SUCCESS as string]: exportProcessFileSuccess,
  [Types.MEDICATION_CONF_EXPORT_PROCESS_FILE_REQUEST_ERROR as string]: exportProcessFileError,

  [Types.MEDICATION_CONF_TYPES_REQUEST as string]: typesRequest,
  [Types.MEDICATION_CONF_TYPES_REQUEST_SUCCESS as string]: typesRequestSuccess,
  [Types.MEDICATION_CONF_TYPES_REQUEST_ERROR as string]: typesRequestError,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
