import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/doctors/actions';
import doctorsApi from 'src/services/api/doctors';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* upload({ doctors, eid }: any) {
  try {
    if (eid === 0) {
      toaster.error('Please, select a medical entity.');
      throw new Error();
    }

    const fileParts = doctors.name.split('.');
    const ext = fileParts[fileParts.length - 1];
    if (!['csv', 'xlsx'].includes(ext)) {
      toaster.error('The uploaded file type should be csv or Excel (.xlsx).');
      throw new Error();
    }
    const data = new FormData();
    data.append('doctors', doctors);
    data.append('id', eid);

    const response = yield call(doctorsApi.upload, data);
    yield put(Creators.doctorsUploadRequestSuccess(response.data));
    toaster.success('doctor were added successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'upload', entity: 'doctors' });
    handleErrorResponse(error);
    yield put(Creators.doctorsUploadRequestError(error));
  }
}

export function* watchUpload() {
  yield takeLeading(Types.DOCTORS_UPLOAD_REQUEST, upload);
}

export default watchUpload;
