import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    tagsListRequest: ['filters'],
    tagsListRequestSuccess: ['data'],
    tagsListRequestError: ['error'],

    tagsCreateRequest: ['tag'],
    tagsCreateRequestSuccess: [],
    tagsCreateRequestError: ['error'],

    tagsUpdateRequest: ['id', 'tag', 'onlyUpdattingStatus'],
    tagsUpdateRequestSuccess: [],
    tagsUpdateRequestError: ['error'],

    tagsDeleteRequest: ['id'],
    tagsDeleteRequestSuccess: [],
    tagsDeleteRequestError: ['error'],

    tagsGetRequest: ['id'],
    tagsGetRequestSuccess: ['data'],
    tagsGetRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
