import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    scansListRequest: ['filters'],
    scansListRequestSuccess: ['data'],
    scansListRequestError: ['error'],

    scansCategoriesRequest: [],
    scansCategoriesRequestSuccess: ['data'],
    scansCategoriesRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
