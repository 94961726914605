import { all } from 'redux-saga/effects';
import { watchCreate } from './create';
import { watchExportProcessFile } from './exportProcessFile';
import { watchExport } from './exports';
import { watchGet } from './get';
import { watchBulkList } from './listBulk';
import { watchSearch } from './search';
import watchTypes from './types';
import { watchUpdate } from './update';
import { watchUpload } from './uploadBulk';

export function* watchMedicationsConfig() {
  yield all([
    watchSearch(),
    watchCreate(),
    watchGet(),
    watchUpdate(),
    watchUpload(),
    watchBulkList(),
    watchExport(),
    watchExportProcessFile(),
    watchTypes(),
  ]);
}
