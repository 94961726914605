import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    DISEASE_CODES_LIST_REQUEST
    DISEASE_CODES_LIST_REQUEST_SUCCESS
    DISEASE_CODES_LIST_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
