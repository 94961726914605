import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    doctorsCreateRequest: ['doctor'],
    doctorsCreateRequestSuccess: ['doctor'],
    doctorsCreateRequestError: ['error'],

    doctorsDeleteRequest: ['id'],
    doctorsDeleteRequestSuccess: ['data'],
    doctorsDeleteRequestError: ['error'],

    doctorsUpdateRequest: ['doctor'],
    doctorsUpdateRequestSuccess: ['doctor'],
    doctorsUpdateRequestError: ['error'],

    doctorsListRequest: ['filters'],
    doctorsListRequestSuccess: ['data'],
    doctorsListRequestError: ['error'],

    doctorsUploadRequest: ['doctors', 'eid'],
    doctorsUploadRequestSuccess: ['data'],
    doctorsUploadRequestError: ['error'],

    doctorsGetRequest: ['id'],
    doctorsGetRequestSuccess: ['data'],
    doctorsGetRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
