import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/roles/actions';
import rolesApi from 'src/services/api/roles';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* list(action: any) {
  try {
    const response = yield call(rolesApi.list);
    yield put(Creators.rolesListRequestSuccess(response));
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'list', entity: 'roles' });
    let error = e.response;
    yield put(Creators.rolesListRequestError(error));
  }
}

export function* watchList() {
  yield takeLeading(Types.ROLES_LIST_REQUEST, list);
}

export default watchList;
