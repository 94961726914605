import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';

import history from 'src/services/browser-history';
import toaster from 'src/services/toaster';

export function* deleteClaim(action: any) {
  try {
    const response = yield call(claimsApi.deleteSelectedClaim, action.id);
    yield put(Creators.claimsDeleteRequestSuccess(response.data));
    history.push('/claims/all');
    toaster.success(`Claim number ${action.id} was deleted`);
  } catch (e) {
    let error = e.response;
    yield put(Creators.claimsDeleteRequestError(error));
    toaster.error(`error deleting claim number ${action.id}`);
  }
}

export function* watchDelete() {
  yield debounce(6e2, Types.CLAIMS_DELETE_REQUEST, deleteClaim);
}

export default watchDelete;
