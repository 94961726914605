import { takeLeading, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/SLA/actions';
import slaAPI from 'src/services/api/SLA';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';

export function* updateAgentCapc(action: any) {
  try {
    const { capacity } = action;
    const response = yield call(slaAPI.updateAgent, capacity);
    yield put(Creators.slaConfigUpdateAgentCapcRequestSuccess(response));
    yield put(Creators.slaConfigGetRequest());
    toaster.success('Agent Capacity updated successfully');
  } catch (e) {
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.slaConfigUpdateAgentCapcRequestError(error));
  }
}

export function* watchUpdateAgentCapc() {
  yield takeLeading(
    Types.SLA_CONFIG_UPDATE_AGENT_CAPC_REQUEST,
    updateAgentCapc
  );
}

export default watchUpdateAgentCapc;
