import { useRef, useEffect } from 'react';

const useUnload = (fn: Function) => {
  const cb = useRef(fn);

  useEffect(() => {
    const onUnload: any = cb.current;

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [cb]);
};

export default useUnload;
