import { takeLeading, put,select } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/statistics-me/actions';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';
import ReduxStoreTypes from 'src/store/redux/types';

 

export function* getAllStatistics() {
  try {
    const dateFilter = yield select(
      (store: ReduxStoreTypes) => store.statsME.dateFilter
    );

    const medicalGroupFilter = yield select(
      (store: ReduxStoreTypes) => store.statsME.medicalGroupStats?.map((item:any)=>+item.value)
    );

    const entityFilter = yield select((store: ReduxStoreTypes) => {
      let filterFormated: {
        insuranceCompany: number | null;
        provider: number[]| null;
      } = { insuranceCompany: null, provider: null };
      if (store.statsME.entityFilter) {
        let filterCloned: typeof store.statsME.entityFilter = {
          ...store.statsME.entityFilter,
        };
        filterFormated.insuranceCompany = filterCloned.entityType?.value
          ? filterCloned.entityType?.value
          : null;
        filterFormated.provider =filterCloned.entityName?filterCloned.entityName.map(item=>+item.value):[];
        
        return filterFormated;
      }
      return filterFormated;
    });

    const finalAppliedFilter = {
      ...(dateFilter && dateFilter),
      medicalGroup:medicalGroupFilter?[...medicalGroupFilter]:null,
      ...entityFilter,
    };
    yield put(Creators.statisticsMeDbPerformanceRequest(finalAppliedFilter))
    yield put(Creators.statisticsMeHeatMapRequest(finalAppliedFilter))
    yield put(Creators.statisticsMeProviderPerformanceRequest(finalAppliedFilter))
    yield put(Creators.statisticsMePerscAnalyticsRequest(finalAppliedFilter))
     
  } catch (e) {
    // let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'getAllStatistics',
      entity: 'statistics',
    });
    handleErrorResponse(e);
  }
}

export function* watchGetAllStatistics() {
  yield takeLeading(Types.STATISTICS_ME_GET_STATISTICS_DATA, getAllStatistics);
}

export default watchGetAllStatistics;

