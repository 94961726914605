import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import * as ocrApi from 'src/services/api/ocr';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* parseImage(data: any) {
  try {
    const response = yield call(ocrApi.parseImage, data.data);

    yield put(Creators.claimsParseImageRequestSuccess(response));
  } catch (e) {
    if (e?.response?.status === 403) {
      return;
    }

    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'parseImage',
      entity: 'claims',
    });
    // handleErrorResponse(error);
    yield put(Creators.claimsParseImageRequestError(error));
  }
}

export function* watchParseImage() {
  yield takeLeading(Types.CLAIMS_PARSE_IMAGE_REQUEST, parseImage);
}

export default watchParseImage;
