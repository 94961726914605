import { createReducer } from 'reduxsauce';
import { SMS_CONFIG_URL } from 'src/services/api/urls';
import history from 'src/services/browser-history';
import { SMSreducerType } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: SMSreducerType = {
  list: [],
  claimSms: [],
  claimSmsSection: '',
  created: [],
  item: {},
  tags: [],
  TPA: [],
  insurCompanies: [],
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  isLoading: false,
  smsFetching: false,
  hasError: false,
  isFilteringScenarios: false,
};

export const smsClaimRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    claimSmsSection: action.filters.section,
    smsFetching: true,
  };
};

export const smsClaimRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action;

  return {
    ...state,
    claimSms: data,
    smsFetching: false,
    hasError: false,
  };
};

export const smsClaimRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    smsFetching: false,
    hasError: true,
  };
};

export const createRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const createRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const list = [...state.created] as any;
  const { smsConfig } = action;

  list.unshift(smsConfig);

  return {
    ...state,
    list: [],
    created: list,
    isLoading: false,
    hasError: false,
  };
};

export const createRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const deleteRequest = (state = INITIAL_STATE, action: any) => {
  const id = action.id;
  const list = state.list.filter((d: any) => d.id.toString() !== id.toString());
  return {
    ...state,
    list,
    isLoading: true,
  };
};

export const deleteRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const deleteRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const updateRequest = (state = INITIAL_STATE) => {
  return {
    ...INITIAL_STATE,
    isLoading: true,
    hasError: false,
  };
};

export const updateRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const updateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const listRequest = (state = INITIAL_STATE, action: any) => {
  state.list = [];
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
    isFilteringScenarios: !!(action.filters?.search || action.filters?.tpa),
  };
};

export const listRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data, ...pagination } = action.data;

  return {
    ...state,
    created: [],
    list: data,
    pagination: { ...state.pagination, ...pagination },
    isLoading: false,
    hasError: false,
  };
};

export const listRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const getRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    item: action.data.data,
    isLoading: false,
    hasError: false,
  };
};

export const getRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => {
  if (
    SMS_CONFIG_URL.includes(history.location.pathname.split('/')[1]) &&
    history.location.search !== ''
  ) {
    return {
      ...INITIAL_STATE,
      pagination: state.pagination,
      isLoading: true,
    };
  }
  return state;
};

export const HANDLERS = {
  [Types.SMS_CONFIG_CLAIM_REQUEST as string]: smsClaimRequest,
  [Types.SMS_CONFIG_CLAIM_REQUEST_SUCCESS as string]: smsClaimRequestSuccess,
  [Types.SMS_CONFIG_CLAIM_REQUEST_ERROR as string]: smsClaimRequestError,

  [Types.SMS_CONFIG_CREATE_REQUEST as string]: createRequest,
  [Types.SMS_CONFIG_CREATE_REQUEST_SUCCESS as string]: createRequestSuccess,
  [Types.SMS_CONFIG_CREATE_REQUEST_ERROR as string]: createRequestError,

  [Types.SMS_CONFIG_DELETE_REQUEST as string]: deleteRequest,
  [Types.SMS_CONFIG_DELETE_REQUEST_SUCCESS as string]: deleteRequestSuccess,
  [Types.SMS_CONFIG_DELETE_REQUEST_ERROR as string]: deleteRequestError,

  [Types.SMS_CONFIG_UPDATE_REQUEST as string]: updateRequest,
  [Types.SMS_CONFIG_UPDATE_REQUEST_SUCCESS as string]: updateRequestSuccess,
  [Types.SMS_CONFIG_UPDATE_REQUEST_ERROR as string]: updateRequestError,

  [Types.SMS_CONFIG_LIST_REQUEST as string]: listRequest,
  [Types.SMS_CONFIG_LIST_REQUEST_SUCCESS as string]: listRequestSuccess,
  [Types.SMS_CONFIG_LIST_REQUEST_ERROR as string]: listRequestError,

  [Types.SMS_CONFIG_GET_REQUEST as string]: getRequest,
  [Types.SMS_CONFIG_GET_REQUEST_SUCCESS as string]: getRequestSuccess,
  [Types.SMS_CONFIG_GET_REQUEST_ERROR as string]: getRequestError,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
