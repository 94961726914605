import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/sms-config/actions';
import smsConfigApi from 'src/services/api/sms-config';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';
import history from 'src/services/browser-history';

export function* create({ smsConfig }: any) {
  try {
    const response = yield call(smsConfigApi.create, smsConfig);
    yield put(Creators.smsConfigCreateRequestSuccess(response.data));
    toaster.success('SMS config were added successfully');
    history.push(`/sms-config/all`);
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'create',
      entity: 'sms_config',
    });
    handleErrorResponse(error);
    yield put(Creators.smsConfigCreateRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLeading(Types.SMS_CONFIG_CREATE_REQUEST, create);
}

export default watchCreate;
