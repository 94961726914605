import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    medicationConfSearchListRequest: ['filters'],
    medicationConfSearchListRequestSuccess: ['data'],
    medicationConfSearchListRequestError: ['error'],
    medicationConfCreateRequest: ['data'],
    medicationConfCreateRequestSuccess: ['data'],
    medicationConfCreateRequestError: ['error'],
    medicationConfUpdateRequest: ['data', 'id'],
    medicationConfUpdateRequestSuccess: ['data'],
    medicationConfUpdateRequestError: ['error'],
    medicationConfExportRequest: ['params'],
    medicationConfExportRequestSuccess: ['data'],
    medicationConfExportRequestError: ['error'],
    medicationConfGetRequest: ['id'],
    medicationConfGetRequestSuccess: ['data'],
    medicationConfGetRequestError: ['error'],
    medicationConfBulkUploadRequest: ['data'],
    medicationConfBulkUploadRequestSuccess: ['data'],
    medicationConfBulkUploadRequestError: ['error'],
    medicationConfBulkListRequest: [],
    medicationConfBulkListRequestSuccess: ['data'],
    medicationConfBulkListRequestError: ['error'],
    medicationConfExportProcessFileRequest: ['filter'],
    medicationConfExportProcessFileRequestSuccess: ['data'],
    medicationConfExportProcessFileRequestError: ['error'],

    medicationConfTypesRequest: [],
    medicationConfTypesRequestSuccess: ['data'],
    medicationConfTypesRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
