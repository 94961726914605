import { createReducer } from 'reduxsauce';
import { CLAIMS_URL } from 'src/services/api/urls';
import history from 'src/services/browser-history';
import { Claim } from 'src/types-dictionary';
import { Ocr } from 'src/types-dictionary/claims/claim-ocr.type';
import { ClaimReducerType } from '../types';
import Types from './actions-types';

export const INITIAL_STATE: ClaimReducerType = {
  list: [],
  item: {},
  report: [],
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  newClaim: [],
  imageData: {},
  isListLoading: false,
  isLoading: false,
  isTrackingSLALoading: false,
  isImageLoading: false,
  eligibilityCheckLoading: false,
  hasError: false,
  currentSectionsTiming: null,
  areSectionsTimerLoading: false,
  isAttachmentLoading: false,
  commentAttachment: null,
  history: [],
};

export const claimsReportRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isReportLoading: true,
  };
};

export const claimsReportRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    report: action.data,
    isReportLoading: false,
    hasError: false,
    isLoading: false,
  };
};

export const claimsReportRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isReportLoading: false,
    hasError: true,
  };
};

export const claimsExportRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
    ...action,
  };
};

export const claimsExportRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    export: action.data,
    isLoading: false,
    hasError: false,
  };
};

export const claimsExportRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const claimsCreateRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const claimsCreateRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const claimsCreateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const claimsListRequest = (state = INITIAL_STATE, action: any) => {
  state.list = [];
  if (action.filters.reset) {
    const defaultConf = {
      count: 30,
      page: 1,
    };
    action.filters = defaultConf;
    state.pagination = defaultConf;
  }
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isListLoading: true,
  };
};

export const claimsListRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  let { data: fetchedList, newClaims, ...pagination } = action.data;
  if (state.pagination?.filter?.includes('createdDate||gt')) {
    state.pagination.filter = '';
  }

  return {
    ...state,
    list: fetchedList,
    newClaim: newClaims || [],
    pagination: { ...state.pagination, ...pagination },
    isListLoading: false,
    hasError: false,
  };
};

export const claimsListRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isListLoading: false,
    hasError: true,
  };
};

export const claimsTrackRequest = (state = INITIAL_STATE, action: any) => {
  state.list = [];
  return {
    ...state,
    areSectionsTimerLoading: true,
  };
};

export const claimsTrackRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    currentSectionsTiming: action?.data || null,
    hasError: false,
    areSectionsTimerLoading: false,
    isTrackingSLALoading: false,
  };
};

export const claimsTrackRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    hasError: true,
    areSectionsTimerLoading: false,
  };
};

export const claimsAdjustRequest = (state = INITIAL_STATE, action: any) => {
  let claimIds: any[] = [];
  const newClaim = state.newClaim
    .filter((claim: any) => {
      if (claimIds.includes(claim.id)) {
        return false;
      } else {
        claimIds.push(claim.id);
        return true;
      }
    })
    .map((claim: any) => {
      claim.new = true;
      return claim;
    });

  const restOfClaims = state.list.map((claim: any) => {
    delete claim.new;
    return claim;
  });

  const slice = newClaim.length > 30 ? 0 : 30 - newClaim.length;
  return {
    ...state,
    newClaim: [],
    list: [...newClaim, ...restOfClaims.slice(0, slice)],
    isLoading: false,
  };
};

export const claimsListenerRequest = (state = INITIAL_STATE, action: any) => {
  state.list = [];
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const claimsListenerRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  state.newClaim = state.newClaim.filter((claim: any) => {
    return action.data.id !== claim.id;
  });

  return {
    ...state,
    newClaim: [action.data, ...state.newClaim],
    isLoading: false,
    hasError: false,
  };
};

export const claimsListenerRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const claimsUpdateRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const claimsUpdateRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const claim = action.data as any;
  if (!claim.doctor) {
    claim.doctor = {};
  }

  return {
    ...state,
    item: {
      ...state.item,
      ...claim,
    },
    isLoading: false,
    hasError: false,
  };
};

export const claimsUpdateRequestError = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    item: { ...state.item, ...action.data },
    isLoading: false,
    hasError: true,
  };
};

export const claimsDeleteRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const claimsDeleteRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const list = state.list.filter(({ id }: any) => id !== action.id);

  return {
    ...state,
    list,
    isLoading: false,
    hasError: false,
  };
};

export const claimsDeleteRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const claimsGetRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    item: {},
    isLoading: true,
  };
};

export const claimsGetRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const claim = action.claim as any;
  let filterdOcr: Ocr = {};
  if (Array.isArray(claim.ocr) && claim.ocr.length) {
    let ocrMap = new Map();
    /* Filter ocr by remove redundant value coming from it  */
    let classifyOCR = new Map();
    claim.ocr.forEach((item: any) => {
      if (!ocrMap.get(item.DetectedText)) {
        ocrMap.set(item.DetectedText, item.Id);
      }
    });
    /* start classify ocr value  */
    let digits: string[] = [];
    ocrMap.forEach((_, keys: string) => {
      if (!!keys.match(/^[a-zA-Z]*$/g)) {
        classifyOCR.set('insuranceCompany', keys);
      } else if (!!keys.match(/\d+/g)) {
        // add numbers coming from ocr to array of digits
        digits.push(keys);
        classifyOCR.set('digits', digits);
      }
    });

    filterdOcr = {
      insuranceCompany: classifyOCR.has('insuranceCompany')
        ? classifyOCR.get('insuranceCompany')
        : null,
      patientId:
        classifyOCR.has('digits') && classifyOCR.get('digits').length
          ? classifyOCR.get('digits')[0]
          : null,
      claimFormId:
        classifyOCR.has('digits') && classifyOCR.get('digits').length
          ? classifyOCR.get('digits')[1]
          : null,
    };
    // check if insurance company is [axa] then swap two digit values coming from ocr[patient Id,claimform Id]
    if (filterdOcr['insuranceCompany']?.toLowerCase().includes('axa')) {
      let tmp = filterdOcr['patientId'];
      filterdOcr['patientId'] = filterdOcr['claimFormId'];
      filterdOcr['claimFormId'] = tmp;
    }
  }
  state.imageData = {};
  if (!claim.doctor) {
    claim.doctor = {};
  }
  return {
    ...state,
    item: { ...action.claim, ocr: filterdOcr },
    isLoading: false,
    hasError: false,
  };
};

export const claimsGetRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const claimsGetImageRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isImageLoading: true,
  };
};

export const claimsGetImageRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    item: {
      ...state.item,
      image:
        action.image.entity === 'claim'
          ? action.image.image
          : (state.item as any)?.image,
      medicalEntity: {
        ...(state.item as any)?.medicalEntity,
        image:
          action.image.entity === 'medicalEntity'
            ? action.image.image
            : (state.item as any)?.image,
      },
    },
    isImageLoading: false,
    hasError: false,
  };
};

export const claimsGetImageRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isImageLoading: false,
    hasError: true,
  };
};

export const claimsGetAttachmentRequest = (
  state = INITIAL_STATE,
  action: any
) => {
  return { ...state, hasError: false, isAttachmentLoading: true };
};

export const claimsGetAttachmentRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    hasError: false,
    isAttachmentLoading: false,
    commentAttachment: action.image,
  };
};

export const claimsGetAttachmentRequestError = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    hasError: true,
    isAttachmentLoading: false,
    commentAttachment: null,
  };
};

export const claimsCountRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const claimsCountRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    count: action.count.total,
    isLoading: false,
    hasError: false,
  };
};

export const claimsCountRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const claimsParseImageRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
  };
};

export const claimsParseImageRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const { data } = action.data;

  return {
    ...state,
    imageData: !data?.error ? data?.Lines : {},
    isLoading: false,
    hasError: false,
  };
};

export const claimsParseImageRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const claimAssignSelfRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    hasError: false,
  };
};
export const claimAssignSelfRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const newAssignedClaim = action.claim;

  const updatedClaims: { list: any } = { ...state };

  const updatedRecordIndex = updatedClaims.list.findIndex(
    (claim: any) => claim.id === newAssignedClaim.id
  );

  updatedClaims.list[updatedRecordIndex] = newAssignedClaim as {};

  return {
    ...state,
    list: updatedClaims.list,
    item: { ...state.item, assignedAgent: newAssignedClaim.assignedAgent },
    isLoading: false,
    hasError: false,
  };
};

export const claimAssignSelfRequestError = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    hasError: true,
  };
};

export const claimEligibility = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    hasError: false,
    eligibilityCheckLoading: true,
  };
};

export const claimEligibilitySuccess = (state = INITIAL_STATE, action: any) => {
  const newState = { ...state, eligibilityCheckLoading: false };

  delete newState.sequenceNotEligibleReason;
  delete newState.idNotEligibleReason;

  if (!action.data.isEligible) {
    newState.idNotEligibleReason =
      action.data.notEligibleReason || 'the ID provided is not valid';
  }

  if (
    !action.data.isSequenceValid &&
    (action.data.isSequenceValid !== null ||
      action.data.isSequenceValid !== undefined)
  ) {
    newState.sequenceNotEligibleReason =
      action.data.notValidReason || 'the sequence number provided is not valid';
  }

  return newState;
};

export const claimEligibilityError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    hasError: true,
    eligibilityCheckLoading: false,
  };
};

export const cancelClaimRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const cancelClaimRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    hasError: false,
    isLoading: false,
  };
};

export const cancelClaimRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const assignClaimRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const assignClaimRequestSuccess = (
  state = INITIAL_STATE,
  payload: any
) => {
  return {
    ...state,
    hasError: false,
    isLoading: false,
    item: payload.claim,
  };
};

export const assignClaimRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const historyClaimRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const historyClaimRequestSuccess = (
  state = INITIAL_STATE,
  payload: any
) => {
  return {
    ...state,
    hasError: false,
    isLoading: false,
    history: payload.data,
  };
};

export const historyClaimRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => {
  if (
    CLAIMS_URL.includes(history.location.pathname.split('/')[1]) &&
    history.location.search !== ''
  ) {
    return { ...INITIAL_STATE, pagination: state.pagination };
  }
  return INITIAL_STATE;
};

// export const clearStore = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.CLAIMS_REPORT_REQUEST as string]: claimsReportRequest,
  [Types.CLAIMS_REPORT_REQUEST_SUCCESS as string]: claimsReportRequestSuccess,
  [Types.CLAIMS_REPORT_REQUEST_ERROR as string]: claimsReportRequestError,

  [Types.CLAIMS_EXPORT_REQUEST as string]: claimsExportRequest,
  [Types.CLAIMS_EXPORT_REQUEST_SUCCESS as string]: claimsExportRequestSuccess,
  [Types.CLAIMS_EXPORT_REQUEST_ERROR as string]: claimsExportRequestError,

  [Types.CLAIMS_CREATE_REQUEST as string]: claimsCreateRequest,
  [Types.CLAIMS_CREATE_REQUEST_SUCCESS as string]: claimsCreateRequestSuccess,
  [Types.CLAIMS_CREATE_REQUEST_ERROR as string]: claimsCreateRequestError,

  [Types.CLAIMS_LIST_REQUEST as string]: claimsListRequest,
  [Types.CLAIMS_LIST_REQUEST_SUCCESS as string]: claimsListRequestSuccess,
  [Types.CLAIMS_LIST_REQUEST_ERROR as string]: claimsListRequestError,

  [Types.CLAIMS_TRACK_REQUEST as string]: claimsTrackRequest,
  [Types.CLAIMS_TRACK_REQUEST_SUCCESS as string]: claimsTrackRequestSuccess,
  [Types.CLAIMS_TRACK_REQUEST_ERROR as string]: claimsTrackRequestError,

  [Types.CLAIMS_ADJUST_REQUEST as string]: claimsAdjustRequest,

  [Types.CLAIMS_LISTENER_REQUEST as string]: claimsListenerRequest,
  [Types.CLAIMS_LISTENER_REQUEST_SUCCESS as string]: claimsListenerRequestSuccess,
  [Types.CLAIMS_LISTENER_REQUEST_ERROR as string]: claimsListenerRequestError,

  [Types.CLAIMS_UPDATE_REQUEST as string]: claimsUpdateRequest,
  [Types.CLAIMS_UPDATE_REQUEST_SUCCESS as string]: claimsUpdateRequestSuccess,
  [Types.CLAIMS_UPDATE_REQUEST_ERROR as string]: claimsUpdateRequestError,

  [Types.CLAIMS_DELETE_REQUEST as string]: claimsDeleteRequest,
  [Types.CLAIMS_DELETE_REQUEST_SUCCESS as string]: claimsDeleteRequestSuccess,
  [Types.CLAIMS_DELETE_REQUEST_ERROR as string]: claimsDeleteRequestError,

  [Types.CLAIMS_GET_REQUEST as string]: claimsGetRequest,
  [Types.CLAIMS_GET_REQUEST_SUCCESS as string]: claimsGetRequestSuccess,
  [Types.CLAIMS_GET_REQUEST_ERROR as string]: claimsGetRequestError,

  [Types.CLAIMS_GET_IMAGE_REQUEST as string]: claimsGetImageRequest,
  [Types.CLAIMS_GET_IMAGE_REQUEST_SUCCESS as string]: claimsGetImageRequestSuccess,
  [Types.CLAIMS_GET_IMAGE_REQUEST_ERROR as string]: claimsGetImageRequestError,

  [Types.CLAIMS_GET_ATTACHMENT_REQUEST as string]: claimsGetAttachmentRequest,
  [Types.CLAIMS_GET_ATTACHMENT_REQUEST_SUCCESS as string]: claimsGetAttachmentRequestSuccess,
  [Types.CLAIMS_GET_ATTACHMENT_REQUEST_ERROR as string]: claimsGetAttachmentRequestError,

  [Types.CLAIMS_COUNT_REQUEST as string]: claimsCountRequest,
  [Types.CLAIMS_COUNT_REQUEST_SUCCESS as string]: claimsCountRequestSuccess,
  [Types.CLAIMS_COUNT_REQUEST_ERROR as string]: claimsCountRequestError,

  [Types.CLAIMS_PARSE_IMAGE_REQUEST as string]: claimsParseImageRequest,
  [Types.CLAIMS_PARSE_IMAGE_REQUEST_SUCCESS as string]: claimsParseImageRequestSuccess,
  [Types.CLAIMS_PARSE_IMAGE_REQUEST_ERROR as string]: claimsParseImageRequestError,

  [Types.CLAIMS_ASSIGN_SELF_REQUEST as string]: claimAssignSelfRequest,
  [Types.CLAIMS_ASSIGN_SELF_REQUEST_SUCCESS as string]: claimAssignSelfRequestSuccess,
  [Types.CLAIMS_ASSIGN_SELF_REQUEST_ERROR as string]: claimAssignSelfRequestError,

  [Types.CLAIMS_CHECK_ELIGIBILITY_REQUEST as string]: claimEligibility,
  [Types.CLAIMS_CHECK_ELIGIBILITY_REQUEST_SUCCESS as string]: claimEligibilitySuccess,
  [Types.CLAIMS_CHECK_ELIGIBILITY_REQUEST_ERROR as string]: claimEligibilityError,

  [Types.CLAIMS_CANCEL_REQUEST as string]: cancelClaimRequest,
  [Types.CLAIMS_CANCEL_REQUEST_SUCCESS as string]: cancelClaimRequestSuccess,
  [Types.CLAIMS_CANCEL_REQUEST_ERROR as string]: cancelClaimRequestError,

  [Types.CLAIMS_ASSIGN_REQUEST as string]: assignClaimRequest,
  [Types.CLAIMS_ASSIGN_REQUEST_SUCCESS as string]: assignClaimRequestSuccess,
  [Types.CLAIMS_ASSIGN_REQUEST_ERROR as string]: assignClaimRequestError,

  [Types.CLAIMS_HISTORY_REQUEST as string]: historyClaimRequest,
  [Types.CLAIMS_HISTORY_REQUEST_SUCCESS as string]: historyClaimRequestSuccess,
  [Types.CLAIMS_HISTORY_REQUEST_ERROR as string]: historyClaimRequestError,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
