import { createTypes } from 'reduxsauce';

export default createTypes(
  `

    MEDICATION_CONF_GET_REQUEST
    MEDICATION_CONF_GET_REQUEST_SUCCESS
    MEDICATION_CONF_GET_REQUEST_ERROR

    MEDICATION_CONF_SEARCH_LIST_REQUEST
    MEDICATION_CONF_SEARCH_LIST_REQUEST_SUCCESS
    MEDICATION_CONF_SEARCH_LIST_REQUEST_ERROR

    MEDICATION_CONF_CREATE_REQUEST
    MEDICATION_CONF_CREATE_REQUEST_SUCCESS
    MEDICATION_CONF_CREATE_REQUEST_ERROR

    MEDICATION_CONF_UPDATE_REQUEST
    MEDICATION_CONF_UPDATE_REQUEST_SUCCESS
    MEDICATION_CONF_UPDATE_REQUEST_ERROR

    MEDICATION_CONF_EXPORT_REQUEST
    MEDICATION_CONF_EXPORT_REQUEST_SUCCESS
    MEDICATION_CONF_EXPORT_REQUEST_ERROR


    MEDICATION_CONF_EXPORT_PROCESS_FILE_REQUEST
    MEDICATION_CONF_EXPORT_PROCESS_FILE_REQUEST_SUCCESS
    MEDICATION_CONF_EXPORT_PROCESS_FILE_REQUEST_ERROR

    MEDICATION_CONF_BULK_UPLOAD_REQUEST
    MEDICATION_CONF_BULK_UPLOAD_REQUEST_SUCCESS
    MEDICATION_CONF_BULK_UPLOAD_REQUEST_ERROR


    MEDICATION_CONF_BULK_LIST_REQUEST
    MEDICATION_CONF_BULK_LIST_REQUEST_SUCCESS
    MEDICATION_CONF_BULK_LIST_REQUEST_ERROR

    MEDICATION_CONF_TYPES_REQUEST
    MEDICATION_CONF_TYPES_REQUEST_SUCCESS
    MEDICATION_CONF_TYPES_REQUEST_ERROR

    CLEAR_STORE
    
  `,
  {
    prefix: '',
  }
);
