import { all } from 'redux-saga/effects';
import watchGetAllStatistics from './getAllStatisticsData';
import watchGetStatsMedicalEnt from './getStatsMedicalEntTyped';
import watchGetStatsMedicalEntities from './getStatsMedicalEntities';
import watchGetDBPerformance from './getDBPerformance';
import watchGetHeatMap from './getHeatMap';
import watchGetProviderPerformance from './getProviderPerformance';
import watchGetPerscAnalytics from './getPerscAnalytics';


export function* watchMEStats() {
  yield all([ 
    watchGetStatsMedicalEnt(),
    watchGetStatsMedicalEntities(),
    watchGetAllStatistics(), 
    watchGetDBPerformance(),
    watchGetHeatMap(),
    watchGetProviderPerformance(),
    watchGetPerscAnalytics()

  ]);
}
