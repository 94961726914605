import { createTypes } from 'reduxsauce';

export default createTypes(
  `


    STATISTICS_ME_PERSC_ANALYTICS_REQUEST
    STATISTICS_ME_PERSC_ANALYTICS_REQUEST_SUCCESS
    STATISTICS_ME_PERSC_ANALYTICS_REQUEST_ERROR 


    STATISTICS_ME_PROVIDER_PERFORMANCE_REQUEST
    STATISTICS_ME_PROVIDER_PERFORMANCE_REQUEST_SUCCESS
    STATISTICS_ME_PROVIDER_PERFORMANCE_REQUEST_ERROR


    STATISTICS_ME_HEAT_MAP_REQUEST
    STATISTICS_ME_HEAT_MAP_REQUEST_SUCCESS
    STATISTICS_ME_HEAT_MAP_REQUEST_ERROR 

    STATISTICS_ME_DB_PERFORMANCE_REQUEST
    STATISTICS_ME_DB_PERFORMANCE_REQUEST_SUCCESS
    STATISTICS_ME_DB_PERFORMANCE_REQUEST_ERROR

  
    STATISTICS_ME_MEDICAL_ENTITY_REQUEST
    STATISTICS_ME_MEDICAL_ENTITY_REQUEST_SUCCESS
    STATISTICS_ME_MEDICAL_ENTITY_REQUEST_ERROR

    STATISTICS_ME_MEDICAL_ENTITY_TYPED_REQUEST
    STATISTICS_ME_MEDICAL_ENTITY_TYPED_REQUEST_SUCCESS
    STATISTICS_ME_MEDICAL_ENTITY_TYPED_REQUEST_ERROR

    STATISTICS_ME_UPDATE_DATE_FILTERS
    STATISTICS_ME_UPDATE_ENTITY_FILTERS
    STATISTICS_ME_UPDATE_MEDICAL_GROUPS_FILTER
    
    STATISTICS_ME_GET_STATISTICS_DATA

    STATISTICS_ME_CLEAR_DATE_FILTERS
    STATISTICS_ME_CLEAR_ENTITY_FILTERS
    STATISTICS_ME_CLEAR_MEDICAL_GROUPS_FILTER


    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
