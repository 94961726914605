import { apiExport, apiRequest } from '../index';
import { LABS_URL, SCANS_URL, MEDIA_SERVICE_URL } from '../urls';
import { Filters } from '../../../../src/props/filters.props';

export const list = (type: string, filters: any) => {
  switch (type) {
    case 'labs':
      return apiRequest('GET', `${LABS_URL}/query-labs`, null, filters);
      break;
    case 'scans':
      return apiRequest('GET', `${SCANS_URL}/query-scans`, null, filters);
      break;
    default:
      return apiRequest('GET', `${LABS_URL}/query-labs`, null, filters);
  }
};

export const search = (type: string, filters: Filters, search: any) => {
  switch (type) {
    case 'labs':
      return apiRequest('POST', `${LABS_URL}/search-lab`, search, filters);
      break;
    case 'scans':
      return apiRequest('POST', `${SCANS_URL}/search-scan`, search, filters);
      break;
    default:
      return apiRequest('POST', `${LABS_URL}/search-lab`, search, filters);
  }
};

export const create = ({ type, ...restCPTData }: any) => {
  switch (type) {
    case 'labs':
      return apiRequest(
        'POST',
        `${LABS_URL}`,
        { ...restCPTData, active: true },
        null
      );
      break;
    case 'scans':
      return apiRequest(
        'POST',
        `${SCANS_URL}`,
        { ...restCPTData, active: true },
        null
      );
      break;
    default:
      return apiRequest(
        'POST',
        `${LABS_URL}`,
        { ...restCPTData, active: true },
        null
      );
  }
};

export const get = (id: string, type: string) => {
  switch (type) {
    case 'labs':
      return apiRequest('GET', `${LABS_URL}/${id}`, null, null);
      break;
    case 'scans':
      return apiRequest('GET', `${SCANS_URL}/${id}`, null, null);
      break;
    default:
  }

  return apiRequest('GET', `${LABS_URL}/${id}`, null, null);
};

export const update = ({ id, type, ...rest }: any) => {
  switch (type) {
    case 'labs':
      return apiRequest('PATCH', `${LABS_URL}/${id}`, rest, null);
      break;
    case 'scans':
      return apiRequest('PATCH', `${SCANS_URL}/${id}`, rest, null);
      break;
    default:
      return apiRequest('PATCH', `${LABS_URL}/${id}`, rest, null);
  }
};

export const upload = (data: FormData) => {
  return apiRequest('POST', `${MEDIA_SERVICE_URL}/upload`, data, null);
};

export const exportReport = (filters: Filters, type: string) => {
  switch (type) {
    case 'labs':
      return apiExport(`${LABS_URL}/export`, filters);
      break;
    case 'scans':
      return apiExport(`${SCANS_URL}/export`, filters);
      break;
    default:
      return apiExport(`${LABS_URL}/export`, filters);
  }
};

export default {
  list,
  update,
  upload,
  get,
  create,
  search,
  exportReport,
};
