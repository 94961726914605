import { toast } from 'react-toastify';

export const toastError = (e: any) => {
  console.log(e);
  try {
    toast.error(e.response.data.message);
  } catch (_) {
    toast.error(e.message);
  }
};

export default toast;
