export enum ClaimIntegrationStatus {
  CREATED = 'Created',
  NOT_CREATED = 'Not Created',
  UPDATED = 'Updated',
  NOT_UPDATED = 'Not Updated',
  ALREADY_DISPENSED = 'Already Dispensed',
  ALREADY_CREATED = 'Already Created',
  CANCELLED = 'Cancelled',
  NOT_CANCELLED = 'Not Cancelled',
  SERVER_ERROR = 'Server Error',
}
