import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    labsListRequest: ['filters'],
    labsListRequestSuccess: ['data'],
    labsListRequestError: ['error'],

    labsCategoriesRequest: [],
    labsCategoriesRequestSuccess: ['data'],
    labsCategoriesRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
