import { all } from 'redux-saga/effects';
import watchGetAllStatistics from './getAllStatisticsData';
import watchGetStatsMedicalEnt from './getStatsMedicalEntTyped';
import watchGetStatsMedicalEntities from './getStatsMedicalEntities';
import watchGetTicketOverview from './getTicketoverview';
import watchGetSla from './getSla'
import watchGetFrequency from './getFrquency'

export function* watchTPAStats() {
  yield all([ 
    watchGetStatsMedicalEnt(),
    watchGetStatsMedicalEntities(),
    watchGetAllStatistics(), 
    watchGetTicketOverview(),
    watchGetSla(),
    watchGetFrequency()

  ]);
}
