import { all } from 'redux-saga/effects';
import { watchAgents } from './agents';
import { watchAuth } from './auth';
import { watchMedicalEntities } from './medical-entities';
import { watchMedicalGroups } from './medical-groups';
import { watchMedicalSpecialties } from './medical-specialties';
import { watchMedicalDegrees } from './medical-degrees';
import { watchDoctors } from './doctors';
import { watchInsuranceCompanies } from './insurance-companies';
import { watchFieldRepresentatives } from './field-representatives';
import { watchClaims } from './claims';
import { watchDiseaseCodes } from './disease-codes';
import { watchNotifications } from './notifications';
import { watchRoles } from './roles';
import { watchMedications } from './medications';
import { watchTags } from './tags';
import { watchComments } from './comments';
import { watchSMSConfig } from './sms-config';
import { watchLabs } from './labs';
import { watchScans } from './scans';
import { watchSLA } from './SLA';
import { watchMedicationsConfig } from './medication-config';
import { watchTPAStats } from './statistics-tpa';
import { watchCPT } from './cpt';
import { watchIt } from './Integration';
import { watchMEStats } from './statistics-me';

export default function* rootSaga() {
  yield all([
    watchAgents(),
    watchAuth(),
    watchMedicalEntities(),
    watchMedicalGroups(),
    watchMedicalSpecialties(),
    watchMedicalDegrees(),
    watchDoctors(),
    watchInsuranceCompanies(),
    watchFieldRepresentatives(),
    watchClaims(),
    watchDiseaseCodes(),
    watchNotifications(),
    watchRoles(),
    watchMedications(),
    watchTags(),
    watchComments(),
    watchSMSConfig(),
    watchLabs(),
    watchScans(),
    watchSLA(),
    watchMedicationsConfig(),
    watchTPAStats(),
    watchMEStats(),
    watchCPT(),
    watchIt(),
  ]);
}
