import { all } from 'redux-saga/effects';
import { watchList } from './list';
import { watchCreate } from './create';
import { watchDelete } from './delete';
import { watchUpdate } from './update';
import { watchUpload } from './upload';
import { watchGet } from './get';

export function* watchDoctors() {
  yield all([
    watchList(),
    watchCreate(),
    watchDelete(),
    watchUpdate(),
    watchUpload(),
    watchGet(),
  ]);
}
