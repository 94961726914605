import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    SMS_CONFIG_CLAIM_REQUEST
    SMS_CONFIG_CLAIM_REQUEST_SUCCESS
    SMS_CONFIG_CLAIM_REQUEST_ERROR

    SMS_CONFIG_CREATE_REQUEST
    SMS_CONFIG_CREATE_REQUEST_SUCCESS
    SMS_CONFIG_CREATE_REQUEST_ERROR

    SMS_CONFIG_DELETE_REQUEST
    SMS_CONFIG_DELETE_REQUEST_SUCCESS
    SMS_CONFIG_DELETE_REQUEST_ERROR

    SMS_CONFIG_UPDATE_REQUEST
    SMS_CONFIG_UPDATE_REQUEST_SUCCESS
    SMS_CONFIG_UPDATE_REQUEST_ERROR

    SMS_CONFIG_LIST_REQUEST
    SMS_CONFIG_LIST_REQUEST_SUCCESS
    SMS_CONFIG_LIST_REQUEST_ERROR
    
    SMS_CONFIG_GET_REQUEST
    SMS_CONFIG_GET_REQUEST_SUCCESS
    SMS_CONFIG_GET_REQUEST_ERROR


    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
