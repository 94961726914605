import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    ROLES_LIST_PERM_REQUEST
    ROLES_LIST_PERM_REQUEST_SUCCESS
    ROLES_LIST_PERM_REQUEST_ERROR

    ROLES_LIST_REQUEST
    ROLES_LIST_REQUEST_SUCCESS
    ROLES_LIST_REQUEST_ERROR

    ROLES_GET_REQUEST
    ROLES_GET_REQUEST_SUCCESS
    ROLES_GET_REQUEST_ERROR

    ROLES_GET_CURR_REQUEST
    ROLES_GET_CURR_REQUEST_SUCCESS
    ROLES_GET_CURR_REQUEST_ERROR

    ROLES_UPDATE_REQUEST
    ROLES_UPDATE_REQUEST_SUCCESS
    ROLES_UPDATE_REQUEST_ERROR

    ROLES_DELETE_REQUEST
    ROLES_DELETE_REQUEST_SUCCESS
    ROLES_DELETE_REQUEST_ERROR

    ROLES_ADD_REQUEST
    ROLES_ADD_REQUEST_SUCCESS
    ROLES_ADD_REQUEST_ERROR
  `,
  {
    prefix: '',
  }
);
