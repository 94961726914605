import React, { useCallback } from 'react';
import { NavDropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as ClaimsIcon } from 'src/assets/icons/claims-icon.svg';
import { ReactComponent as ReplaceIcon } from 'src/assets/icons/replace.svg';
import { ReactComponent as ResetIcon } from 'src/assets/icons/lock.svg';
import dayjs from 'dayjs';
import { Creators as NotificationsCreators } from 'src/store/redux/notifications/actions';

import { ROUTES } from 'src/constants';
import './style.css';
import styles from './top-navbar.module.scss';
import { FaCheck } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Notification as NotificationType } from 'src/types-dictionary';

const scopes: any = {
  claims: ROUTES.CLAIMS.EDIT,
  users: ROUTES.FieldRep.EDIT,
};
const Notification: React.FC<{ notification: NotificationType }> = (props) => {
  const { notification } = props;
  const dispatch = useDispatch();

  const markAsRead = useCallback(
    (id) => {
      dispatch(
        NotificationsCreators.notificationsReadRequest({ read: true, id })
      );
    },
    [dispatch]
  );
  return (
    <NavDropdown.Item
      as={Link}
      to={(scopes[notification.itemScope] as string).replace(
        ':id',
        notification.itemId
      )}
      onClick={(e) => markAsRead(notification.id)}>
      <Row>
        <Col md={2}>
          {notification.itemScope === 'claims' &&
            notification.status === null && <ClaimsIcon />}
          {notification.itemScope === 'claims' &&
            notification.status !== null && <ReplaceIcon />}
          {notification.itemScope === 'users' && <ResetIcon />}
        </Col>
        <Col md={7}>
          <p>{notification.body}</p>
        </Col>
        <Col md={3}>
          <p className={styles.timeLabel}>
            {dayjs(notification.createdDate).format('HH:mm A')}
          </p>
          {notification.read && (
            <span className={styles.timeLabel}>
              <FaCheck />
            </span>
          )}
        </Col>
      </Row>
    </NavDropdown.Item>
  );
};

export default Notification;
