import { all } from 'redux-saga/effects';
import { watchCreate } from './create';
import { watchList } from './list';
import { watchUpdate } from './update';
import { watchGet } from './get';
import { watchDelete } from './delete';
import { watchGenerateMFA } from './generateMFA';
import watchGetImage from './getImage';
import watchAgentStatus from './status';

export function* watchAgents() {
  yield all([
    watchCreate(),
    watchList(),
    watchUpdate(),
    watchGet(),
    watchDelete(),
    watchGenerateMFA(),
    watchGetImage(),
    watchAgentStatus(),
  ]);
}
