import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/roles/actions';
import rolesApi from 'src/services/api/roles';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* listPerm(action: any) {
  try {
    const response = yield call(rolesApi.listPerm);
    yield put(Creators.rolesListPermRequestSuccess(response));
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'listPerm', entity: 'roles' });
    let error = e.response;
    yield put(Creators.rolesListPermRequestError(error));
  }
}

export function* watchListPrem() {
  yield takeLeading(Types.ROLES_LIST_PERM_REQUEST, listPerm);
}

export default watchListPrem;
