import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/field-representatives/actions';
import fieldRepApi from 'src/services/api/field-representatives';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get({ id }: any) {
  try {
    const response = yield call(fieldRepApi.get, id);
    yield put(Creators.fieldRepresentativesGetRequestSuccess(response));
  } catch (e) {
    //TODO add error handling in a function
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'fieldRep' });
    handleErrorResponse(error);
    yield put(Creators.fieldRepresentativesGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLeading(Types.FIELD_REPRESENTATIVES_GET_REQUEST, get);
}

export default watchGet;
