import { takeLeading, put, select } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/auth/actions';
import toaster from 'src/services/toaster';
import { Auth } from 'aws-amplify';
import history from 'src/services/browser-history';
import { ROUTES } from 'src/constants';

export function* newPassword(action: any) {
  try {
    const user = yield select(({ auth }) => auth.user);

    user.attributes = {
      given_name: 'Predictor',
      family_name: 'Auto',
    };
    yield Auth.completeNewPassword(user, action.user.password, null);
    yield put(Creators.completeNewPasswordRequestSuccess());
    toaster.success('Please, login again');
    history.push(ROUTES.AUTH.LOGIN);
  } catch (e) {
    toaster.error(e.message || e);
    yield put(Creators.completeNewPasswordRequestError(e));
  }
}

export function* watchNewPassword() {
  yield takeLeading(Types.COMPLETE_NEW_PASSWORD_REQUEST, newPassword);
}

export default watchNewPassword;
