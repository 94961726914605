import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/agents/actions';
import agentsApi from 'src/services/api/agents';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* generateMFA({ id }: any) {
  try {
    const currentUser = yield call(agentsApi.get, 0);
    if (currentUser.data.role !== 'Admin') {
      throw new Error(
        'Unauthorized. Please, ask your administrator to generate your key.'
      );
    }
    const response = yield call(agentsApi.generateMFA, id);
    toaster.success('Authentication QR code has been sent to the user email.');
    yield put(Creators.agentsMFARequestSuccess(response));
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'mfa', entity: 'agents' });
    toaster.error(e.message);
    let error = e.response;
    yield put(Creators.agentsMFARequestError(error));
  }
}

export function* watchGenerateMFA() {
  yield takeLeading(Types.AGENTS_MFA_REQUEST, generateMFA);
}

export default watchGenerateMFA;
