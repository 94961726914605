import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    COMMENTS_CREATE_REQUEST
    COMMENTS_CREATE_REQUEST_SUCCESS
    COMMENTS_CREATE_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
