import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    medicalDegreesListRequest: ['filters'],
    medicalDegreesListRequestSuccess: ['data'],
    medicalDegreesListRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
