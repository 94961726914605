import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medical-entities/actions';
import medicalEntitiesApi from 'src/services/api/medical-entities';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* deleteEntity({ id }: any) {
  try {
    yield call(medicalEntitiesApi.delete, id);
    yield put(Creators.medicalEntitiesDeleteRequestSuccess(id));
    toaster.success('entity were deleted successfully');
    yield put(Creators.medicalEntitiesListRequest({}));
  } catch (e) {
    DBErrorMonitor.captureException(e, {
      saga: 'delete',
      entity: 'medicalEntities',
    });
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.medicalEntitiesDeleteRequestError(error));
  }
}

export function* watchDelete() {
  yield takeLeading(Types.MEDICAL_ENTITIES_DELETE_REQUEST, deleteEntity);
}

export default watchDelete;
