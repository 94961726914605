import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/doctors/actions';
import doctorsApi from 'src/services/api/doctors';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* deleteDoctor(action: any) {
  try {
    const response = yield call(doctorsApi.delete, action.id);
    yield put(Creators.doctorsDeleteRequestSuccess(response));
    yield put(Creators.doctorsListRequest({}));
    toaster.success('doctor were deleted successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'delete', entity: 'doctors' });
    handleErrorResponse(error);
    yield put(Creators.doctorsDeleteRequestError(error));
  }
}

export function* watchDelete() {
  yield takeLeading(Types.DOCTORS_DELETE_REQUEST, deleteDoctor);
}

export default watchDelete;
