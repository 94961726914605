import { createReducer } from 'reduxsauce';
import { MedicationsReducerTypes } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: MedicationsReducerTypes = {
  list: [],
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
    keyword: '',
  },
  isLoading: false,
  hasError: false,
};

export const listRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    list: [],
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const listRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data, ...pagination } = action;

  return {
    ...state,
    list: data,
    pagination: { ...state.pagination, ...pagination },
    isLoading: false,
    hasError: false,
  };
};

export const listRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const cacheRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const cacheRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const cacheRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => INITIAL_STATE;

export const HANDLERS = {
  [Types.MEDICATIONS_LIST_REQUEST as string]: listRequest,
  [Types.MEDICATIONS_LIST_REQUEST_SUCCESS as string]: listRequestSuccess,
  [Types.MEDICATIONS_LIST_REQUEST_ERROR as string]: listRequestError,

  [Types.MEDICATIONS_CACHE_REQUEST as string]: cacheRequest,
  [Types.MEDICATIONS_CACHE_REQUEST_SUCCESS as string]: cacheRequestSuccess,
  [Types.MEDICATIONS_CACHE_REQUEST_ERROR as string]: cacheRequestError,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
