import { takeLeading, put, call, select } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/tags/actions';
import tagsApi from 'src/services/api/tags';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';
import ReduxStoreTypes from 'src/store/redux/types';
import { Tag } from 'src/types-dictionary';

export function* update(action: any) {
  try {
    const response = yield call(tagsApi.update, action.id, action.tag);
    yield put(Creators.tagsUpdateRequestSuccess(response));
    toaster.success('Tag were updated successfully');
    if (action.onlyUpdattingStatus) {
      const tags = yield select((store: ReduxStoreTypes) => store.tags.list);
      yield put(
        Creators.tagsListRequestSuccess({
          data: tags.map((item: Tag) => {
            if (item.id !== action.tag.id) {
              return item;
            } else {
              return action.tag;
            }
          }),
        })
      );
    }
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'update', entity: 'Tags' });
    toaster.error(e.message);
    let error = e.response;
    yield put(Creators.tagsUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.TAGS_UPDATE_REQUEST, update);
}

export default watchUpdate;
