import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/field-representatives/actions';
import fieldRepresentativesApi from 'src/services/api/field-representatives';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* deleteFieldRepresentative(action: any) {
  try {
    const response = yield call(fieldRepresentativesApi.delete, action.id);
    yield put(Creators.fieldRepresentativesDeleteRequestSuccess(response));
    toaster.success('field representative were deleted successfully');
    yield put(Creators.fieldRepresentativesListRequest({}));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'delete', entity: 'fieldRep' });
    handleErrorResponse(error);
    yield put(Creators.fieldRepresentativesDeleteRequestError(error));
  }
}

export function* watchDelete() {
  yield takeLeading(
    Types.FIELD_REPRESENTATIVES_DELETE_REQUEST,
    deleteFieldRepresentative
  );
}

export default watchDelete;
