import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    LABS_LIST_REQUEST
    LABS_LIST_REQUEST_SUCCESS
    LABS_LIST_REQUEST_ERROR

    LABS_CATEGORIES_REQUEST
    LABS_CATEGORIES_REQUEST_SUCCESS
    LABS_CATEGORIES_REQUEST_ERROR
  `,
  {
    prefix: '',
  }
);
