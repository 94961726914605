import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/labs/actions';
import labsApi from 'src/services/api/labs';

export function* categories(action: any) {
  try {
    const response = yield call(labsApi.categories);
    yield put(Creators.labsCategoriesRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.labsCategoriesRequestError(error));
  }
}

export function* watchCategories() {
  yield debounce(6e2, Types.LABS_CATEGORIES_REQUEST, categories);
}

export default watchCategories;
