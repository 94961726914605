import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  cptListRequest: ['Type', 'filters'],
  cptListRequestSuccess: ['data', 'filters'],
  cptListRequestError: ['error'],

  cptUpdateRequest: ['data'],
  cptUpdateRequestSuccess: ['data'],
  cptUpdateRequestError: ['error'],

  cptUploadRequest: ['Type', 'cpt'],
  cptUploadRequestError: ['error'],

  cptExportRequest: ['filters', 'Type'],
  cptExportRequestSuccess: ['data'],
  cptExportRequestError: ['error'],

  cptGetRequest: ['data'],
  cptGetRequestSuccess: ['data'],
  cptGetRequestError: ['error'],

  cptCreateRequest: ['data'],
  cptCreateRequestSuccess: ['data'],
  cptCreateRequestError: ['error'],

  cptSearchRequest: ['Type', 'filters', 'search'],
  cptSearchRequestSuccess: ['data'],
  cptSearchRequestError: ['error'],

  clearStore: [],
});
