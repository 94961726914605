import { all } from 'redux-saga/effects';
import { watchCreate } from './create';
import { watchDelete } from './delete';
import { watchGet } from './get';
import { watchList } from './list';
import { watchUpdate } from './update';

export function* watchTags() {
  yield all([
    watchList(),
    watchCreate(),
    watchUpdate(),
    watchDelete(),
    watchGet(),
  ]);
}
