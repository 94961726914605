import { takeLeading, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/agents/actions';
import agentsApi from 'src/services/api/agents';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';

export function* changeStatus() {
  try {
    let response = yield call(agentsApi.changeStatus);
    yield put(Creators.agentsStatusRequestSuccess(response.data));
    toaster.success(`Status has been changed successfully`);
  } catch (e) {
    let error = e.response;
    handleErrorResponse(error);
    toaster.success(`Status has not been changed!!`);
    yield put(Creators.agentsStatusRequestError(error));
  }
}

export function* watchAgentStatus() {
  yield takeLeading(Types.AGENTS_STATUS_REQUEST, changeStatus);
}

export default watchAgentStatus;
