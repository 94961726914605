import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/notifications/actions';
import notificationsApi from 'src/services/api/notifications';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* counts(action: any) {
  try {
    const response = yield call(notificationsApi.counts);
    yield put(Creators.countsAllRequestSuccess(response));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'count',
      entity: 'notification',
    });
    console.log(e);
    yield put(Creators.countsAllRequestError(error));
  }
}

export function* watchCounts() {
  yield debounce(6e2, Types.COUNTS_ALL_REQUEST, counts);
}

export default watchCounts;
