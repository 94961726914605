import { all, takeLatest, put, call } from 'redux-saga/effects';
import { Types } from '../../redux/cpt/action';
import cptApi from '../../../services/api/cpt';
import { Creators } from '../../redux/cpt/action';
import toaster from 'src/services/toaster';
import history from 'src/services/browser-history';

export const redirectToList = (action: any) => {
  if (action.data.integrationCode) {
    history.push('/CPT');
  }
};

export function* updateCpt(action: any) {
  try {
    let response = yield call(cptApi.update, action.data);
    yield put(Creators.cptUpdateRequestSuccess(response.data));
    toaster.success('CPT has been updated successfully');
    redirectToList(action);
  } catch (e) {
    let error = e.response;
    yield put(Creators.cptUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLatest(Types.CPT_UPDATE_REQUEST, updateCpt);
}
export default watchUpdate;
