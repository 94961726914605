import { createReducer } from 'reduxsauce';
import { TagsreducerType } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: TagsreducerType = {
  list: [],
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  isLoading: false,
  hasError: false,
  iscreatingLoading: false,
  isCreatingError: false,
  item: null,
  isGettingOneLoading: false,
  isGettingOneError: false,
};

export const listRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const listRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data, ...pagination } = action;

  return {
    ...state,
    list: data.data,
    pagination: { ...state.pagination, ...pagination },
    isLoading: false,
    hasError: false,
  };
};

export const listRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const createRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    iscreatingLoading: true,
    isCreatingError: false,
  };
};

export const createRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    iscreatingLoading: false,
    isCreatingError: false,
  };
};

export const createRequestError = (State = INITIAL_STATE) => {
  return {
    ...State,
    iscreatingLoading: false,
    isCreatingError: true,
  };
};

const getRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isGettingOneLoading: true,
    isGettingOneError: false,
  };
};
const getRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    item: action.data.data,
    isGettingOneLoading: false,
    isGettingOneError: false,
  };
};
const getRequestError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isGettingOneLoading: false,
    isGettingOneError: true,
  };
};

const updateRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};
const updateRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};
const updateRequestError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

const deleteRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
    hasError: false,
  };
};
const deleteRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};
const deleteRequestError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => INITIAL_STATE;

export const HANDLERS = {
  [Types.TAGS_LIST_REQUEST as string]: listRequest,
  [Types.TAGS_LIST_REQUEST_SUCCESS as string]: listRequestSuccess,
  [Types.TAGS_LIST_REQUEST_ERROR as string]: listRequestError,

  [Types.TAGS_CREATE_REQUEST as string]: createRequest,
  [Types.TAGS_CREATE_REQUEST_SUCCESS as string]: createRequestSuccess,
  [Types.TAGS_CREATE_REQUEST_ERROR as string]: createRequestError,

  [Types.TAGS_GET_REQUEST as string]: getRequest,
  [Types.TAGS_GET_REQUEST_SUCCESS as string]: getRequestSuccess,
  [Types.TAGS_GET_REQUEST_ERROR as string]: getRequestError,

  [Types.TAGS_UPDATE_REQUEST as string]: updateRequest,
  [Types.TAGS_UPDATE_REQUEST_SUCCESS as string]: updateRequestSuccess,
  [Types.TAGS_UPDATE_REQUEST_ERROR as string]: updateRequestError,

  [Types.TAGS_DELETE_REQUEST as string]: deleteRequest,
  [Types.TAGS_DELETE_REQUEST_SUCCESS as string]: deleteRequestSuccess,
  [Types.TAGS_DELETE_REQUEST_ERROR as string]: deleteRequestError,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
