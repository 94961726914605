import { apiRequest } from '../index';
import { LOGIN_URL, MFA_VERIFY_URL, CURRENT_USER_URL,LOGOUT_URL,FORGET_URL,RESET_URL } from '../urls';

export const login = (username: string, password: string) => {
  return apiRequest('POST', `${LOGIN_URL}`, {
    username,
    password,
  });
};
export const logout = () => {
  return apiRequest('POST', `${LOGOUT_URL}`, null);
};

export const getUser = () => {
  return apiRequest('GET', `${CURRENT_USER_URL}`, null, null);
};

export const forget = (email: string) => {
  return apiRequest('POST', `${FORGET_URL}`, {
    email,
  });
};

export const reset = (token: string, password: string) => {
  return apiRequest('POST', `${RESET_URL}/${token}`, {
    password,
  });
};

export const mfaVerify = (user: any) => {
  return apiRequest('POST', `${MFA_VERIFY_URL}`, {
    token: user.token,
    email: user.email,
  });
};

export default {
  mfaVerify,
  login,
  getUser,
  logout,
  forget,
  reset
  
};
