import React from 'react';
import { Router, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import { Provider as StoreProvider, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

import history from 'src/services/browser-history';
import store from 'src/store';
import { ROUTES } from 'src/constants';

import PageSpinner from 'src/components/Atoms/PageSpinner';
import DBSocket from 'src/services/websockets';

import ClaimsListingScreen from 'src/pages/claims-listing-screen';

import useUnload from './lib/helpers/custom-hooks';
import { normalizePermissions } from 'src/lib/helpers/permissions';
import ReduxStoreTypes from './store/redux/types';

import NotFoundPage from './pages/not-found-page-screen';
import AccessDenied from './pages/access-denied-screen/components';
import ClaimsViewScreen from 'src/pages/claim-view-screen';
import { UserRoles } from './types-dictionary';

const DashboardScreen = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard-screen" */

      'src/pages/dashboard-screen'
    )
);

const LoginScreen = React.lazy(
  () =>
    import(
      /* webpackChunkName: "login-screen" */
      /* webpackPrefetch: true */
      'src/pages/login-screen'
    )
);
const AgentProfileScreen = React.lazy(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "agent-profile-screen" */ 'src/pages/agent-profile-screen'
    )
);

const ForgetPasswordScreen = React.lazy(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "forget-password-screen" */ 'src/pages/forget-password-screen'
    )
);

const ResetPasswordScreen = React.lazy(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "reset-password-screen" */ 'src/pages/reset-password-screen'
    )
);

const NewPasswordScreen = React.lazy(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "new-password-screen" */ 'src/pages/new-password-screen'
    )
);

const TPAStatisticsScreen = React.lazy(
  () =>
    import(
      
      'src/pages/statistics-tpa-screen'
    )
);


const MEStatisticsScreen = React.lazy(
  () =>
    import(
      
      'src/pages/statistics-me-screeen'
    )
);

const decode_jwt = (token: any) => {
  try {
    return jwtDecode(token);
  } catch (e) {
    return null;
  }
};

const PrivateRoute = (props: RouteProps) => {
  const accessToken = useSelector(
    (state: ReduxStoreTypes) => state.auth.accessToken
  );
  const currPermissions = useSelector(
    (store: ReduxStoreTypes) => store.roles.currPerm
  );
  const user = useSelector((store: ReduxStoreTypes) => store.auth.user);
  const dispatch = useDispatch();


  React.useEffect(() => {
    DBSocket.connect('users');
    if (!user) {
      dispatch({ type: 'USER_REQUEST' });
    }

    //@ts-ignore
    //this is made for testing only
    if (window.Cypress && process.env.NODE_ENV === 'development') {
      //@ts-ignore
      window.Cypress.__reduxStore__ = store;
    }
  }, [dispatch]);
  ////////////////////////////////////////////////////////////
  ///// Clearing Redux Stores on Rerouting ///////////////////
  ////////////////////////////////////////////////////////////
  React.useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_STORE' });
    };
  }, [dispatch, props.path]);
  ////////////////////////////////////////////////////////////
  // Authentication //////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  const isLocal = process.env.REACT_APP_BACKEND?.includes('localhost');

  if (isLocal) {
    const decoded: any = decode_jwt(accessToken);
    const now = new Date().getTime() / 1000;

    const hasToken = decoded?.exp ? true : false;
    const isExpired = now > decoded?.exp;

    if (!hasToken || isExpired) {
      return <Redirect exact to={ROUTES.AUTH.LOGIN} />;
    }
  }

  ////////////////////////////////////////////////////////////
  // Authorization ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  const path = (props.path as string)
    .split('/')
    .filter((part: string) => part !== '');

  if (
    currPermissions.length !== 0 &&
    !currPermissions.includes('all_access') &&
    !path?.includes('profile') &&
    !path?.includes('dashboard')
  ) {
    const nPermissions = normalizePermissions(currPermissions);

    const path = (props.path as string)
      .split('/')
      .filter((part: string) => part !== '');

    let accessAllowed = false;

    for (const perm in nPermissions) {
      let comparingPerm = perm;
      if (comparingPerm === 'fieldRepresentative') {
        comparingPerm = 'field-representative';
      } else if (comparingPerm === 'medicalEntity') {
        comparingPerm = 'medical-entitie';
      } else if (comparingPerm === 'member') {
        comparingPerm = 'agent';
      } else if (comparingPerm === 'lab' || comparingPerm === 'scan') {
        comparingPerm = 'CPT';
      } else if (comparingPerm === 'agentCapacity') {
        comparingPerm = 'slaConfig';
      }
      else if (comparingPerm === 'TPADashboard') {
        comparingPerm = 'tpa-statistics';
      }
      else if (comparingPerm === 'ProviderDashboard') {
        comparingPerm = 'me-statistics';
      }
      
      
      if (path.includes('tpa-statistics') && user && user.role == UserRoles.TPA_ADMIN) {
        accessAllowed = true;
        break;
      }

      if (path.includes('me-statistics') && user && [UserRoles.ME_ADMIN, UserRoles.GME_ADMIN].includes(user.role)) {
        accessAllowed = true;
        break;
      }
      
      

      if (path.includes('claims') && path.includes('edit')) {
        accessAllowed = true;
        break;
      }
      if (path.includes(comparingPerm + 's') || path.includes(comparingPerm)) {
        //read permission
        if (nPermissions[perm].includes('read')) {
          accessAllowed = true;
          break;
        }

        //create permission
        else if (
          path.includes('add') &&
          nPermissions[perm].includes('create')
        ) {
          accessAllowed = true;
          break;
        }
        //delete & update permission
        else if (
          nPermissions[perm].includes('update') ||
          nPermissions[perm].includes('delete')
        ) {
          accessAllowed = true;
          break;
        }
      }
      accessAllowed = false;
    }

    if (!accessAllowed) {
      return <Redirect exact to={ROUTES.AUTH.ACCESS} />;
    }
  }

  return <Route {...props} />;
};

function App() {
  useUnload(() => {
    DBSocket.closeAllConnections();
  });

  return (
    <StoreProvider store={store}>
      <ToastContainer />
      <Router history={history}>
        <React.Suspense fallback={<PageSpinner />}>
          <Switch>
            <Redirect exact from={'/'} to={ROUTES.CLAIMS.ALL} />

            <Route exact path={ROUTES.AUTH.LOGIN} component={LoginScreen} />

            <Route
              exact
              path={ROUTES.AUTH.FORGET}
              component={ForgetPasswordScreen}
            />

            <Route
              exact
              path={ROUTES.AUTH.CHALLENGES.NEW_PASSWORD}
              component={NewPasswordScreen}
            />

            <Route
              exact
              path={ROUTES.AUTH.RESET}
              component={ResetPasswordScreen}
            />

            <Route exact path={ROUTES.AUTH.ACCESS} component={AccessDenied} />


            <PrivateRoute
              exact
              path={ROUTES.AGENTS.PROFILE}
              component={AgentProfileScreen}
            />

            <PrivateRoute
              exact
              path={ROUTES.DASHBOARD.MAIN}
              component={DashboardScreen}
            />

            <PrivateRoute
              exact
              path={ROUTES.CLAIMS.ALL}
              component={ClaimsListingScreen}
            />

             <PrivateRoute
              exact
              path={ROUTES.CLAIMS.EDIT}
              component={ClaimsViewScreen}
            />

            <PrivateRoute
              exact
              path={ROUTES.DASHBOARD.TPA_STATISTICS}
              component={TPAStatisticsScreen}
            />


            <PrivateRoute
              exact
              path={ROUTES.DASHBOARD.ME_TATISTICS}
              component={MEStatisticsScreen}
            />

            <PrivateRoute path="*" component={NotFoundPage} />
          </Switch>
        </React.Suspense>
      </Router>
    </StoreProvider>
  );
}

export default App;
