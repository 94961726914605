import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import classnames from 'classnames';
import { ReactComponent as DashboardIcon } from 'src/assets/icons/bar-chart.svg';
import { ReactComponent as ClaimsIcon } from 'src/assets/icons/claims-icon.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/closeIcon.svg';

import NavTabs from 'src/components/Atoms/NavTabs';

import styles from './sidebar.module.scss';

import { ROUTES} from 'src/constants';
import { useSelector } from 'react-redux';
import { validateUser } from 'src/lib/helpers/permissions';
import Loader from 'src/components/Molecules/Loader/Loader';

function SidebarComponent(props: any) {
  const currentUrl = window.location.pathname;
  const perms = useSelector((store: any) => store.roles.currPerm);
  const permsLoading = useSelector((store: any) => store.roles.isCurrLoading);

  const tabsActivity = {
    dashboardSlider: Object.values(ROUTES.DASHBOARD).includes(currentUrl),
    dashboard: currentUrl === ROUTES.DASHBOARD.MAIN,
    tpa_stats: currentUrl === ROUTES.DASHBOARD.TPA_STATISTICS,
    me_stats:currentUrl===ROUTES.DASHBOARD.ME_TATISTICS,
    claims: currentUrl === ROUTES.CLAIMS.ALL
   
  };

  return (
    <Navbar bg="white" expand="lg" className={styles.StickyNav}>
      <Nav className={classnames('flex-column', styles.sidebarNav)}>
        <Navbar.Brand className={styles.brand}>
          <div
            className={styles.closeIcon}
            onClick={() => props.toggle()}
            id="closeIcon_res">
            <CloseIcon />
          </div>

          <img
            src={require('src/assets/images/app-texted-logo.png')}
            alt="app logo"
          />
        </Navbar.Brand>
        {(permsLoading && <Loader type="list" rows={3} />) || (
          <>
          

            <NavTabs       
             title="Dashboard"
             icon={<DashboardIcon />}
             isOpen={tabsActivity.dashboardSlider}          
            >
             <NavTabs.Tab
               to={ROUTES.DASHBOARD.TPA_STATISTICS}
               title="TPA Statistics"
               active={tabsActivity.tpa_stats}
               hidden={!validateUser('read_TPADashboard', perms)}
              />
             <NavTabs.Tab
                to={ROUTES.DASHBOARD.ME_TATISTICS}
                title="ME Statistics"
                active={tabsActivity.me_stats}
                hidden={!validateUser('read_ProviderDashboard', perms)}
                />   

            </NavTabs>
              
            

            <NavTabs.Tab
              to={ROUTES.CLAIMS.ALL}
              title="Prescriptions"
              icon={<ClaimsIcon />}
              active={tabsActivity.claims}
              hidden={!validateUser('read_claim', perms)}
              tabular
              isParent={true}
            />
           
          </>
        )}
      </Nav>
    </Navbar>
  );
}

const Sidebar = React.memo(SidebarComponent);

export default Sidebar;
