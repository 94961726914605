import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import DBSocket from 'src/services/websockets';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* report(action: any) {
  try {
    const responseapi = yield call(claimsApi.statsReport);
    yield put(Creators.claimsReportRequestSuccess(responseapi.data));

    while (DBSocket.isConnected('claims')) {
      const response = yield call(
        DBSocket.listenToSocket,
        'claims',
        'statistics'
      );
      yield put(Creators.claimsReportRequestSuccess(response));
    }
  } catch (e) {
    console.log(e);
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'report', entity: 'claims' });
    handleErrorResponse(error);
    yield put(Creators.claimsReportRequestError(error));
  }
}

export function* watchReport() {
  yield debounce(6e2, Types.CLAIMS_REPORT_REQUEST, report);
}

export default watchReport;
