import { apiRequest } from '../index';
import { DOCTORS_URL } from '../urls';
import { Filters } from 'src/props/filters.props';

export const create = (doctor: any) => {
  return apiRequest('POST', DOCTORS_URL, doctor);
};

export const update = ({ id, ...data }: any) => {
  return apiRequest('PATCH', `${DOCTORS_URL}/${id}`, data);
};

export const deleteDoctor = (id: string | number) => {
  return apiRequest('DELETE', `${DOCTORS_URL}/${id}`, null);
};

export const list = (filters: Filters) => {
  return apiRequest('GET', `${DOCTORS_URL}/query-doctors`, null, filters);
};

export const upload = (doctors: any) => {
  return apiRequest('POST', `${DOCTORS_URL}/upload`, doctors);
};

export const get = (id: string | number) => {
  return apiRequest('GET', `${DOCTORS_URL}/${id}`, null);
};

export default {
  create,
  update,
  delete: deleteDoctor,
  list,
  upload,
  get,
};
