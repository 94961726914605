import { takeLatest, put, call } from 'redux-saga/effects';
import { Types } from '../../redux/cpt/action';
import cptApi from '../../../services/api/cpt';
import { Creators } from '../../redux/cpt/action';

export function* getCPT(action: any) {
  const { type, id } = action.data;
  try {
    let response = yield call(cptApi.get, id, type);
    yield put(Creators.cptGetRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.cptGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLatest(Types.CPT_GET_REQUEST, getCPT);
}
export default watchGet;
