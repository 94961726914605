import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/comments/actions';
import { Creators as ClaimCreators } from 'src/store/redux/claims/actions';
import commentApi from 'src/services/api/comments';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* create({ comment }: any) {
  try {
    const response = yield call(commentApi.create, comment);
    yield put(Creators.commentsCreateRequestSuccess(response.data));
    toaster.success('comment were added successfully');

    yield put(ClaimCreators.claimsGetRequest(comment?.claim?.id));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'create', entity: 'comments' });
    handleErrorResponse(error);
    yield put(Creators.commentsCreateRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLeading(Types.COMMENTS_CREATE_REQUEST, create);
}

export default watchCreate;
