import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

interface UserVerificationData {
  npCode: string;
  claimFormId: number;
  patientId: string;
  claimId: number;
}

export function* checkEligibility({ formik }: any) {
  try {
    const userData: UserVerificationData = {
      claimId: formik.values.id,
      patientId: formik.values.patientIdNumber,
      ...(formik.values?.claimFormId && {
        claimFormId: Number(formik.values.claimFormId),
      }),
      ...(formik.values?.npCode && { npCode: formik.values.npCode }),
      ...(formik.values?.insuranceCompany?.name === 'AXA' && {
        insuranceCompanyName: formik.values.insuranceCompany.name,
        policyNumber: formik.values.policyNumber,
      }),
    };
    const { data } = yield call(claimsApi.checkEligibility, userData);

    if (data.patient) {
      if (data.patient.name)
        formik.setFieldValue('patientName', data.patient.name);
      if (data.patient.mobileNumber)
        formik.setFieldValue('patientPhoneNumber', data.patient.mobileNumber);
    }

    yield put(Creators.claimsCheckEligibilityRequestSuccess(data));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'checkEligibility',
      entity: 'claims',
    });
    handleErrorResponse(error);
    yield put(Creators.claimsCheckEligibilityRequestError(error));
  }
}

export function* watchCheckEligibility() {
  yield debounce(6e2, Types.CLAIMS_CHECK_ELIGIBILITY_REQUEST, checkEligibility);
}

export default watchCheckEligibility;
