import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    commentsCreateRequest: ['comment'],
    commentsCreateRequestSuccess: ['data'],
    commentsCreateRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
