import { takeLatest, take, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/cpt/action';
import cptApi from 'src/services/api/cpt/index';
import toaster from 'src/services/toaster';
export function* upload({ Type, cpt }: any) {
  try {
    const fileParts = cpt.name.split('.');
    const ext = fileParts[fileParts.length - 1];
    if (!['csv', 'xlsx'].includes(ext)) {
      toaster.error('The uploaded file type should be csv or Excel (.xlsx).');
      throw new Error();
    }
    const data = new FormData();
    data.append('file', cpt);
    switch (Type) {
      case 'labs':
        data.append('service', 'LAB');
        break;
      case 'scans':
        data.append('service', 'SCAN');
        break;
      default:
        data.append('service', 'LAB');
    }
    let response = yield call(cptApi.upload, data);
    response.status === 201 &&
      toaster.success(
        "The file is uploaded successfully and it's currently being processed"
      );
    yield put(Creators.cptListRequest(Type));
  } catch (e) {
    let error = e.response;
    toaster.error('Failed to import the file');
    yield put(Creators.cptUploadRequestError(error));
  }
}

export function* watchUpload() {
  yield takeLatest(Types.CPT_UPLOAD_REQUEST, upload);
}

export default watchUpload;
