import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* track(data: any) {
  try {
    const response = yield call(claimsApi.track, data.tracker);
    yield put(Creators.claimsTrackRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'Track',
      entity: 'claims',
    });
    handleErrorResponse(error);
    yield put(Creators.claimsTrackRequestError(error));
  }
}

export function* watchTrack() {
  yield debounce(6e2, Types.CLAIMS_TRACK_REQUEST, track);
}

export default watchTrack;
