import { apiRequest } from '../index';
import { MEDICATIONS_URL } from '../urls';
import { CacheManager } from 'src/services/cache';

export const list = async (filters: any) => {
  const cacheMgr = new CacheManager();
  await cacheMgr.init('medication-cache', 10);

  const url = MEDICATIONS_URL;
  const response = await cacheMgr.getCache(
    `${url}/?keyword=${filters.keyword}`,
    'json'
  );

  if (response) {
    return { data: { data: response } };
  }

  const data = apiRequest(
    'POST',
    `${MEDICATIONS_URL}/search-medication`,
    filters,
    null
  );

  return data;
};

export const cache = async (keyword: any, data: any[]) => {
  const cacheMgr = new CacheManager();
  await cacheMgr.init('medication-cache', 10);

  const url = MEDICATIONS_URL;
  await cacheMgr.setCache(`${url}/?keyword=${keyword}`, { data }, 'json');

  return { data: { msg: 'cached successfully' } };
};

export default {
  list,
  cache,
};
