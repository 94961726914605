import { apiRequest, apiExport } from '../index';
import { CONFIG_URL } from '../urls';

export const get = () => {
  return apiRequest('GET', `${CONFIG_URL}/SLA`, null);
};

export const update = (data: any) => {
  return apiRequest('POST', `${CONFIG_URL}/SLA`, data);
};

export const updateAgent = (capacity: number) => {
  return apiRequest('PATCH', `${CONFIG_URL}/update-agent-capacity`, {
    agent_capacity: capacity,
  });
};

export default {
  get,
  update,
  updateAgent,
};
