import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/doctors/actions';
import doctorsApi from 'src/services/api/doctors';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get({ id }: any) {
  try {
    const response = yield call(doctorsApi.get, id);
    yield put(Creators.doctorsGetRequestSuccess(response));
  } catch (e) {
    //TODO add error handling in a function
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'doctors' });
    switch (error.data.statusCode) {
      case 400:
        toaster.error(error.data.message);
        break;
      case 500:
        toaster.error('Internal Server Error');
        break;
      default:
        toaster.error(error);
    }
    yield put(Creators.doctorsGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLeading(Types.DOCTORS_GET_REQUEST, get);
}

export default watchGet;
