import { takeLatest, put, call } from 'redux-saga/effects';
import { Types } from '../../redux/cpt/action';
import cptApi from '../../../services/api/cpt';
import { Creators } from '../../redux/cpt/action';
import history from 'src/services/browser-history';
import toaster from 'src/services/toaster';

export function* createCPT(action: any) {
  try {
    let response = yield call(cptApi.create, action.data);
    yield put(Creators.cptCreateRequestSuccess(response.data));
    toaster.success('CPT has been created successfully');
    history.push('/CPT');
  } catch (e) {
    let error = e.response;
    yield put(Creators.cptCreateRequestError(error));
  }
}

export function* watchCreate() {
  yield takeLatest(Types.CPT_CREATE_REQUEST, createCPT);
}
export default watchCreate;
