import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/sms-config/actions';
import smsConfigApi from 'src/services/api/sms-config';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get({ id }: any) {
  try {
    const response = yield call(smsConfigApi.get, id);
    yield put(Creators.smsConfigGetRequestSuccess(response));
  } catch (e) {
    //TODO add error handling in a function
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'sms_config' });
    switch (error.data.statusCode) {
      case 400:
        toaster.error(error.data.message);
        break;
      case 500:
        toaster.error('Internal Server Error');
        break;
      default:
        toaster.error(error);
    }
    yield put(Creators.smsConfigGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLeading(Types.SMS_CONFIG_GET_REQUEST, get);
}

export default watchGet;
