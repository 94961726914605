import { takeLeading, put, call, select } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/tags/actions';
import tagsApi from 'src/services/api/tags';
import toaster from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';
import ReduxStoreTypes from 'src/store/redux/types';
import { Tag } from 'src/types-dictionary';

export function* deleteTag(action: any) {
  try {
    const response = yield call(tagsApi.deleteTag, action.id);
    yield put(Creators.tagsDeleteRequestSuccess(response));
    toaster.success('Tag were Deleted successfully');
    const tags = yield select((store: ReduxStoreTypes) => store.tags.list);
    yield put(
      Creators.tagsListRequestSuccess({
        data: tags.filter((tag: Tag) => tag.id !== action.id),
      })
    );
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'delete', entity: 'Tags' });
    toaster.error(e.message);
    let error = e.response;
    yield put(Creators.tagsDeleteRequestError(error));
  }
}

export function* watchDelete() {
  yield takeLeading(Types.TAGS_DELETE_REQUEST, deleteTag);
}

export default watchDelete;
