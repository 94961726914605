import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/notifications/actions';

import notificationsApi from 'src/services/api/notifications';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* list(action: any) {
  try {
    if (action.filters.place === 'header') {
      delete action.filters.page;
    }

    const response = yield call(notificationsApi.list, action.filters);

    if (action.filters.place === 'header') {
      yield put(Creators.notificationsListHeaderRequestSuccess(response.data));
    } else {
      yield put(Creators.notificationsListRequestSuccess(response.data));
    }
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'list',
      entity: 'notification',
    });
    yield put(Creators.notificationsListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.NOTIFICATIONS_LIST_REQUEST, list);
}

export default watchList;
