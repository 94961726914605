import { createReducer } from 'reduxsauce';
import { CommenTReducerTypes } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: CommenTReducerTypes = {
  comment: {},
  isLoading: false,
  hasError: false,
};

export const commentsCreateRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const commentsCreateRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const commentsCreateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => INITIAL_STATE;

export const HANDLERS = {
  [Types.COMMENTS_CREATE_REQUEST as string]: commentsCreateRequest,
  [Types.COMMENTS_CREATE_REQUEST_SUCCESS as string]: commentsCreateRequestSuccess,
  [Types.COMMENTS_CREATE_REQUEST_ERROR as string]: commentsCreateRequestError,
  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
