import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/notifications/actions';
import notificationsApi from 'src/services/api/notifications';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* read(action: any) {
  try {
    const response = yield call(notificationsApi.read, action.data);
    yield put(Creators.notificationsReadRequestSuccess(response));
    yield put(
      Creators.notificationsListRequest({
        place: 'header',
        limit: 5,
        sort: 'id,DESC',
      })
    );
  } catch (e) {
    DBErrorMonitor.captureException(e, {
      saga: 'read',
      entity: 'notification',
    });
    let error = e.response;
    console.log(e);
    yield put(Creators.notificationsReadRequestError(error));
  }
}

export function* watchRead() {
  yield debounce(6e2, Types.NOTIFICATIONS_READ_REQUEST, read);
}

export default watchRead;
