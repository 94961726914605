import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/agents/actions';
import agentsApi from 'src/services/api/agents';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* deleteAgent({ id }: any) {
  try {
    yield call(agentsApi.delete, id);
    yield put(Creators.agentsDeleteRequestSuccess(id));
    yield put(Creators.agentsListRequest({}));
    toaster.success('agent were deleted successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'delete', entity: 'agents' });
    handleErrorResponse(error);
    yield put(Creators.agentsDeleteRequestError(error));
  }
}

export function* watchDelete() {
  yield takeLeading(Types.AGENTS_DELETE_REQUEST, deleteAgent);
}

export default watchDelete;
