import { apiRequest } from '../index';
import { SCANS_URL } from '../urls';

export const list = (filters: any) => {
  const url = `${SCANS_URL}/search-scan`;
  const categories: string[] = [];
  const searchScans: { keyword?: string; categories?: string[] } = {};

  if (filters.filter) {
    Object.keys(filters.filter.category).forEach((scanF: any) => {
      if (filters.filter.category[scanF].isFilteredBy) {
        categories.push(filters.filter.category[scanF].value);
      }
    });

    searchScans.keyword = filters.filter.name;
    searchScans.categories = categories;

    delete filters.filter;
  }
  return apiRequest('POST', url, searchScans, null);
};

export const categories = () => {
  return apiRequest('GET', `${SCANS_URL}/categories`, null, null);
};

export default {
  list,
  categories,
};
