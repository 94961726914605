import { all } from 'redux-saga/effects';
import { watchReport } from './report';
import { watchExport } from './export';
import { watchList } from './list';
import { watchGet } from './get';
import { watchUpdate } from './update';
import { watchCount } from './count';
import watchListener from './new';
import watchParseImage from './parseImage';
import { watchAssignClaimToSelf } from './assign-to-self';
import watchGetImage from './getImage';
import { watchDelete } from './delete';
import watchTrack from './track';
import watchCheckEligibility from './checkEligibility';
import watchGetAttachment from './getAttachment';
import watchCancel from './cancel';
import watchAssignClaimToAgent from './assign-to-agent';
import watchHistory from './history';

export function* watchClaims() {
  yield all([
    watchReport(),
    watchExport(),
    watchList(),
    watchGet(),
    watchUpdate(),
    watchCount(),
    watchListener(),
    watchParseImage(),
    watchAssignClaimToSelf(),
    watchGetImage(),
    watchDelete(),
    watchTrack(),
    watchCheckEligibility(),
    watchGetAttachment(),
    watchCancel(),
    watchAssignClaimToAgent(),
    watchHistory(),
  ]);
}
