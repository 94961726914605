import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    TAGS_LIST_REQUEST
    TAGS_LIST_REQUEST_SUCCESS
    TAGS_LIST_REQUEST_ERROR


    TAGS_CREATE_REQUEST
    TAGS_CREATE_REQUEST_SUCCESS
    TAGS_CREATE_REQUEST_ERROR


    TAGS_UPDATE_REQUEST
    TAGS_UPDATE_REQUEST_SUCCESS
    TAGS_UPDATE_REQUEST_ERROR


    TAGS_DELETE_REQUEST
    TAGS_DELETE_REQUEST_SUCCESS
    TAGS_DELETE_REQUEST_ERROR


    TAGS_GET_REQUEST
    TAGS_GET_REQUEST_SUCCESS
    TAGS_GET_REQUEST_ERROR

    CLEAR_STORE

  `,
  {
    prefix: '',
  }
);
