import { createReducer } from 'reduxsauce';
import { RolesreducerTypes } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: RolesreducerTypes = {
  list: [],
  allPerm: [],
  currPerm: [],
  item: {},
  isCurrLoading: false,
  isLoading: false,
  hasError: false,
};

export const listPermRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const listPermRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action.data;
  return {
    ...state,
    allPerm: data,
    isLoading: false,
    hasError: false,
  };
};

export const listRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const listRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const listRequestSuccess = (state = INITIAL_STATE, action: any) => {
  const { data } = action.data;
  return {
    ...state,
    list: data,
    isLoading: false,
    hasError: false,
  };
};

export const listPermRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const addRoleRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const addRoleRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const addRoleRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const deleteRoleRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const deleteRoleRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const deleteRoleRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const getRoleRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getRoleRequestSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    item: action.data[0],
    isLoading: false,
    hasError: false,
  };
};

export const getRoleRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const getCurrRoleRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isCurrLoading: true,
  };
};

export const getCurrRoleRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    currPerm: action.data,
    isCurrLoading: false,
    hasError: false,
  };
};

export const getCurrRoleRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isCurrLoading: false,
    hasError: true,
  };
};

export const updateRoleRequest = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateRoleRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const updateRoleRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const HANDLERS = {
  [Types.ROLES_LIST_PERM_REQUEST as string]: listPermRequest,
  [Types.ROLES_LIST_PERM_REQUEST_SUCCESS as string]: listPermRequestSuccess,
  [Types.ROLES_LIST_PERM_REQUEST_ERROR as string]: listPermRequestError,

  [Types.ROLES_LIST_REQUEST as string]: listRequest,
  [Types.ROLES_LIST_REQUEST_SUCCESS as string]: listRequestSuccess,
  [Types.ROLES_LIST_REQUEST_ERROR as string]: listRequestError,

  [Types.ROLES_ADD_REQUEST as string]: addRoleRequest,
  [Types.ROLES_ADD_REQUEST_SUCCESS as string]: addRoleRequestSuccess,
  [Types.ROLES_ADD_REQUEST_ERROR as string]: addRoleRequestError,

  [Types.ROLES_DELETE_REQUEST as string]: deleteRoleRequest,
  [Types.ROLES_DELETE_REQUEST_SUCCESS as string]: deleteRoleRequestSuccess,
  [Types.ROLES_DELETE_REQUEST_ERROR as string]: deleteRoleRequestError,

  [Types.ROLES_GET_REQUEST as string]: getRoleRequest,
  [Types.ROLES_GET_REQUEST_SUCCESS as string]: getRoleRequestSuccess,
  [Types.ROLES_GET_REQUEST_ERROR as string]: getRoleRequestError,

  [Types.ROLES_GET_CURR_REQUEST as string]: getCurrRoleRequest,
  [Types.ROLES_GET_CURR_REQUEST_SUCCESS as string]: getCurrRoleRequestSuccess,
  [Types.ROLES_GET_CURR_REQUEST_ERROR as string]: getCurrRoleRequestError,

  [Types.ROLES_UPDATE_REQUEST as string]: updateRoleRequest,
  [Types.ROLES_UPDATE_REQUEST_SUCCESS as string]: updateRoleRequestSuccess,
  [Types.ROLES_UPDATE_REQUEST_ERROR as string]: updateRoleRequestError,
};

export default createReducer(INITIAL_STATE, HANDLERS);
