import { createReducer } from 'reduxsauce';
import history from 'src/services/browser-history';
import { MEStatisticsReducerType } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: MEStatisticsReducerType = {

  dbPerformance:null,
  dbPerformanceLoading:false,
  dbPerformanceError:false,

  medicalGroupStats:[],

  
  providerPerformance:null,
  providerPerformanceLoading:false,
  providerPerformanceError:false,

  perscAnalytics:null,
  perscAnalyticsLoading:false,
  perscAnalyticsError:false,


  heatMap:null,
  heatMapLoading:false,
  heatMapError:false,

  medicalEntitiesStats: null,
  medicalEntitiesStatsLoading: false,
  medicalEntitiesStatsError: false,

  medicalEntitiesStatsTyped: null,
  medicalEntitiesStatsTypedLoading: false,
  medicalEntitiesStatsTypedError: false,


  dateFilter: null,
  entityFilter: {entityType:null,entityName:[]},

  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
};



const getProviderPerformance = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    providerPerformanceLoading: true,
    providerPerformanceError: false,
  };
};

const getProviderPerformanceSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    providerPerformance: action.data,
    providerPerformanceLoading: false,
    providePerformanceError: false,
  };
};

const getProviderPerformanceError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    providerPerformanceLoading: false,
    providerPerformanceError: true,
  };
};


const getPerscAnalytics = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    perscAnalyticsLoading: true,
    perscAnalyticsError: false,
  };
};

const getPerscAnalyticsSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    perscAnalytics: action.data,
    perscAnalyticsLoading: false,
    perscAnalyticsError: false,
  };
};

const getPerscAnalyticsError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    perscAnalyticsLoading: false,
    perscAnalyticsError: true,
  };
};






const getDBPerformance = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    dbPerformanceLoading: true,
    dbPerformanceError: false,
  };
};

const getDBPerformanceSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    dbPerformance: action.data,
    dbPerformanceLoading: false,
    dbPerformanceError: false,
  };
};

const getDBPerformanceError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    dbPerformanceLoading: false,
    dbPerformanceError: true,
  };
};



const getHeatMap = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    heatMapLoading: true,
    heatMapError: false,
  };
};

const getHeatMapSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    heatMap: action.data,
    heatMapLoading: false,
    heatMapError: false,
  };
};

const getHeatMapError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    heatMapLoading: false,
    heatMapError: true,
  };
};


const getMedTypedStats = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    medicalEntitiesStatsTypedLoading: true,
    medicalEntitiesStatsTypedError: false,
  };
};

const getMedTypedStatsSuccess = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    totalClaimsCount:
      action.data.data.length > 0
        ? action.data?.data
            ?.map((ent: any) => Number(ent.NumOfClaims))
            .reduce((accumulator: number, currentValue: number) => {
              return Number(accumulator) + Number(currentValue);
            })
        : null,
    medicalEntitiesStatsTyped: action.data.data,
    medicalEntitiesStatsTypedLoading: false,
    medicalEntitiesStatsTypedError: false,
  };
};

const getMedTypedStatsError = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,

    medicalEntitiesStatsTypedLoading: false,
    medicalEntitiesStatsTypedError: true,
  };
};


const updateStatsDateFilters = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    dateFilter: action.filters,
  };
};

const updateStatsEntityFilters = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    entityFilter: action.filters,
  };
};
const updateMedGroupFilter = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    medicalGroupStats:action.filters
              
  };
};

export const fetchStatsData = (state = INITIAL_STATE, action: any) => {
  return state;
};

const clearDateFilter = (state = INITIAL_STATE, action: any) => {
  return { ...state, dateFilter: null };
};

const clearEntityFilter = (state = INITIAL_STATE, action: any) => {
  return { ...state, entityFilter: null };
};
const clearMedGroupFilter = (state = INITIAL_STATE, action: any) => {
  return { ...state, medicalGroupStats: null };
};

export const clearStore = (state = INITIAL_STATE) => {
  if ('/dashboard'.includes(history.location.pathname.split('/')[1])) {
    return { ...INITIAL_STATE, pagination: state.pagination };
  }
  return INITIAL_STATE;
};

export const HANDLERS = {

  [Types.STATISTICS_ME_PROVIDER_PERFORMANCE_REQUEST as string]: getProviderPerformance,
  [Types.STATISTICS_ME_PROVIDER_PERFORMANCE_REQUEST_SUCCESS as string]: getProviderPerformanceSuccess,
  [Types.STATISTICS_ME_PROVIDER_PERFORMANCE_REQUEST_ERROR as string]: getProviderPerformanceError,


  [Types.STATISTICS_ME_PERSC_ANALYTICS_REQUEST as string]: getPerscAnalytics,
  [Types.STATISTICS_ME_PERSC_ANALYTICS_REQUEST_SUCCESS as string]: getPerscAnalyticsSuccess,
  [Types.STATISTICS_ME_PERSC_ANALYTICS_REQUEST_ERROR as string]: getPerscAnalyticsError,

  

  [Types.STATISTICS_ME_DB_PERFORMANCE_REQUEST as string]: getDBPerformance,
  [Types.STATISTICS_ME_DB_PERFORMANCE_REQUEST_SUCCESS as string]: getDBPerformanceSuccess,
  [Types.STATISTICS_ME_DB_PERFORMANCE_REQUEST_ERROR as string]: getDBPerformanceError,

  [Types.STATISTICS_ME_HEAT_MAP_REQUEST as string]: getHeatMap,
  [Types.STATISTICS_ME_HEAT_MAP_REQUEST_SUCCESS as string]: getHeatMapSuccess,
  [Types.STATISTICS_ME_HEAT_MAP_REQUEST_ERROR as string]: getHeatMapError,

  [Types.STATISTICS_ME_MEDICAL_ENTITY_TYPED_REQUEST as string]: getMedTypedStats,
  [Types.STATISTICS_ME_MEDICAL_ENTITY_TYPED_REQUEST_SUCCESS as string]: getMedTypedStatsSuccess,
  [Types.STATISTICS_ME_MEDICAL_ENTITY_TYPED_REQUEST_ERROR as string]: getMedTypedStatsError,


  [Types.STATISTICS_ME_UPDATE_DATE_FILTERS as string]: updateStatsDateFilters,
  [Types.STATISTICS_ME_UPDATE_ENTITY_FILTERS as string]: updateStatsEntityFilters,
  [Types.STATISTICS_ME_UPDATE_MEDICAL_GROUPS_FILTER as string]: updateMedGroupFilter,

  [Types.STATISTICS_ME_GET_STATISTICS_DATA as string]: fetchStatsData,


  [Types.STATISTICS_ME_CLEAR_DATE_FILTERS]: clearDateFilter,
  [Types.STATISTICS_ME_CLEAR_ENTITY_FILTERS]: clearEntityFilter,
  [Types.STATISTICS_ME_CLEAR_MEDICAL_GROUPS_FILTER]: clearMedGroupFilter,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
