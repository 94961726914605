import { apiRequest } from '../index';
import { DISEASE_CODES_URL } from '../urls';
import { Filters } from 'src/props/filters.props';

export const list = (filters: Filters) => {
  let url = DISEASE_CODES_URL;
  if (filters.filter) {
    filters.s = {
      $or: [
        { code: { $cont: filters.filter } },
        { description: { $cont: filters.filter } },
      ],
    };
    delete filters.filter;
  }
  return apiRequest('GET', url, null, filters);
};

export default {
  list,
};
