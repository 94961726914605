import React from 'react';
import { withLayout } from '../../templates/layout';

const Screen = React.lazy(() => {
  return import(/* webpackChunkName: "claims-listing-screen" */ './components');
});

export default withLayout({
  component: Screen,
});
