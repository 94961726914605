import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/sms-config/actions';
import smsConfigApi from 'src/services/api/sms-config';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* claimSms({ filters }: any) {
  try {
    const response = yield call(smsConfigApi.claimSms, filters);
    yield put(Creators.smsConfigClaimRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'claims',
      entity: 'sms_config',
    });
    handleErrorResponse(error);
    yield put(Creators.smsConfigClaimRequestError(error));
  }
}

export function* watchClaimSms() {
  yield takeLeading(Types.SMS_CONFIG_CLAIM_REQUEST, claimSms);
}

export default watchClaimSms;
