import { debounce, put, call, takeLeading } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/notifications/actions';
import DBSocket from 'src/services/websockets';
import toast from 'src/services/toaster';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* pull(action: any) {
  try {
    while (DBSocket.isConnected('notifications')) {
      const response = yield call(
        DBSocket.listenToSocket,
        'notifications',
        'notification'
      );
      if (response) {
        toast.info(response.body);
        yield put(Creators.notificationsPullRequestSuccess(response));
      }
    }
  } catch (e) {
    DBErrorMonitor.captureException(e, {
      saga: 'pull',
      entity: 'notification',
    });
    let error = e.response;
    yield put(Creators.notificationsPullRequestError(error));
  }
}

export function* watchPull() {
  yield takeLeading(Types.NOTIFICATIONS_PULL_REQUEST, pull);
}

export default watchPull;
