import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    medicalSpecialtiesListRequest: ['filters'],
    medicalSpecialtiesListRequestSuccess: ['data'],
    medicalSpecialtiesListRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
