import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    MEDICAL_GROUPS_CREATE_REQUEST
    MEDICAL_GROUPS_CREATE_REQUEST_SUCCESS
    MEDICAL_GROUPS_CREATE_REQUEST_ERROR

    MEDICAL_GROUPS_LIST_REQUEST
    MEDICAL_GROUPS_LIST_REQUEST_SUCCESS
    MEDICAL_GROUPS_LIST_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
