import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    agentsCreateRequest: ['agent'],
    agentsCreateRequestSuccess: ['data'],
    agentsCreateRequestError: ['error'],

    agentsListRequest: ['filters'],
    agentsListRequestSuccess: ['data'],
    agentsListRequestError: ['error'],

    agentsUpdateRequest: ['agent'],
    agentsUpdateRequestSuccess: ['data'],
    agentsUpdateRequestError: ['error'],

    agentsDeleteRequest: ['id'],
    agentsDeleteRequestSuccess: ['data'],
    agentsDeleteRequestError: ['error'],

    agentsGetRequest: ['id'],
    agentsGetRequestSuccess: ['data'],
    agentsGetRequestError: ['error'],

    agentsMFARequest: ['id'],
    agentsMFARequestSuccess: ['data'],
    agentsMFARequestError: ['error'],

    agentsImageRequest: ['photoKey'],
    agentsImageRequestSuccess: ['data'],
    agentsImageRequestError: ['error'],

    agentsStatusRequest: [],
    agentsStatusRequestSuccess: ['data'],
    agentsStatusRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
