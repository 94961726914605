import { put, call, takeLatest } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* getAttachment(action: any) {
  try {
    const imgResponse = yield call(
      claimsApi.fetchCommentAttachment,
      action.photoKey
    );
    const reader = new FileReader();
    const blob = imgResponse.data;
    const imagePromise = (): Promise<unknown> =>
      new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });
    const image = yield call(imagePromise);
    yield put(Creators.claimsGetAttachmentRequestSuccess(image));
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'claims' });
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.claimsGetAttachmentRequestError(error));
  }
}

export function* watchGetAttachment() {
  yield takeLatest(Types.CLAIMS_GET_ATTACHMENT_REQUEST, getAttachment);
}

export default watchGetAttachment;
