import io from 'socket.io-client';
import { BASE_URL, SOCKET_URL } from 'src/services/api/urls';

type Stream = {
  type: string;
  data: any;
};

export default class DBSocket {
  private static clients: any = {};

  public static connect(namespace: string): void {
    if (DBSocket.isConnected(namespace)) {
      return;
    }

    const client = io(`${SOCKET_URL}/${namespace}`, {
      query: {
        token: localStorage.getItem('accessToken'),
      },
    });

    DBSocket.clients[namespace] = client;

    DBSocket.clients[namespace].on('connect', () => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Websocket Client is connected');
      }
    });
  }

  public static isConnected(namespace: string = 'notifications'): Boolean {
    return Object.keys(DBSocket.clients).includes(namespace);
  }

  public static closeConnection(namespace: string): void {
    DBSocket.clients[namespace].close();
  }

  public static closeAllConnections(): void {
    const namespaces = Object.keys(DBSocket.clients);
    for (const namespace of namespaces) {
      DBSocket.clients[namespace].close();
    }
  }

  public static listenToSocket(
    namespace: string,
    event: string = 'message'
  ): Promise<Stream> {
    console.log('current clients', DBSocket.clients);
    return new Promise((resolve, reject) => {
      DBSocket.clients[namespace].on(event, (message: any) => {
        if (typeof message !== 'object') {
          const err = new Error('Unexpected data stream');
          reject(err);
        }

        return resolve(message);
      });
    });
  }

  public static emitToSocket(
    namespace: string,
    event: string = 'message',
    message: string = 'check'
  ): void {
    DBSocket.clients[namespace].emit(event, message);
  }
}
