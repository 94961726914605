import { apiRequest } from '../index';
import { NOTIFICATIONS_URL, STATS_URL } from '../urls';
import { Filters } from 'src/props/filters.props';

export const list = (filters: Filters) => {
  return apiRequest('GET', NOTIFICATIONS_URL, null, filters);
};

export const read = ({ id, ...data }: any) => {
  return apiRequest('PATCH', `${NOTIFICATIONS_URL}/${id}`, data);
};

export const counts = () => {
  return apiRequest('GET', `${STATS_URL}/all-counts`, null);
};

export default {
  list,
  read,
  counts,
};
