import { debounce, put, call, select } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medications/actions';
import medicationsApi from 'src/services/api/medications';

export function* cache(action: any) {
  try {
    const list = yield select((store: any) => store?.medications?.list || []);
    const keyword = yield select(
      (store: any) => store?.medications?.pagination?.keyword || ''
    );
    if (list.length > 0) {
      const response = yield call(medicationsApi.cache, keyword, list);
    }
    yield put(Creators.medicationsCacheRequestSuccess());
  } catch (e) {
    let error = e.response;
    yield put(Creators.medicationsCacheRequestError(error));
  }
}

export function* watchCache() {
  yield debounce(500, Types.MEDICATIONS_CACHE_REQUEST, cache);
}

export default watchCache;
