// base urls
const isLocal = process.env.REACT_APP_BACKEND?.includes('localhost');

export const BASE_URL: string | undefined = process.env.REACT_APP_BACKEND;
export const SOCKET_URL: string | undefined = process.env.REACT_APP_SOCKET;

// auth urls
export const MFA_VERIFY_URL: string = `${BASE_URL}/auth/auth/verify-mfa`;
export const LOGIN_URL: string = `${BASE_URL}/auth/auth/login`;
export const LOGOUT_URL: string = `${BASE_URL}/auth/auth/logout`;
export const CURRENT_USER_URL: string = `${BASE_URL}/auth/auth/user`;
export const FORGET_URL: string = `${BASE_URL}/auth/auth/forget-password`;
export const RESET_URL: string = `${BASE_URL}/portal/users/forget-password`

// agents urls
export const AGENTS_URL: string = `${BASE_URL}/portal/users`;

// medical entities urls
export const MEDICAL_ENTITIES_URL: string = `${BASE_URL}/portal/medical-entity`;

// medical groups urls
export const MEDICAL_GROUPS_URL: string = `${BASE_URL}/portal/medical-group`;

// medical degrees urls
export const MEDICAL_DEGREES_URL: string = `${BASE_URL}/directory/degrees`;

// medical specialties urls
export const MEDICAL_SPECIALTIES_URL: string = `${BASE_URL}/directory/specialties`;

// medications urls
export const MEDICATIONS_URL: string = `${BASE_URL}/directory/medications`;

// notifications urls
export const NOTIFICATIONS_URL: string = `${BASE_URL}/portal/notifications`;

// stats urls
export const STATS_URL: string = `${BASE_URL}/portal/statistics`;

// medical doctors urls
export const DOCTORS_URL: string = `${BASE_URL}/directory/doctors`;

// comments urls
export const COMMENTS_URL: string = `${BASE_URL}/directory/comments`;

// medical insurance companies urls
export const INSURANCES_URL: string = `${BASE_URL}/portal/insurance-companies`;

// permissions urls
export const PERMISSIONS_URL: string = `${BASE_URL}/portal/permissions`;

// medical field representatives urls
export const FIELD_REPRESENTATIVES_URL: string = `${BASE_URL}/portal/users`;

// claims urls
export const CLAIMS_URL: string = `${BASE_URL}/portal/claims`;

// claim cancel url
export const CANCEL_CLAIMS_URL: string = `${BASE_URL}/portal/claims/cancel-claim`;

// tags urls
export const TAGS_URL: string = `${BASE_URL}/directory/tags`;
export const TAGS_UPDATE = `${BASE_URL}/directory/tags/update-tags`;

// query tags urls
export const QUERY_TAGS_URL: string = `${BASE_URL}/directory/tags/query-tags`;

// tags urls
export const TAGS_SMS_URL: string = `${BASE_URL}/portal/sms-tags`;

// disease codes urls
export const DISEASE_CODES_URL: string = `${BASE_URL}/directory/disease-codes`;

// sms urls
export const SMS_CONFIG_URL: string = `${BASE_URL}/portal/sms-tags`;
export const SMS_CONFIG_SCENARIOS_URL: string = `${BASE_URL}/portal/sms-scenario`;

// statistics urls
export const STATISTICS_URL: string = `${BASE_URL}/portal/statistics`;

// SLA urls
export const CONFIG_URL: string = `${BASE_URL}/portal/configurations`;

// labs urls
export const LABS_URL: string = `${BASE_URL}/directory/labs`;

// scans urls
export const SCANS_URL: string = `${BASE_URL}/directory/scans`;

// medications search config urls
export const MEDICATIONS_CONF_SEARCH_URL: string = `${BASE_URL}/directory/medications/search-medication`;

// ocr url
export const OCR_URL: string = `${BASE_URL}/media/resource/parse`;

// Media service url
export const MEDIA_SERVICE_URL: string = `${BASE_URL}/media/resource`;

// claim time tracking url
export const CLAIM_TIME_TRACKING_URL: string = `${BASE_URL}/portal/claim-sections-time`;

// bulk listing url
export const BULK_LISTING_URL: string = `${BASE_URL}/directory/upload-process`;

//assign claim to agent
export const ASSIGN_CLAIM_TO_AGENT: string = `${BASE_URL}/portal/claims/assign`;
