import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    medicalEntitiesCreateRequest: ['medicalEntity'],
    medicalEntitiesCreateRequestSuccess: ['data'],
    medicalEntitiesCreateRequestError: ['error'],

    medicalEntitiesListRequest: ['filters'],
    medicalEntitiesListRequestSuccess: ['data'],
    medicalEntitiesListRequestError: ['error'],

    medicalEntitiesUpdateRequest: ['medicalEntity'],
    medicalEntitiesUpdateRequestSuccess: ['data'],
    medicalEntitiesUpdateRequestError: ['error'],

    medicalEntitiesDeleteRequest: ['id'],
    medicalEntitiesDeleteRequestSuccess: ['data'],
    medicalEntitiesDeleteRequestError: ['error'],

    medicalEntitiesGetRequest: ['id'],
    medicalEntitiesGetRequestSuccess: ['data'],
    medicalEntitiesGetRequestError: ['error'],

    medicalEntitiesImageRequest: ['photoKey'],
    medicalEntitiesImageRequestSuccess: ['data'],
    medicalEntitiesImageRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
