import { debounce, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/tags/actions';
import tagsApi from 'src/services/api/tags';

export function* list(action: any) {
  try {
    const response = yield call(tagsApi.list, action.filters);
    yield put(Creators.tagsListRequestSuccess(response.data));
  } catch (e) {
    let error = e.response;
    yield put(Creators.tagsListRequestError(error));
  }
}

export function* watchList() {
  yield debounce(6e2, Types.TAGS_LIST_REQUEST, list);
}

export default watchList;
