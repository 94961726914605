import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    itConfigGetRequest: [],
    itConfigGetRequestSuccess: ['data'],
    itConfigGetRequestError: ['error'],

    itConfigUpdateRequest: ['data'],
    itConfigUpdateRequestSuccess: ['data'],
    itConfigUpdateRequestError: ['error'],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
