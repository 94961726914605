import React, { useCallback, useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FaRegBell } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { Creators as AuthCreators } from 'src/store/redux/auth/actions';
import { Creators as NotificationsCreators } from 'src/store/redux/notifications/actions';
import { Creators as RolesCreators } from 'src/store/redux/roles/actions';
import './style.css';
import styles from './top-navbar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import PageSpinner from 'src/components/Atoms/PageSpinner';
import Notification from './notification';
import ReduxStoreTypes from 'src/store/redux/types';
import { Creators as AgentCreators } from '../../../store/redux/agents/actions';

function TopNavbarComponent(props: any) {
  const dispatch = useDispatch();
  const user = (useSelector((store: ReduxStoreTypes) => store) as any)?.auth
    ?.user;

  const notifications = (useSelector((store: ReduxStoreTypes) => store) as any)
    ?.notifications;

  const logOut = useCallback(() => {
    dispatch(AuthCreators.logoutRequest());
  }, [dispatch]);

  const newNotifications = notifications.headerList
    ? notifications.headerList.filter((notifty: any) => !notifty.read)
    : [];

  const getNotifications = useCallback(() => {
    dispatch(
      NotificationsCreators.notificationsListRequest({
        sort: 'id,DESC',
        limit: 5,
        place: 'header',
      })
    );
    dispatch(NotificationsCreators.notificationsPullRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(RolesCreators.rolesGetCurrRequest());
  }, [dispatch]);

  return (
    <Navbar expand="lg" className={styles.mainNabBar}>
      <Navbar.Brand className={styles.brand} onClick={() => props.toggle()}>
        <img
          className={styles.burger_icon}
          src={require('src/assets/icons/burger-icon.svg')}
          alt="navbar icon"
        />
      </Navbar.Brand>
      <Nav className="ml-auto" style={{ zIndex: 1 }}>
        <NavDropdown
          onClick={getNotifications}
          id="notification-dd"
          className={newNotifications.length > 0 ? 'redLabel' : ''}
          title={<FaRegBell />}>
          <NavDropdown.Item
            as={Link}
            to={ROUTES.NOTIFICATIONS.ALL}
            className={styles.notificationHeader}>
            <span>You have {newNotifications.length} unread notifications</span>
          </NavDropdown.Item>
          {(notifications.isLoading && (
            <PageSpinner className={'centeredLoader'} />
          )) ||
            (notifications.headerList.length === 0 && (
              <p className="text-center">No notifications found</p>
            )) ||
            notifications.headerList.map((notification: any) => (
              <Notification key={notification.id} notification={notification} />
            ))}
          <NavDropdown.Item
            as={Link}
            to={ROUTES.NOTIFICATIONS.ALL}
            className={styles.notificationFooter}>
            <span className="text-center">Show All Notifications</span>
          </NavDropdown.Item>
        </NavDropdown>

        <NavDropdown
          className={styles.ddbox}
          title={`${user?.given_name ?? ''} ${user?.family_name ?? ''}`}
          id="nav-dropdown">
          <NavDropdown.Item
            as={Link}
            to={ROUTES.AGENTS.PROFILE.replace(':id', 'current')}>
            Profile
          </NavDropdown.Item>  
          <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
}

const TopNavbar = React.memo(TopNavbarComponent);

export default TopNavbar;
