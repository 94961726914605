import { all } from 'redux-saga/effects';
import watchList from './list';
import watchUpdate from './update';
import watchUpload from './upload';
import watchGet from './getItem';
import watchCreate from './create';
import watchSearch from './search';
import watchExport from './export';

export function* watchCPT() {
  yield all([
    watchList(),
    watchUpdate(),
    watchGet(),
    watchCreate(),
    watchSearch(),
    watchUpload(),
    watchGet(),
    watchExport(),
  ]);
}
