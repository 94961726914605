import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    AGENTS_CREATE_REQUEST
    AGENTS_CREATE_REQUEST_SUCCESS
    AGENTS_CREATE_REQUEST_ERROR

    AGENTS_LIST_REQUEST
    AGENTS_LIST_REQUEST_SUCCESS
    AGENTS_LIST_REQUEST_ERROR

    AGENTS_UPDATE_REQUEST
    AGENTS_UPDATE_REQUEST_SUCCESS
    AGENTS_UPDATE_REQUEST_ERROR

    AGENTS_DELETE_REQUEST
    AGENTS_DELETE_REQUEST_SUCCESS
    AGENTS_DELETE_REQUEST_ERROR

    AGENTS_GET_REQUEST
    AGENTS_GET_REQUEST_SUCCESS
    AGENTS_GET_REQUEST_ERROR

    AGENTS_MFA_REQUEST
    AGENTS_MFA_REQUEST_SUCCESS
    AGENTS_MFA_REQUEST_ERROR

    AGENTS_IMAGE_REQUEST
    AGENTS_IMAGE_REQUEST_SUCCESS
    AGENTS_IMAGE_REQUEST_ERROR

    AGENTS_STATUS_REQUEST
    AGENTS_STATUS_REQUEST_SUCCESS
    AGENTS_STATUS_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
