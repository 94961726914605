import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/agents/actions';
import agentsApi from 'src/services/api/agents';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* update({ agent }: any) {
  try {
    const response = yield call(agentsApi.update, agent);
    yield put(Creators.agentsUpdateRequestSuccess(response));
    toaster.success('agent were updated successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'update', entity: 'agents' });
    handleErrorResponse(error);
    yield put(Creators.agentsUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.AGENTS_UPDATE_REQUEST, update);
}

export default watchUpdate;
