import { takeEvery, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/statistics-tpa/actions';
import statisticsAPI from 'src/services/api/statistics';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';


export function* getStatsMedicalEnt(filters: any = null) {
  try {
    // @ts-ignore: Unreachable code error
    const response = yield call(statisticsAPI.statsMedicalEntityTyped, filters);
    yield put(Creators.statisticsMedicalEntityTypedRequestSuccess(response));
  } catch (e) {
    let error = e;
    DBErrorMonitor.captureException(e, {
      saga: 'getStatsMedicalEnt',
      entity: 'statistics',
    });
    handleErrorResponse(error);
    yield put(Creators.statisticsMedicalEntityTypedRequestError(error));
  }
}

export function* watchGetStatsMedicalEnt() {
  yield takeEvery(
    Types.STATISTICS_MEDICAL_ENTITY_TYPED_REQUEST,
    getStatsMedicalEnt
  );
}

export default watchGetStatsMedicalEnt;
