import { apiRequest } from '../index';
import { INSURANCES_URL } from '../urls';
import { Filters } from 'src/props/filters.props';

export const list = (filters: Filters) => {
  return apiRequest('GET', INSURANCES_URL, null, filters);
};

export default {
  list,
};
