import { takeEvery, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/claims/actions';
import claimsApi from 'src/services/api/claims';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* count(action: any) {
  try {
    const response = yield call(claimsApi.count);
    yield put(Creators.claimsCountRequestSuccess(response.data));
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'count', entity: 'claims' });
    let error = e.response;
    yield put(Creators.claimsCountRequestError(error));
  }
}

export function* watchCount() {
  yield takeEvery(Types.CLAIMS_COUNT_REQUEST, count);
}

export default watchCount;
