import React from 'react';
import { Container, Row, Button, Col } from 'react-bootstrap';
import history from 'src/services/browser-history';
import { ReactComponent as AccessDeniedIcon } from 'src/assets/icons/roles/access-denied.svg';
import styles from './index.module.scss';

const AccessDenied: React.FC = () => {
  return (
    <Container style={{ marginTop: '70px' }} fluid>
      <Row>
        <Col md={12}>
          <AccessDeniedIcon className={styles.accessDeniedIcon} />
        </Col>
        <Col md={12}>
          <h2 className={styles.accessDeniedCode}>403</h2>
        </Col>
        <Col md={12}>
          <h2 className={styles.accessDeniedTitle}>Access Denied</h2>
        </Col>
        <Col md={12}>
          <p className={styles.accessDeniedDesc}>
            Sorry you don't have permission to access this page
          </p>
        </Col>
        <Col md={12}>
          <p className={styles.accessDeniedBtnCont}>
            <Button
              onClick={() => history.goBack()}
              className={styles.accessDeniedBtn}>
              Previous Page
            </Button>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AccessDenied;
