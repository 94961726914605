import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    rolesListPermRequest: [],
    rolesListPermRequestSuccess: ['data'],
    rolesListPermRequestError: ['error'],

    rolesListRequest: [],
    rolesListRequestSuccess: ['data'],
    rolesListRequestError: ['error'],

    rolesGetRequest: ['roleId'],
    rolesGetRequestSuccess: ['data'],
    rolesGetRequestError: ['error'],

    rolesGetCurrRequest: [],
    rolesGetCurrRequestSuccess: ['data'],
    rolesGetCurrRequestError: ['error'],

    rolesUpdateRequest: ['data'],
    rolesUpdateRequestSuccess: ['data'],
    rolesUpdateRequestError: ['error'],

    rolesDeleteRequest: ['roleId'],
    rolesDeleteRequestSuccess: ['data'],
    rolesDeleteRequestError: ['error'],

    rolesAddRequest: ['data'],
    rolesAddRequestSuccess: ['data'],
    rolesAddRequestError: ['error'],
  },
  {
    prefix: '',
  }
);
