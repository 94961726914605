import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/SLA/actions';
import slaAPI from 'src/services/api/SLA';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get() {
  try {
    const response = yield call(slaAPI.get);
    yield put(Creators.slaConfigGetRequestSuccess(response));
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, { saga: 'get', entity: 'sla' });
    handleErrorResponse(error);
    yield put(Creators.slaConfigGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLeading(Types.SLA_CONFIG_GET_REQUEST, get);
}

export default watchGet;
