import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    MEDICAL_ENTITIES_CREATE_REQUEST
    MEDICAL_ENTITIES_CREATE_REQUEST_SUCCESS
    MEDICAL_ENTITIES_CREATE_REQUEST_ERROR

    MEDICAL_ENTITIES_LIST_REQUEST
    MEDICAL_ENTITIES_LIST_REQUEST_SUCCESS
    MEDICAL_ENTITIES_LIST_REQUEST_ERROR

    MEDICAL_ENTITIES_UPDATE_REQUEST
    MEDICAL_ENTITIES_UPDATE_REQUEST_SUCCESS
    MEDICAL_ENTITIES_UPDATE_REQUEST_ERROR

    MEDICAL_ENTITIES_DELETE_REQUEST
    MEDICAL_ENTITIES_DELETE_REQUEST_SUCCESS
    MEDICAL_ENTITIES_DELETE_REQUEST_ERROR

    MEDICAL_ENTITIES_GET_REQUEST
    MEDICAL_ENTITIES_GET_REQUEST_SUCCESS
    MEDICAL_ENTITIES_GET_REQUEST_ERROR

    MEDICAL_ENTITIES_IMAGE_REQUEST
    MEDICAL_ENTITIES_IMAGE_REQUEST_SUCCESS
    MEDICAL_ENTITIES_IMAGE_REQUEST_ERROR

    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
