import { debounce, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/cpt/action';
import cptApi from 'src/services/api/cpt';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* exportCPT(action: any) {
  try {
    const response = yield call(
      cptApi.exportReport,
      action.filters,
      action.Type
    );
    yield put(Creators.cptExportRequestSuccess(response?.data));
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'export', entity: 'cpt' });
    let error = e.response;
    yield put(Creators.cptExportRequestError(error));
  }
}

export function* watchExport() {
  yield debounce(6e2, Types.CPT_EXPORT_REQUEST, exportCPT);
}

export default watchExport;
