import { takeEvery, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/statistics-tpa/actions';
import statisticsAPI from 'src/services/api/statistics';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* getTicketOverview(filters: any = null) {
  try {
    // @ts-ignore: Unreachable code error
    
    const response = yield call(statisticsAPI.ticketOverViewSection, filters);
    yield put(
      Creators.statisticsTicketOverviewRequestSuccess(response.data)
    );
  } catch (e) {
    let error = e;
    DBErrorMonitor.captureException(e, {
      saga: 'getTicketOverview',
      entity: 'statistics',
    });
    handleErrorResponse(error);
    yield put(Creators.statisticsTicketOverviewRequestError(error));
  }
}

export function* watchGetTicketOverview() {
  yield takeEvery(
    Types.STATISTICS_TICKET_OVERVIEW_REQUEST,
    getTicketOverview
  );
}

export default watchGetTicketOverview;
