import { apiRequest, apiExport } from '../index';
import {
  CLAIMS_URL,
  CLAIM_TIME_TRACKING_URL,
  MEDIA_SERVICE_URL,
  CANCEL_CLAIMS_URL,
  ASSIGN_CLAIM_TO_AGENT,
} from '../urls';
import { Filters } from 'src/props/filters.props';
import { CacheManager } from 'src/services/cache';

export const exportReport = (filters: Filters) => {
  return apiExport(`${CLAIMS_URL}/exports`, filters);
};

export const checkEligibility = (userData: any) => {
  return apiRequest('POST', `${CLAIMS_URL}/check-eligibility `, userData);
};

export const exportImages = (filters: Filters, exportedType: string) => {
  console.log('filters', filters);
  return apiExport(`${CLAIMS_URL}/${exportedType}-exports`, filters);
};

export const statsReport = () => {
  return apiRequest('GET', `${CLAIMS_URL}/daily-reports`, null, null);
};

export const list = (filters: Filters) => {
  return apiRequest('GET', `${CLAIMS_URL}/query-claims`, null, filters);
};

export const get = (id: string | number) => {
  return apiRequest('GET', `${CLAIMS_URL}/${id}`, null);
};

export const update = ({ id, ...data }: any, submissionType: string) => {
  if (submissionType == 'submit') {
    return apiRequest('GET', `${CLAIMS_URL}/submit-claim/${id}`, null, null);
  } else {
    const {
      submit,
      medications,
      medicationStatus,
      medicationsPriorApproval,
      labs,
      labsPriorApproval,
      labsStatus,
      scans,
      scansPriorApproval,
      scansStatus,
      ...vdData
    } = data;
    switch (submissionType) {
      case 'dv':
        return apiRequest(
          'PATCH',
          `${CLAIMS_URL}/update-claim-${submissionType}/${id}`,
          vdData
        );
      case 'medications':
        return apiRequest(
          'PATCH',
          `${CLAIMS_URL}/update-claim-${submissionType}/${id}`,
          {
            medications,
            medicationStatus,
            medicationsPriorApproval,
          }
        );
      case 'labs':
        return apiRequest(
          'PATCH',
          `${CLAIMS_URL}/update-claim-${submissionType}/${id}`,
          {
            labs,
            labsPriorApproval,
            labsStatus,
          }
        );
      case 'scans':
        return apiRequest(
          'PATCH',
          `${CLAIMS_URL}/update-claim-${submissionType}/${id}`,
          {
            scans,
            scansPriorApproval,
            scansStatus,
          }
        );
    }
  }
};

export const track = (data: any) => {
  return apiRequest('POST', CLAIM_TIME_TRACKING_URL, data);
};

export const count = () => {
  return apiRequest('GET', CLAIMS_URL, null, { count: 1 });
};

export const fetchCommentAttachment = async (key: string) => {
  const mediaUrl = `${MEDIA_SERVICE_URL}/${key}`;
  const img = apiRequest('GET', mediaUrl, null, null);

  return img;
};

export const fetchImage = async (key: string) => {
  const cacheMgr = new CacheManager();
  await cacheMgr.init('claims-cache', 1);
  const mediaUrl = `${MEDIA_SERVICE_URL}/${key}`;
  const response = await cacheMgr.getCache(mediaUrl);
  if (response) {
    return { data: response };
  }
  const img = apiRequest('GET', mediaUrl, null, null);
  img.then((imgRes) => {
    cacheMgr.setCache(mediaUrl, imgRes.data);
  });
  return img;
};

export const assignToSelf = (id: number, internal: number) => {
  return apiRequest(
    'GET',
    `${CLAIMS_URL}/${id}/assign/${internal}`,
    null,
    null
  );
};

export const deleteSelectedClaim = (id: number) => {
  return apiRequest('DELETE', `${CLAIMS_URL}/${id}`, null, null);
};

export const cancel = (id: number) => {
  return apiRequest('GET', `${CANCEL_CLAIMS_URL}/${id}`, null, null);
};

export const assignToAgent = (userId: number, claimId: number) => {
  return apiRequest(
    'POST',
    `${ASSIGN_CLAIM_TO_AGENT}/${claimId}`,
    { userId },
    null
  );
};

export const getClaimHistory = (id: number) => {
  return apiRequest('GET', `${CLAIMS_URL}/claim-history/${id}`, null, null);
};

export default {
  assignToSelf,
  statsReport,
  exportReport,
  exportImages,
  track,
  list,
  get,
  update,
  count,
  fetchImage,
  deleteSelectedClaim,
  fetchCommentAttachment,
  checkEligibility,
  cancel,
  assignToAgent,
  getClaimHistory,
};
