import { takeEvery, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/statistics-me/actions';
import statisticsAPI from 'src/services/api/statistics';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* getHeatMap(filters: any = null) {
  try {
    // @ts-ignore: Unreachable code error
    
    const response = yield call(statisticsAPI.heatMapStats, filters);
    yield put(
      Creators.statisticsMeHeatMapRequestSuccess (response.data)
    );
  } catch (e) {
    let error = e;
    DBErrorMonitor.captureException(e, {
      saga: 'getHeatMap',
      entity: 'statistics',
    });
    handleErrorResponse(error);
    yield put(Creators.statisticsMeHeatMapRequestError(error));
  }
}

export function* watchGetHeatMap() {
  yield takeEvery(
    Types.STATISTICS_ME_HEAT_MAP_REQUEST,
    getHeatMap
  );
}

export default watchGetHeatMap;
