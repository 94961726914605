import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/auth/actions';
import AuthAPI from 'src/services/api/auth';
import { DBErrorMonitor } from 'src/services/error-monitor';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import DBSocket from '../../../services/websockets';

export function* user(_: any) {
  try {
    const resUser = yield call(AuthAPI.getUser);
    yield put(Creators.userRequestSuccess(resUser.data));
    while (DBSocket.isConnected('users')) {
      const updatedUser = yield call(
        DBSocket.listenToSocket,
        'users',
        'user-updated'
      );
      yield put(Creators.userRequestSuccess(updatedUser));
    }
  } catch (e) {
    DBErrorMonitor.captureException(e, { saga: 'login', entity: 'auth' });
    if (e.response) {
      if (e.response.status !== 401) {
        handleErrorResponse(e.response);
      }
    }
    yield put(Creators.loginRequestError(e));
  }
}

export function* watchUser() {
  yield takeLeading(Types.USER_REQUEST, user);
}

export default watchUser;
