import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/sms-config/actions';
import smsConfigAPI from 'src/services/api/sms-config';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* deleteSMSConfig(action: any) {
  try {
    const response = yield call(smsConfigAPI.delete, action.id);
    yield put(Creators.smsConfigDeleteRequestSuccess(response));
    yield put(Creators.smsConfigListRequest({}));
    toaster.success('SMS config were deleted successfully');
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'delete',
      entity: 'sms_config',
    });
    handleErrorResponse(error);
    yield put(Creators.smsConfigDeleteRequestError(error));
  }
}

export function* watchDelete() {
  yield takeLeading(Types.SMS_CONFIG_DELETE_REQUEST, deleteSMSConfig);
}

export default watchDelete;
