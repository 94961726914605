import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {


    statisticsMePerscAnalyticsRequest: ['filters'],
    statisticsMePerscAnalyticsRequestSuccess: ['data'],
    statisticsMePerscAnalyticsRequestError: ['error'],

    statisticsMeProviderPerformanceRequest: ['filters'],
    statisticsMeProviderPerformanceRequestSuccess: ['data'],
    statisticsMeProviderPerformanceRequestError: ['error'],


    statisticsMeHeatMapRequest: ['filters'],
    statisticsMeHeatMapRequestSuccess: ['data'],
    statisticsMeHeatMapRequestError: ['error'],

    statisticsMeDbPerformanceRequest: ['filters'],
    statisticsMeDbPerformanceRequestSuccess: ['data'],
    statisticsMeDbPerformanceRequestError: ['error'],

    statisticsMeMedicalEntityRequest: ['filters'],
    statisticsMeMedicalEntityRequestSuccess: ['data'],
    statisticsMeMeMedicalEntityRequestError: ['error'],

    statisticsMeMedicalEntityTypedRequest: ['filters'],
    statisticsMeMedicalEntityTypedRequestSuccess: ['data'],
    statisticsMeMedicalEntityTypedRequestError: ['error'],

    statisticsMeUpdateDateFilters: ['filters'],
    statisticsMeUpdateEntityFilters: ['filters'],
    statisticsMeUpdateMedicalGroupsFilter: ['filters'],

    statisticsMeGetStatisticsData: [],

    statisticsMeClearDateFilters: [],
    statisticsMeClearEntityFilters: [],
    statisticsMeClearMedicalGroupsFilter: [],

    clearStore: [],
  },
  {
    prefix: '',
  }
);
