import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    CPT_LIST_REQUEST
    CPT_LIST_REQUEST_SUCCESS
    CPT_LIST_REQUEST_ERROR


    CPT_UPDATE_REQUEST
    CPT_UPDATE_REQUEST_SUCCESS
    CPT_UPDATE_REQUEST_ERROR

    CPT_UPLOAD_REQUEST
    CPT_UPLOAD_REQUEST_ERROR
    
    CPT_GET_REQUEST
    CPT_GET_REQUEST_SUCCESS
    CPT_GET_REQUEST_ERROR

    CPT_CREATE_REQUEST
    CPT_CREATE_REQUEST_SUCCESS
    CPT_CREATE_REQUEST_ERROR

    CPT_SEARCH_REQUEST
    CPT_SEARCH_REQUEST_SUCCESS
    CPT_SEARCH_REQUEST_ERROR

    
    CLEAR_STORE
  `,
  {
    prefix: '',
  }
);
