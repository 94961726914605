import { takeLeading, put, call } from 'redux-saga/effects';

import { Creators, Types } from 'src/store/redux/medical-entities/actions';
import medicalEntitiesApi from 'src/services/api/medical-entities';
import { handleErrorResponse } from 'src/lib/helpers/errors';
import { DBErrorMonitor } from 'src/services/error-monitor';

export function* get({ id }: any) {
  try {
    const response = yield call(medicalEntitiesApi.get, id);
    yield put(Creators.medicalEntitiesGetRequestSuccess(response));
    yield put(
      Creators.medicalEntitiesImageRequest({
        medicalEntity: response.data?.logoKey,
      })
    );
  } catch (e) {
    let error = e.response;
    DBErrorMonitor.captureException(e, {
      saga: 'get',
      entity: 'medicalEntities',
    });
    handleErrorResponse(error);
    yield put(Creators.medicalEntitiesGetRequestError(error));
  }
}

export function* watchGet() {
  yield takeLeading(Types.MEDICAL_ENTITIES_GET_REQUEST, get);
}

export default watchGet;
