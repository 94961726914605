import { createReducer } from 'reduxsauce';
import { MEDICAL_ENTITIES_URL } from 'src/services/api/urls';
import history from 'src/services/browser-history';
import { MedicalEntity } from 'src/types-dictionary';
import { MedicalEntitiesReducerTypes } from '../types';

import Types from './actions-types';

export const INITIAL_STATE: MedicalEntitiesReducerTypes = {
  list: [],
  item: {},
  pagination: {
    count: 8,
    total: 8,
    page: 1,
    filter: '',
    s: null,
  },
  isLoading: false,
  isImageLoading: false,
  hasError: false,
};

export const medicalEntitiesCreateRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const medicalEntitiesCreateRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: false,
  };
};

export const medicalEntitiesCreateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const medicalEntitiesListRequest = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    pagination: { ...state.pagination, ...action.filters },
    isLoading: true,
  };
};

export const medicalEntitiesListRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const { data: list, ...pagination } = action.data.data;

  return {
    ...state,
    list,
    pagination: { ...state.pagination, ...pagination },
    isLoading: false,
    hasError: false,
  };
};

export const medicalEntitiesListRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const medicalEntitiesUpdateRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const medicalEntitiesUpdateRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const list = state.list.map((entity: any) => {
    if (action.data.data.id === entity.id) {
      return action.data.data;
    }
    return entity;
  });

  return {
    ...state,
    list,
    isLoading: false,
    hasError: false,
  };
};

export const medicalEntitiesUpdateRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const medicalEntitiesDeleteRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const medicalEntitiesDeleteRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  const list = state.list.filter(({ id }: any) => id !== action.id);

  return {
    ...state,
    list,
    isLoading: false,
    hasError: false,
  };
};

export const medicalEntitiesDeleteRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const medicalEntitiesGetRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const medicalEntitiesGetRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    list: [action.data.data],
    item: action.data.data,
    isLoading: false,
    hasError: false,
  };
};

export const medicalEntitiesGetRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
  };
};

export const medicalEntitiesImageRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isImageLoading: true,
  };
};

export const medicalEntitiesImageRequestSuccess = (
  state = INITIAL_STATE,
  action: any
) => {
  return {
    ...state,
    item: {
      ...state.item,
      image:
        action.data.entity === 'medicalEntity'
          ? action.data.image
          : (state.item as any)?.image,
    },
    isImageLoading: false,
    hasError: false,
  };
};

export const medicalEntitiesImageRequestError = (state = INITIAL_STATE) => {
  return {
    ...state,
    isImageLoading: false,
    hasError: true,
  };
};

export const clearStore = (state = INITIAL_STATE) => {
  if (
    MEDICAL_ENTITIES_URL.includes(history.location.pathname.split('/')[1]) &&
    history.location.search !== ''
  ) {
    return { ...INITIAL_STATE, pagination: state.pagination };
  }
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.MEDICAL_ENTITIES_CREATE_REQUEST as string]: medicalEntitiesCreateRequest,
  [Types.MEDICAL_ENTITIES_CREATE_REQUEST_SUCCESS as string]: medicalEntitiesCreateRequestSuccess,
  [Types.MEDICAL_ENTITIES_CREATE_REQUEST_ERROR as string]: medicalEntitiesCreateRequestError,

  [Types.MEDICAL_ENTITIES_LIST_REQUEST as string]: medicalEntitiesListRequest,
  [Types.MEDICAL_ENTITIES_LIST_REQUEST_SUCCESS as string]: medicalEntitiesListRequestSuccess,
  [Types.MEDICAL_ENTITIES_LIST_REQUEST_ERROR as string]: medicalEntitiesListRequestError,

  [Types.MEDICAL_ENTITIES_UPDATE_REQUEST as string]: medicalEntitiesUpdateRequest,
  [Types.MEDICAL_ENTITIES_UPDATE_REQUEST_SUCCESS as string]: medicalEntitiesUpdateRequestSuccess,
  [Types.MEDICAL_ENTITIES_UPDATE_REQUEST_ERROR as string]: medicalEntitiesUpdateRequestError,

  [Types.MEDICAL_ENTITIES_DELETE_REQUEST as string]: medicalEntitiesDeleteRequest,
  [Types.MEDICAL_ENTITIES_DELETE_REQUEST_SUCCESS as string]: medicalEntitiesDeleteRequestSuccess,
  [Types.MEDICAL_ENTITIES_DELETE_REQUEST_ERROR as string]: medicalEntitiesDeleteRequestError,

  [Types.MEDICAL_ENTITIES_GET_REQUEST as string]: medicalEntitiesGetRequest,
  [Types.MEDICAL_ENTITIES_GET_REQUEST_SUCCESS as string]: medicalEntitiesGetRequestSuccess,
  [Types.MEDICAL_ENTITIES_GET_REQUEST_ERROR as string]: medicalEntitiesGetRequestError,

  [Types.MEDICAL_ENTITIES_IMAGE_REQUEST as string]: medicalEntitiesImageRequest,
  [Types.MEDICAL_ENTITIES_IMAGE_REQUEST_SUCCESS as string]: medicalEntitiesImageRequestSuccess,
  [Types.MEDICAL_ENTITIES_IMAGE_REQUEST_ERROR as string]: medicalEntitiesImageRequestError,

  [Types.CLEAR_STORE as string]: clearStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
