import { takeLeading, put, call } from 'redux-saga/effects';
import { Creators, Types } from 'src/store/redux/Integration/actions';
import itAPI from 'src/services/api/Integration';
import toaster from 'src/services/toaster';
import { handleErrorResponse } from 'src/lib/helpers/errors';

export function* update({ data }: any) {
  try {
    const response = yield call(itAPI.update, data);
    yield put(Creators.itConfigUpdateRequestSuccess(response));
    toaster.success('Integtaion Contact was updated successfully');
  } catch (e) {
    let error = e.response;
    handleErrorResponse(error);
    yield put(Creators.itConfigUpdateRequestError(error));
  }
}

export function* watchUpdate() {
  yield takeLeading(Types.IT_CONFIG_UPDATE_REQUEST, update);
}

export default watchUpdate;
