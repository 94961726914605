import dayjs from 'dayjs';
import { apiRequest } from '../index';
import { STATISTICS_URL } from '../urls';

const dateFormat = 'YYYY-MM-DD';
const today = dayjs().format(dateFormat);
const tomorrow = dayjs().add(1, 'days').format(dateFormat);

const todayDefaultFilter = {
  to: tomorrow,
  from: today,
};

export const ticketOverViewSection = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/ticket-overview-section`,
    null,
    filters
  );
  
};

export const slaSection = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/SLA-section`,
    null,
    filters
  );
  
};


export const frqSection = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/frequency-section`,
    null,
    filters
  );
  
};


export const statsStatus = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/prescriptions-status`,
    null,
    filters
  );
  
};

export const statsAggregations = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/prescriptions-aggregations`,
    null,
    filters
  );
};

export const statsIntegStatus = ({ filters }: any = todayDefaultFilter) => {
  
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/prescriptions-dvStatus`,
    null,
    filters
  );
};

export const statsMedicalEntities = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/prescriptions-media-entities`,
    null,
    filters
  );
};

export const statsMedicalEntityTyped = ({
  filters,
}: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/prescriptions-entity-type`,
    null,
    filters
  );
};

export const statsSlaViolation = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/prescriptions-sla-violation`,
    null,
    filters
  );
};

export const statsPrescriptionsOverTime = ({
  filters,
}: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/prescriptions-over-time`,
    null,
    filters
  );
};

export const statsTotalNumbers = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest('GET', `${STATISTICS_URL}/entities-count`, null, filters);
};

export const statsAverageResolutionTime = ({
  filters,
}: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/average-resolution-time`,
    null,
    filters
  );
};

/* ME Statistics endpoints */
export const meDBPerformance = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/dbridges-preformance`,
    null,
    filters
  );
  
};


export const heatMapStats = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/presc-heat-map`,
    null,
    filters
  );
  
};
export const providerPerformanceStats = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/provider-preformance`,
    null,
    filters
  );
  
};


export const perscAnalyticsStats = ({ filters }: any = todayDefaultFilter) => {
  return apiRequest(
    'GET',
    `${STATISTICS_URL}/doctors-speciality-freq`,
    null,
    filters
  );
  
};

export default {
  statsStatus,
  statsAggregations,
  statsIntegStatus,
  statsMedicalEntities,
  statsMedicalEntityTyped,
  statsSlaViolation,
  statsPrescriptionsOverTime,
  statsTotalNumbers,
  statsAverageResolutionTime,
  ticketOverViewSection,
  slaSection,
  frqSection,
  heatMapStats,
  meDBPerformance,
  providerPerformanceStats,
  perscAnalyticsStats
};
