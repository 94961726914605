export enum ClaimStatus {
  NEW = 'New',
  ASSIGNED = 'Assigned',
  AWAITING = 'Awaiting',
  RESUBMITTED = 'Resubmitted',
  CLOSED = 'Closed',
}

export enum DVStatus {
  PENDING = 'Pending',
  AWAITING = 'Awaiting',
  RESUBMITTED = 'Resubmitted',
  REJECTED = 'Rejected',
  DONE = 'Done',
}

export enum MedicationStatus {
  PENDING = 'Pending',
  DONE = 'Done',
}

export enum LabStatus {
  PENDING = 'Pending',
  DONE = 'Done',
}

export enum ScanStatus {
  PENDING = 'Pending',
  DONE = 'Done',
}

export enum TimeUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}
